import { LocaleId } from '@magal/configs'
import { groq } from 'next-sanity'
import { createSEOProjection } from './createSEOProjection'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createSectionJournalLandingPageProjection } from '../sections/createSectionJournalLandingPageProjection'

export const createJournalLandingPageProjection = (localeId: LocaleId) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)

  return groq`{
      ...,
      "pageBuilder":
        [
          ${createSectionJournalLandingPageProjection(localeId)},
          ...pageBuilder[]${PAGE_BUILDER_PROJECTION},
        ],
        seo${createSEOProjection(localeId)},
    }`
}
