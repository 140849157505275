import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionThreeFeaturesProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('subtitle', localeId)},
        features[] {
          ${coalesceLocaleField('title', localeId)},
          icon${MEDIA_PROJECTION}
        },
      }
  `
}
