import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'

export const createSectionCollectionProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
      }
  `
}
