import { FC } from 'react'
import { styled } from '@magal/styles'
import { ThemeDemo } from './ThemeDemo'
import { MediaDemo } from './MediaDemo'
import { MediaFieldType } from '@magal/models'
import { ExampleModal } from './ExampleModal'

type SampleComponentProps = {
  text?: string
  videoSampleField: MediaFieldType
  imageSampleField: MediaFieldType
}

const DemoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

const Container = styled('div', {
  padding: '$48 0 0 0',
  mx: '5vw',
})

const SectionTitle = styled('h2', {
  projectFont: 'heading02',
  margin: '$48 0 $12',
})

export const SampleComponent: FC<SampleComponentProps> = ({
  text,
  imageSampleField,
  videoSampleField,
}) => {
  return (
    <Container>
      <DemoContainer>
        <SectionTitle>Text prop: {text}</SectionTitle>
        <ThemeDemo />
        <MediaDemo
          imageSampleField={imageSampleField}
          videoSampleField={videoSampleField}
        />
        <SectionTitle>Modal</SectionTitle>
        <ExampleModal />

        <SectionTitle>Accordion</SectionTitle>
      </DemoContainer>
    </Container>
  )
}
