import { getLocaleRegionIdFromPath } from '@magal/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@magal/models'
import { createJournalPostProjection } from './projections/createJournalPostProjection'
import { createDefaultPageProjection } from './projections/createDefaultPageProjection'
import { createJournalLandingPageProjection } from './projections/createJournalLandingPageProjection'
import { createUtilityPageProjection } from './projections/createUtilityPageProjection'

export const getRouteByPath = async (
  path: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]> | undefined> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const JOURNAL_POST_PROJECTION = createJournalPostProjection(localeId)
  const JOURNAL_LANDING_PAGE_PROJECTION =
    createJournalLandingPageProjection(localeId)
  const DEFAULT_PAGE_PROJECTION = createDefaultPageProjection(
    regionId,
    localeId,
  )
  const UTILITY_PAGE_PROJECTION = createUtilityPageProjection(localeId)

  const query = groq`*[!(_id in path('drafts.**')) && defined(path.current) && path.current == "${path}"][0]{
    "page": select(
      _type == "journalPost" => ${JOURNAL_POST_PROJECTION},
      _type == "route" => ${DEFAULT_PAGE_PROJECTION},
      _type == "journalLandingPage" => ${JOURNAL_LANDING_PAGE_PROJECTION},
      _type == "utilityPage" => ${UTILITY_PAGE_PROJECTION},
    )
  }.page`

  return await getClient(preview).fetch(query)
}
