import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const sanityImageProjection = (fieldName: string) => groq`{
  ...${fieldName},
  ...${fieldName}.asset -> {
    "width": metadata.dimensions.width,
    "height": metadata.dimensions.height,
    "unoptimized": extension == "svg"
  }
}`

const muxVideoProjection = (fieldName: string) => groq`{
  ...${fieldName}.asset -> {
    "width": data.static_renditions.files[-1].width,
    "height": data.static_renditions.files[-1].height,
    "hls": "https://stream.mux.com/" + data.playback_ids[0].id + ".m3u8",
    "mp4": "https://stream.mux.com/" + data.playback_ids[0].id + "/high.mp4",
    "aspectRatio": data.aspect_ratio,
    "duration": data.duration,
    "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.jpg?time=0",
    "id": data.id,
  }
}`

export const createMediaProjection = (localeId: string) => groq`{
    ${coalesceLocaleField('alt', localeId)},
    mediaType == 'image' => {
      "mediaPayload": select(
        defined(image) => {
          "type": "sanity-image",
          "image": ${sanityImageProjection('image')},
          overrideMobileImage && defined(mobileImage) => {
            "mobileImage": ${sanityImageProjection('mobileImage')}
          }
        },
        {
          "type": "undefined-payload"
        }
      )
    },
    mediaType == 'video' => {
      "mediaPayload": {
        "video": video,
        "type": "mux-video",
        "video": ${muxVideoProjection('video')},
        overrideMobileVideo && defined(mobileVideo) => {
          "mobileVideo": ${muxVideoProjection('mobileVideo')}
        }
      }
    },
   }`
