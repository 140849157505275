import { FC, useState, useEffect } from 'react'
import { PricePrimitive } from './PricePrimitve'
import { ShopifyPrice } from '@magal/models'
import { styled } from '@stitches/react'
import { DiscountPercentage, formatDiscount } from '@magal/utils'

const ComparedPrice = styled('s', {
  color: '$red400',
  marginLeft: '$8',
  opacity: 0.8,
})

export const ProductVariantPrice: FC<{
  noZeros?: boolean
  noDiscount?: boolean
  mockDiscount?: boolean
  price?: ShopifyPrice
  compareAtPrice?: ShopifyPrice
}> = ({
  price,
  compareAtPrice,
  noZeros = false,
  noDiscount = false,
  mockDiscount = false,
}) => {
  const [discount, setDiscount] = useState<DiscountPercentage>()

  useEffect(() => {
    setTimeout(() => {
      if (window.discountPercentage.enableDiscount) {
        setDiscount(window.discountPercentage)
      }
    }, 100)
  }, [])

  if (!price) return null

  const shouldDisplayCompareAtPrice =
    !!(
      compareAtPrice &&
      parseFloat(compareAtPrice.amount) > parseFloat(price.amount)
    ) ||
    (discount?.enableDiscount && !noDiscount) ||
    (mockDiscount && discount?.enableDiscount)

  const theComparedPrice = () => {
    const priceWithDiscount = discount?.enableDiscount ? price : compareAtPrice
    let mockedDiscount = price

    if (discount?.enableDiscount && mockedDiscount) {
      mockedDiscount = {
        amount: `${Math.ceil(
          parseInt(price.amount) / ((100 - discount?.percentage) / 100),
        )}`,
        currencyCode: 'USD',
      }
    }

    return mockDiscount ? mockedDiscount : priceWithDiscount
  }

  return (
    <>
      <PricePrimitive
        noZeros={noZeros}
        price={!noDiscount ? formatDiscount(price, discount) : price}
      />
      {shouldDisplayCompareAtPrice && (
        <ComparedPrice>
          <PricePrimitive noZeros={noZeros} price={theComparedPrice()} />
        </ComparedPrice>
      )}
    </>
  )
}
