import {
  MediaFieldType,
  SanityImageMediaPayload,
  ShopifyImageMediaPayload,
} from '@magal/models'
import { getClient } from '@magal/services/sanity-service'
import imageUrlBuilder from '@sanity/image-url'

import { shopifyImageLoader } from './shopifyImageLoader'

const builder = imageUrlBuilder(getClient(false))

const getMediaDimensions = (
  mediaPayload: SanityImageMediaPayload | ShopifyImageMediaPayload,
): { width: number; height: number } => {
  const mediaWidth = mediaPayload?.image?.width
  const mediaHeight = mediaPayload?.image?.height

  // landscape
  if (mediaWidth > mediaHeight) {
    return {
      width: 1200,
      height: 630,
    }
  }

  // portrait
  return {
    width: 900,
    height: 1200,
  }
}

export const seoImageLoader = (
  media?: MediaFieldType,
  altText?: string,
): { url: string; width: number; height: number; alt: string } | null => {
  const type = media?.mediaPayload?.type

  if (!type) {
    return null
  }

  const alt = altText || media.alt

  switch (type) {
    case 'sanity-image': {
      if (!media.mediaPayload.image?.asset) {
        return null
      }
      const { width, height } = getMediaDimensions(media.mediaPayload)

      return {
        url: builder
          .image(media.mediaPayload.image)
          .width(width)
          .height(height)
          .url(),
        width,
        height,
        alt: alt ?? '',
      }
    }
    case 'shopify-image': {
      if (!media.mediaPayload.image.src) {
        return null
      }
      const { width, height } = getMediaDimensions(media.mediaPayload)

      return {
        url: shopifyImageLoader({
          src: media.mediaPayload.image.src,
          width,
          height,
        }),
        width,
        height,
        alt: alt ?? '',
      }
    }
    default:
      return null
  }
}
