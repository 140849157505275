import { styled } from '@magal/styles'
import { FC } from 'react'
import { ROUTE_BASE_PRODUCTS } from '@magal/configs'
import { Link } from '../Link/Link'
import { ShopifyProductColor } from '@magal/models'
import { useProductsSettings } from '@magal/product'
import { useOrderProductsGlobalColor } from './useOrderProductsGlobalColor'

export const ProductSwatchesStyled = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  placeContent: 'center start',
  gridAutoColumns: 'auto',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  span: {
    projectFont: 'body02',
    placeSelf: 'center',
  },
})

export const SwatchInner = styled('div', {
  display: 'grid',
  borderRadius: '$rMax',
  background: '$gray400',
  position: 'relative',
  height: '$28',
  width: '$28',
  '&:after': {
    content: '',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    right: '-2px',
    bottom: '-2px',
    zIndex: '1',
    pointerEvents: 'none',
    border: '1px solid transparent',
    borderRadius: '$rMax',
  },
  '&>div': {
    overflow: 'hidden',
    borderRadius: '$rMax',
  },
  variants: {
    small: {
      true: { width: '$12', height: '$12' },
    },
  },
})
export const SwatchWrap = styled(Link, {
  cursor: 'pointer',
  display: 'grid',
  placeItems: 'center',
  width: '$36',
  height: '$36',
  margin: '$2 $6',
  [`&:hover ${SwatchInner}:after`]: {
    borderColor: '$gray500',
  },
  variants: {
    isActive: {
      true: {
        [`${SwatchInner}:after`]: { borderColor: '$green_09 !important' },
      },
    },
    small: {
      true: { width: '$24', height: '$24' },
    },
  },
})

export type ProductSwatchType = {
  href: string
  isActive?: boolean
  label?: string
  color?: string
  preventScroll?: boolean
  small?: boolean
}

export const ProductSwatch: FC<ProductSwatchType> = ({
  color,
  href,
  isActive,
  preventScroll,
  small = false,
}) => {
  return (
    <SwatchWrap
      isActive={isActive}
      href={href}
      scroll={!preventScroll}
      small={small}
    >
      <SwatchInner small={small} css={{ background: color }} />
    </SwatchWrap>
  )
}

export const ProductSwatches: FC<{
  productColors?: ShopifyProductColor[]
  currentProductColorId?: string
  small?: boolean
  preventScroll?: boolean
}> = ({
  productColors,
  small = false,
  preventScroll = false,
  currentProductColorId,
}) => {
  const { colorsConfig } = useProductsSettings()
  const { orderProductColor } = useOrderProductsGlobalColor(productColors || [])

  if (!(colorsConfig && productColors && currentProductColorId)) return null

  return (
    <ProductSwatchesStyled>
      {orderProductColor?.map((item: ShopifyProductColor, i: number) => {
        const { label, color } = colorsConfig[item.id] || {}
        return (
          <ProductSwatch
            key={`${i}${label}`}
            label={label}
            color={color}
            href={`${ROUTE_BASE_PRODUCTS}/${item.handle}`}
            isActive={currentProductColorId === item.id}
            preventScroll={preventScroll}
            small={small}
          />
        )
      })}
    </ProductSwatchesStyled>
  )
}
