import { FC } from 'react'
import { MediaFieldType } from '@magal/models'
import { styled } from '@magal/styles'
import { Media } from '../Media/Media'

const Container = styled('div', {
  lin: {
    padding: [10, '$48'],
  },
})

export const MediaDemo: FC<{
  imageSampleField: MediaFieldType
  videoSampleField: MediaFieldType
}> = ({ imageSampleField, videoSampleField }) => {
  return (
    <Container>
      <h2>Image (original)</h2>
      <Media {...imageSampleField} />
      <h2>Image (hardcrop landscape1)</h2>
      <Media {...imageSampleField} hardcropRatio={'landscape1'} />
      <h2>Image (hardcrop portrait2)</h2>
      <Media {...imageSampleField} hardcropRatio={'portrait2'} />
      <h2>Image (hardcrop square)</h2>
      <Media {...imageSampleField} hardcropRatio={'square'} />
      <h2>Image Fill (fixed height 700)</h2>
      <div style={{ height: 700, width: '100%' }}>
        <Media {...imageSampleField} layout="fill" />
      </div>
      <h2>Video (original)</h2>
      <Media {...videoSampleField} loop />
      <h2>Video (fixed height)</h2>
      <div style={{ height: 700, width: '100%' }}>
        <Media {...videoSampleField} loop layout="fill" />
      </div>
    </Container>
  )
}
