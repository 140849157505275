import { getLocaleRegionIdFromPath } from '@magal/utils'
import {
  ServiceResponse,
  ShopifyCollection,
  ShopifyCollectionRes,
  ShopifyCollectionSortKey,
  ShopifyProduct,
} from '@magal/models'
import { createShopifyClient } from './createShopifyClient'
import { gql } from 'graphql-request'
import { PRODUCT_BASIC_FRAGMENT } from './fragments/productFragment'
import { formatProduct } from './helpers/formatProduct'
import { filterProducts } from './helpers/filterProducts'
import {
  COLLECTION_INITIAL_PRODUCTS_COUNT,
  COLLECTION_LOAD_MORE_PRODUCTS_COUNT,
} from '@magal/configs'

// // TODO maybe there is better way to figure it out
// const getCollectionProductsCountFromFilters = (
//   filters: ShopifyCollectionProductsFilterRes[],
// ): number | null => {
//   const availabilityFilter = filters.find(
//     (f) => f.id === 'filter.v.availability',
//   )
//   return availabilityFilter?.values[0].count ?? null
// }

const COLLECTION_QUERY = gql`
  ${PRODUCT_BASIC_FRAGMENT}
  query (
    $handle: String!
    $regionId: CountryCode
    $afterCursor: String
    $beforeCursor: String
    $sortKey: ProductCollectionSortKeys
    $reverse: Boolean
    $count: Int
  ) @inContext(country: $regionId) {
    collection(handle: $handle) {
      id
      handle
      title
      description
      seo {
        title
        description
      }
      products(
        first: $count
        after: $afterCursor
        before: $beforeCursor
        sortKey: $sortKey
        reverse: $reverse
      ) {
        filters {
          id
          label
          values {
            count
            id
            input
            label
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...ProductBasicFragment
        }
      }
    }
  }
`

type GetShopifyCollectionProductsRet = {
  products: ShopifyProduct[]
  pageInfo: {
    hasNextPage: boolean
    endCursor: string
  }
}

export const getShopifyCollectionProducts: (
  collectionInfo: {
    handle: string
    afterCursor?: string
    beforeCursor?: string
    sortKey?: ShopifyCollectionSortKey
    reverse?: boolean
  },
  regionId: string,
  localeId: string,
) => Promise<ServiceResponse<GetShopifyCollectionProductsRet>> = async (
  collectionInfo,
  regionId,
  localeId,
) => {
  const shopifyClient = createShopifyClient(localeId)
  return await shopifyClient
    .rawRequest<{
      collection: ShopifyCollectionRes
    }>(COLLECTION_QUERY, {
      ...collectionInfo,
      count: COLLECTION_LOAD_MORE_PRODUCTS_COUNT,
      regionId,
    })
    .then((res) => {
      const incomingCollection = res.data.collection

      return {
        status: 'OK',
        data: incomingCollection
          ? {
              products: filterProducts(
                incomingCollection.products.nodes.map(formatProduct),
              ),
              pageInfo: incomingCollection.products.pageInfo,
            }
          : null,
      } as ServiceResponse<GetShopifyCollectionProductsRet>
    })
    .catch((e) => {
      const err = new Error(e)
      return { status: 'ERROR', errors: [err] }
    })
}

export const getShopifyCollection = async (
  handle: string,
  locale?: string,
): Promise<ServiceResponse<ShopifyCollection>> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)
  return await shopifyClient
    .rawRequest<{
      collection: ShopifyCollectionRes
    }>(COLLECTION_QUERY, {
      handle,
      regionId,
      count: COLLECTION_INITIAL_PRODUCTS_COUNT,
    })
    .then((res) => {
      const incomingCollection = res.data.collection
      return {
        status: 'OK',
        data: incomingCollection
          ? {
              ...incomingCollection,
              products: {
                count: null,
                items: filterProducts(
                  incomingCollection.products.nodes.map(formatProduct),
                ),
                pageInfo: incomingCollection.products.pageInfo,
              },
            }
          : null,
      } as ServiceResponse<ShopifyCollection>
    })
    .catch((e) => {
      const err = new Error(e)
      return { status: 'ERROR', errors: [err] }
    })
}
