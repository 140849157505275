import React, { FC } from 'react'
import {
  StyledClickable,
  StyledClickableProps,
} from '../StyledClickable/StyledClickable'
import { styled } from '@magal/styles'
import { Loader } from '../Loader/Loader'

const Wrapper = styled(StyledClickable, {
  position: 'relative',
  variants: {
    isLoading: {
      true: {
        opacity: '0.75',
        '& > span': {
          opacity: 0,
        },
      },
    },
  },
})

const StyledLoader = styled('div', {
  content: '',
  position: 'absolute',
  top: '50%',
  left: '20%',
  width: '60%',
  variants: {
    isLoading: {
      false: {
        opacity: 0,
      },
    },
  },
})

type ButtonProps = StyledClickableProps & {
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
}

export const ButtonWithLoader: FC<ButtonProps> = ({
  children,
  size,
  appearance,
  type,
  isLoading = false,
  ...restProps
}) => {
  // two returns are needed: adding div to children makes it required
  // to add a wrapper to button's content if there's grid display involved
  if (isLoading)
    return (
      <Wrapper
        size={size}
        appearance={appearance}
        type={type}
        isLoading={isLoading}
        {...restProps}
      >
        <StyledLoader isLoading={isLoading}>
          <Loader />
        </StyledLoader>
        <span>{children}</span>
      </Wrapper>
    )
  return (
    <Wrapper size={size} appearance={appearance} type={type} {...restProps}>
      {children}
    </Wrapper>
  )
}
