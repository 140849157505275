import { FC, useState, useEffect } from 'react'

import { styled } from '@magal/styles'
import { Link, Media, ProductVariantPrice, Loader } from '@magal/components'
import { getProductRootTitle } from '@magal/services/shopify-service'
import { ROUTE_BASE_PRODUCTS } from '@magal/configs'
import { Upsell } from '@magal/models'
import { ChevronDown } from '@magal/icons'
import { useProductsSettings } from '@magal/product'
import { useTranslation } from 'react-i18next'

const UpsellWrapper = styled('article', {
  display: 'inline-flex',
  flexDirection: 'column',
  minWidth: '100%',
  marginBottom: '$12',
})

const MediaWrap = styled('div', {
  background: '$gray300',
  width: 90,
  height: 90,
  marginRight: 12,
})

const Wrap = styled('div', {
  display: 'flex',
  marginBottom: 12,
})

const ContentWrap = styled('div', {
  display: 'grid',
  gridTemplateRows: '.2fr .5fr .5fr',
})

const Title = styled('h2', {
  projectFont: 'body01',
  fontSize: 13,
  '@lg': {
    fontSize: 14,
  },
})

const AddBtn = styled('button', {
  projectFont: 'priceSmall',
  alignSelf: 'center',
  background: 'none',
  border: '1px solid $red300',
  color: '$red300',
  padding: '$8 $20',
  display: 'inline-block',
  marginRight: 'auto',
  width: 'auto',
})

const SelectWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  width: '100%',
  alignItems: 'center',
  border: '2px solid transparent',
  borderRadius: '$r1',
  backgroundColor: '$gray400',
  variants: {
    hasError: {
      true: {
        borderColor: '$red400',
      },
    },
  },
})

const Select = styled('select', {
  projectFont: 'body01',
  color: '$green_09',
  backgroundColor: '$gray400',
  border: 'none',
  width: '100%',
  borderRadius: '$r1',
  fontSize: '14px',
  padding: '0 $8',
  height: '28px',
  display: 'grid',
  alignItems: 'center',
  appearance: 'none',
  gridRow: 1,
  gridColumn: 1,
})

const IconWrap = styled('div', {
  display: 'grid',
  gridRow: 1,
  gridColumn: 1,
  justifyContent: 'end',
  alignItems: 'center',
  padding: '3px 0',
  pointerEvents: 'none',
})

const ProductColor = styled('span', {
  display: 'block',
  projectFont: 'caps07',
})

export const UpsellItem: FC<{
  upsell: Upsell
  onATC: (id: { id: string; original: string | null }) => void
}> = ({ upsell, onATC }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const { colorsConfig } = useProductsSettings()
  const { t } = useTranslation('sectionProductConversionArea')
  const {
    title,
    featuredImage,
    priceRange,
    compareAtPriceRange,
    variants,
    color,
  } = JSON.parse(JSON.stringify(upsell.item))

  const handleATC = () => {
    if (selectedValue === '') {
      setHasError(true)

      return
    }

    setHasError(false)
    onATC({ id: selectedValue, original: upsell.original })

    setIsLoading(true)
  }

  const handleOptionChange = (option: string) => {
    setHasError(false)
    setSelectedValue(option)
  }

  useEffect(() => {
    if (variants.length === 1) {
      setSelectedValue(variants[0].id)
    }
  }, [])

  if (upsell === undefined) return null

  return (
    <UpsellWrapper key={title}>
      <Wrap>
        {upsell.original ? (
          <Link href={`${ROUTE_BASE_PRODUCTS}/${upsell.original}`}>
            <MediaWrap>
              {featuredImage && (
                <Media
                  mediaPayload={featuredImage}
                  sizes={'(min-width: 430px) 174px, 116px'}
                  hardcropRatio="square"
                />
              )}
            </MediaWrap>
          </Link>
        ) : (
          <MediaWrap>
            {featuredImage && (
              <Media
                mediaPayload={featuredImage}
                sizes={'(min-width: 430px) 174px, 116px'}
                hardcropRatio="square"
              />
            )}
          </MediaWrap>
        )}
        <ContentWrap>
          {colorsConfig && colorsConfig[color] && (
            <ProductColor>{colorsConfig[color]?.label}</ProductColor>
          )}
          {upsell.original ? (
            <Link href={`${ROUTE_BASE_PRODUCTS}/${upsell.original}`}>
              <Title>{getProductRootTitle(title)}</Title>
            </Link>
          ) : (
            <Title>{getProductRootTitle(title)}</Title>
          )}

          {variants.length > 1 && (
            <SelectWrap hasError={hasError}>
              <Select onChange={(e) => handleOptionChange(e.target.value)}>
                <option value="select">Size</option>
                {Array.isArray(variants) &&
                  variants.map((val) => (
                    <option value={val.id} key={val.id} disabled={val.disabled}>
                      {val.title}
                    </option>
                  ))}
              </Select>
              <IconWrap>
                <ChevronDown />
              </IconWrap>
            </SelectWrap>
          )}
        </ContentWrap>
      </Wrap>

      <AddBtn onClick={handleATC}>
        {isLoading ? (
          <Loader size={'default'} />
        ) : (
          <div>
            {t('addFor')}{' '}
            <ProductVariantPrice
              noZeros
              noDiscount
              price={priceRange.minVariantPrice}
              compareAtPrice={compareAtPriceRange.minVariantPrice}
            />
          </div>
        )}
      </AddBtn>
    </UpsellWrapper>
  )
}
