import { getLocaleRegionIdFromPath } from '@magal/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { SectionCollectionProps } from '@magal/models'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createLinkProjection } from './projections/createLinkProjection'
import { createMediaProjection } from './projections/createMediaProjection'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'

export const getSearch = async (
  localeRegionString: string,
  preview = false,
): Promise<
  | { promoTiles: SectionCollectionProps['promoTiles']; pageBuilder: [] }
  | undefined
> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)

  const query = groq`*[!(_id in path('drafts.**')) && _type == "search"][0]{
    promoTiles{
      "index": index,
      icon${MEDIA_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      "href": link${LINK_PROJECTION}.href,
    }[],
    "pageBuilder": *[ !(_id in path('drafts.**')) && _type == "search"][0] {
      pageBuilder[]${PAGE_BUILDER_PROJECTION}
    }.pageBuilder
  }`

  return await getClient(preview).fetch(query)
}
