import { FC, ReactElement } from 'react'
import { useHeightAnimation } from '../helpers/useHeightAnimation'

export type AccordionHeaderProps = {
  isOpen: boolean
  id: string
  onClick: () => void
  'aria-expanded': boolean
  'aria-controls': string
}
export type AccordionStatelessProps = {
  accordionId: string
  isOpen?: boolean
  onRequestOpen: (id: string) => void
  onRequestClose: (id: string) => void
  header: (accordionHeaderProps: AccordionHeaderProps) => ReactElement
  transitionTime?: number
}

export const AccordionStateless: FC<AccordionStatelessProps> = ({
  accordionId,
  isOpen = false,
  onRequestClose,
  onRequestOpen,
  children,
  header,
  transitionTime = 300,
}) => {
  const [bodyRef, styles] = useHeightAnimation<HTMLDivElement>({
    isActive: isOpen,
    duration: transitionTime,
  })

  const bodyId = `accordion-body-${accordionId}`
  const headerId = `accordion-header-${accordionId}`

  const accordionHeaderProps = {
    isOpen,
    id: headerId,
    onClick: () =>
      isOpen ? onRequestClose(accordionId) : onRequestOpen(accordionId),
    'aria-expanded': isOpen,
    'aria-controls': bodyId,
  }

  return (
    <>
      {header(accordionHeaderProps)}
      <div
        role={'region'}
        ref={bodyRef}
        style={styles}
        id={bodyId}
        aria-labelledby={headerId}
        aria-hidden={!isOpen}
      >
        {children}
      </div>
    </>
  )
}
