import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClientError } from 'graphql-request'
import { useRouter } from 'next/router'

import { createCustomerClient, ErrorResponseType } from '../customerService'
import { useCustomerContext } from '../customerContext'

import {
  validateRequired,
  validateMatchesPassword,
  useFormSubmitState,
  SubmitButton,
  FormTextField,
} from '@magal/forms'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { Form } from '../components/Form'

interface ActivateAccountFormProps {
  className?: string
  activationUrl?: string
  shouldLoginOnSuccess?: boolean
  onSubmitSuccess?: () => void
}

type FormValues = {
  password?: string
  confirmPassword?: string
}

const defaultValues: FormValues = {
  password: '',
  confirmPassword: '',
}

export const ActivateAccountForm: FC<ActivateAccountFormProps> = ({
  className,
  activationUrl = '',
  shouldLoginOnSuccess = true,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
    watch,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })
  const { setToken } = useCustomerContext()

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({ form, defaultValues })

  const handleResponseErrors = (errors: ErrorResponseType[]) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const { password = '' } = data
      const response = await customerClient.activate({
        activationUrl,
        password,
      })
      const errors = response?.customerActivateByUrl?.customerUserErrors

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      const customer = response?.customerActivateByUrl?.customer
      if (!customer) {
        return setError()
      }

      if (shouldLoginOnSuccess) {
        const email = response?.customerActivateByUrl?.customer?.email

        const loginResponse = await customerClient.createToken({
          email,
          password,
        })

        const loginErrors =
          loginResponse?.customerAccessTokenCreate?.customerUserErrors

        if (loginErrors?.length) {
          return handleResponseErrors(loginErrors)
        }

        const token =
          loginResponse?.customerAccessTokenCreate?.customerAccessToken
            ?.accessToken

        if (!token) {
          return setError()
        }

        setToken(token)
      }

      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="reset-password-form"
    >
      <FormTextField
        control={control}
        rules={{
          deps: ['password', 'confirmPassword'],
          ...validateRequired(t),
        }}
        autoComplete="new-password"
        type="password"
        name="password"
        label={t('activateAccountForm.passwordFieldLabel')}
      />
      <FormTextField
        control={control}
        rules={{
          deps: ['password', 'confirmPassword'],
          ...validateRequired(t),
          ...validateMatchesPassword(t, watch('password')),
        }}
        autoComplete="new-password"
        type="password"
        name="confirmPassword"
        label={t('activateAccountForm.confirmPasswordFieldLabel')}
      />
      <SubmitButton
        text={t('activateAccountForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
    </Form>
  )
}
