import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClientError } from 'graphql-request'
import { useRouter } from 'next/router'
import {
  validateRequired,
  useFormSubmitState,
  validateEmail,
  SubmitButton,
  FormTextField,
} from '@magal/forms'

import { createCustomerClient, ErrorResponseType } from '../customerService'
import { useCustomerContext } from '../customerContext'

import { removeEmptyProperties } from '../helpers/removeEmptyProperties'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { FormWrapper } from '../components/FormWrapper'
import { Form } from '../components/Form'

interface RegisterFormProps {
  className?: string
  shouldLoginOnSuccess?: true
  onSubmitSuccess?: () => void
}

type FormValues = {
  acceptsMarketing?: boolean
  email: string
  firstName?: string
  lastName?: string
  password: string
}

const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  acceptsMarketing: true,
}

export const RegisterForm: FC<RegisterFormProps> = ({
  className,
  shouldLoginOnSuccess = true,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })
  const { setToken } = useCustomerContext()

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({ form, defaultValues })

  const handleResponseErrors = (errors: ErrorResponseType[]) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const response = await customerClient.create(removeEmptyProperties(data))
      const errors = response?.customerCreate?.customerUserErrors

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      const customer = response?.customerCreate?.customer
      if (!customer) {
        return setError()
      }

      if (shouldLoginOnSuccess) {
        const { email, password } = data
        const loginResponse = await customerClient.createToken({
          email,
          password,
        })

        const loginErrors =
          loginResponse?.customerAccessTokenCreate?.customerUserErrors || []

        if (loginErrors.length) {
          return handleResponseErrors(loginErrors)
        }

        const token =
          loginResponse?.customerAccessTokenCreate?.customerAccessToken
            ?.accessToken
        if (!token) {
          return setError()
        }

        setToken(token)
      }

      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <FormWrapper
      isRequiredTextVisible={true}
      title={t('registerForm.header')}
      description={t('registerForm.description')}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={className}
        name="register-form"
        extraSubmitButtonPadding={true}
      >
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="name"
          name="firstName"
          label={t('registerForm.firstNameFieldLabel') + '*'}
        />
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="family-name"
          name="lastName"
          label={t('registerForm.lastNameFieldLabel') + '*'}
        />
        <FormTextField
          control={control}
          rules={{ ...validateRequired(t), ...validateEmail(t) }}
          autoComplete="email"
          type="email"
          name="email"
          id="email-register"
          label={t('registerForm.emailFieldLabel') + '*'}
        />
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="new-password"
          name="password"
          type="password"
          id="password-register"
          label={t('registerForm.passwordFieldLabel') + '*'}
        />

        <SubmitButton
          text={t('registerForm.submitButtonLabel')}
          helpText={submitMessage}
          invalid={invalid}
          success={success}
          loading={isSubmitting}
        />
      </Form>
    </FormWrapper>
  )
}
