export const sizes = {
  0: '0px',
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  28: '28px',
  32: '32px',
  36: '36px',
  40: '40px',
  48: '48px',
  56: '56px',
  64: '64px',
  72: '72px',
  96: '96px',
  120: '120px',
  144: '144px',
  168: '168px',
  192: '192px',
  240: '240px',
  288: '288px',
  378: '378px',
  416: '416px',
  buttonHeightM: '24px',
  buttonHeightS: '22px',
  buttonHeightL: '51px',
  containerMarginMobile: '16px',
  containerMarginDesktop: '30px',
  maxWidthS: '580px',
  maxWidthM: '880px',
  maxWidthL: '1080px',
  maxWidthXL: '1280px',
  headerHeightMobile: '60px',
  headerHeightDesktop: '70px',
  promoBarHeightMobile: '60px',
  promoBarHeightDesktop: '50px',
  promoBannerHeightMobile: '50px',
  promoBannerHeightDesktop: '60px',
} as const
