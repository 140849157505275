import {
  ShopifyData,
  SectionShopifyId,
  PageBuilderSection,
  ShopifyIdType,
  ShopifyProductExtendedRes,
  ShopifyProductRes,
  ShopifyProductVariantRes,
  ShopifyCollectionSliderExcerpt,
  ShopifyCollectionRes,
  ServiceResponse,
} from '@magal/models'

import { validateShopifyIds } from './helpers/validateShopifyIds'
import { fetchServiceWithRetry, getLocaleRegionIdFromPath } from '@magal/utils'
import { createShopifyClient } from './createShopifyClient'
import { gql } from 'graphql-request'
import { PRODUCT_VARIANT_FRAGMENT } from './fragments/productFragment'
import { SIMPLE_COLLECTION_FRAGMENT } from './fragments/simpleCollectionFragment'
import {
  formatProduct,
  formatVariant,
  formatExtendedProduct,
} from './helpers/formatProduct'
import { captureException } from '@sentry/nextjs'

const formatCollection = (
  collection: ShopifyCollectionRes,
): ShopifyCollectionSliderExcerpt => {
  return {
    ...collection,
    products: collection.products.nodes.map(formatProduct),
  }
}

const QUERY = gql`
  ${PRODUCT_VARIANT_FRAGMENT}
  ${SIMPLE_COLLECTION_FRAGMENT}
  fragment ProductExtendedFragment on Product {
    id
    handle
    title
    description
    descriptionHtml
    tags
    handle
    productType
    featuredImage {
      ...ImageFragment
    }
    images(first: 20) {
      nodes {
        ...ImageFragment
      }
    }
    options {
      name
      values
    }
    variants(first: 100) {
      nodes {
        ...ProductVariantFragment
      }
    }
    compareAtPriceRange {
      ...ProductPriceRangeFragment
    }
    priceRange {
      ...ProductPriceRangeFragment
    }
    collections(first: 10) {
      edges {
        node {
          title
          handle
        }
      }
    }
    metafields(
      identifiers: [
        { namespace: "product", key: "featured" }
        { namespace: "product", key: "color" }
        { namespace: "product", key: "materials" }
        { namespace: "product", key: "advancedTemplate" }
      ]
    ) {
      key
      namespace
      value
    }
    seo {
      title
      description
    }
    reviewsSummary: metafield(namespace: "okendo", key: "summaryData") {
      value
    }
  }
  query (
    $regionId: CountryCode!
    $productBasic: [ID!]!
    $productExtended: [ID!]!
    $variantBasic: [ID!]!
    $collectionSliderExcerpt: [ID!]!
  ) @inContext(country: $regionId) {
    productBasic: nodes(ids: $productBasic) {
      ...ProductBasicFragment
    }
    productExtended: nodes(ids: $productExtended) {
      ...ProductExtendedFragment
    }
    variantBasic: nodes(ids: $variantBasic) {
      ...ProductVariantFragment
    }
    collectionSliderExcerpt: nodes(ids: $collectionSliderExcerpt) {
      ...SimpleCollectionFragment
    }
  }
`

const __getShopifyDataByNodeIds = async (
  locale: string,
  allShopifyIds: SectionShopifyId[],
): Promise<ServiceResponse<ShopifyData>> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)

  if (allShopifyIds.length === 0) {
    return { status: 'OK', data: null }
  }

  // group shopify ids by their type
  const shopifyIdsByType = allShopifyIds.reduce<
    Record<ShopifyIdType, string[]>
  >(
    (acc, val) => {
      return {
        ...acc,
        [val.type]: [...acc[val.type], val.id],
      }
    },
    {
      productBasic: [],
      productExtended: [],
      variantBasic: [],
      collectionSliderExcerpt: [],
    },
  )

  return await shopifyClient
    .rawRequest<{
      productBasic: ShopifyProductRes[]
      productExtended: ShopifyProductExtendedRes[]
      variantBasic: ShopifyProductVariantRes[]
      collectionSliderExcerpt: ShopifyCollectionRes[]
    }>(QUERY, { ...shopifyIdsByType, regionId })
    .then((res) => {
      return {
        status: 'OK',
        data: {
          productBasic: res.data.productBasic
            .filter((node) => !!node)
            .map(formatProduct)
            .reduce((acc, val) => {
              return {
                ...acc,
                [val.id]: val,
              }
            }, {}),
          productExtended: res.data.productExtended
            .filter((node) => !!node)
            .map(formatExtendedProduct)
            .reduce((acc, val) => {
              return {
                ...acc,
                [val.id]: val,
              }
            }, {}),
          variantBasic: res.data.variantBasic
            .filter((node) => !!node)
            .map(formatVariant)
            .reduce((acc, val) => {
              return {
                ...acc,
                [val.id]: val,
              }
            }, {}),
          collectionSliderExcerpt: res.data.collectionSliderExcerpt
            .filter((node) => !!node)
            .map(formatCollection)
            .reduce((acc, val) => {
              return {
                ...acc,
                [val.id]: val,
              }
            }, {}),
        },
      } as ServiceResponse<ShopifyData>
    })
    .catch((e) => {
      const err = new Error(e)
      return { status: 'ERROR', errors: [err] }
    })
}

export const getShopifyData = async (
  locale: string,
  pageBuilderSections: PageBuilderSection[],
): Promise<ShopifyData | null> => {
  if (!pageBuilderSections) {
    return null
  }

  const allSectionsShopifyIds: SectionShopifyId[] = pageBuilderSections.reduce<
    Array<SectionShopifyId>
  >((acc, section) => {
    if ('shopifyIds' in section && !!section.shopifyIds) {
      const { shopifyIds } = section
      return [...acc, ...validateShopifyIds(shopifyIds)]
    }
    return acc
  }, [])

  // return without fetching data if no ids were found in any section
  if (allSectionsShopifyIds.length === 0) {
    return null
  }

  const res = await fetchServiceWithRetry(
    () => __getShopifyDataByNodeIds(locale, allSectionsShopifyIds),
    3,
  )

  if (res.status === 'ERROR') {
    captureException(res.errors)
    throw res.errors
  }
  return res.data
}
