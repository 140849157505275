import {
  ShopifyMediaEdge,
  ShopifyImageMediaPayload,
  ShopifyVideoMediaPayload,
  ShopifyExternalVideoMediaPayload,
  ShopifyVideoType,
  ShopifyExternalVideoType,
  ShopifyMediaPayload,
  ShopifyMediaImageType,
} from '@magal/models'

export const mapMediaImage = (
  input: ShopifyMediaImageType,
): ShopifyImageMediaPayload => {
  const { height, width, altText, url, id } = input.image || {}
  return {
    type: 'shopify-image',
    image: {
      height: Number(height),
      width: Number(width),
      alt: altText,
      src: url || '',
      id: id || '',
    },
  }
}

export const mapVideo = (video: ShopifyVideoType): ShopifyVideoMediaPayload => {
  return {
    type: 'shopify-media-video',
    video,
  }
}

export const mapExternalVideo = (
  video: ShopifyExternalVideoType,
): ShopifyExternalVideoMediaPayload => {
  return {
    type: 'shopify-media-external-video',
    video,
  }
}

const buildMedia = (input: ShopifyMediaEdge): ShopifyMediaPayload | null => {
  const type = input.node.mediaContentType

  switch (type) {
    case 'EXTERNAL_VIDEO':
      return mapExternalVideo(input.node as ShopifyExternalVideoType)
    case 'VIDEO':
      return mapVideo(input.node as ShopifyVideoType)
    case 'IMAGE':
      return mapMediaImage(input.node as ShopifyMediaImageType)
    default:
      return null
  }
}

export const mapMedia = (input?: {
  edges: ShopifyMediaEdge[]
}): ShopifyMediaPayload[] => {
  return (
    input?.edges
      ?.map(buildMedia)
      .filter((media): media is ShopifyMediaPayload => !!media) || []
  )
}
