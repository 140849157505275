import { Dispatch, FC, SetStateAction } from 'react'
import { UpdateAddressForm } from '../../../forms/UpdateAddressForm'
import { useCustomerContext } from '../../../customerContext'
import { FormWrapper } from '../../../components/FormWrapper'
import { AddressResponseType } from '../../../customerService'

export const EditAddress: FC<{
  title?: string
  setIsEditVisible: Dispatch<SetStateAction<boolean>>
  address: AddressResponseType
  isDefault: boolean
}> = ({ title = '', setIsEditVisible, address, isDefault }) => {
  const { updateCustomer } = useCustomerContext()

  const handleSuccess = async () => {
    await updateCustomer()
    setIsEditVisible(false)
  }

  return (
    <FormWrapper title={title}>
      <UpdateAddressForm
        defaultValues={address}
        onCancelClick={() => setIsEditVisible(false)}
        onSubmitSuccess={handleSuccess}
        isDefault={isDefault}
      />
    </FormWrapper>
  )
}
