import { FC } from 'react'
import { styled } from '@magal/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { ClientError } from 'graphql-request'

import { createCustomerClient, ErrorResponseType } from '../customerService'
import { useCustomerContext } from '../customerContext'

import {
  validateRequired,
  useFormSubmitState,
  SubmitButton,
  FormTextField,
  FormCheckboxField,
  FormSelectField,
} from '@magal/forms'
import { removeEmptyProperties } from '../helpers/removeEmptyProperties'
import { Button } from '@magal/components'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { shopifyCountries } from '../helpers/shopifyCountries'
import { Form } from '../components/Form'

interface RegisterFormProps {
  className?: string
  onCancelClick?: () => void
  onSubmitSuccess?: () => void
}

type FormValues = {
  lastName?: string
  firstName?: string
  address1?: string
  address2?: string
  province?: string
  country?: string
  phone?: string
  zip?: string
  city?: string
  isDefault?: boolean
}

const defaultValues: FormValues = {
  lastName: '',
  firstName: '',
  address1: '',
  address2: '',
  province: '',
  country: 'United States',
  phone: '',
  zip: '',
  city: '',
  isDefault: false,
}

const StyledFormTextField = styled(FormTextField, {
  maxWidth: '$416',
})

const StyledSelectField = styled(FormSelectField, {
  width: 'fit-content',
})

export const AddAddressForm: FC<RegisterFormProps> = ({
  className,
  onCancelClick,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({
    form,
    defaultValues,
    keepValuesOnSuccess: true,
  })
  const { accessToken } = useCustomerContext()
  const customerAccessToken = accessToken || ''

  const handleResponseErrors = (errors: ErrorResponseType[]) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const { isDefault, ...restOfAddress } = data

      const response = await customerClient.createAddress({
        address: removeEmptyProperties({ ...restOfAddress }),
        customerAccessToken,
      })

      const errors = response?.customerAddressCreate?.customerUserErrors || []

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      if (!response?.customerAddressCreate?.customerAddress) {
        return setError()
      }

      // set address as default if requested
      if (isDefault) {
        const addressId = response?.customerAddressCreate?.customerAddress?.id

        const defaultAddressResponse =
          await customerClient.updateDefaultAddress({
            addressId,
            customerAccessToken,
          })

        const defaultFieldErrors =
          defaultAddressResponse?.customerDefaultAddressUpdate
            ?.customerUserErrors || []

        if (defaultFieldErrors.length) {
          return handleResponseErrors(defaultFieldErrors)
        }
      }

      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="add-address-form"
    >
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="name"
        name="firstName"
        label={t('addAddressForm.firstNameFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="family-name"
        name="lastName"
        label={t('addAddressForm.lastNameFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="address-line1"
        name="address1"
        label={t('addAddressForm.address1FieldLabel')}
      />
      <StyledFormTextField
        control={control}
        autoComplete="address-line2"
        name="address2"
        label={t('addAddressForm.address2FieldLabel')}
      />
      <StyledFormTextField
        control={control}
        autoComplete="phone"
        name="phone"
        label={t('addAddressForm.phoneFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="city"
        name="city"
        label={t('addAddressForm.cityFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="postal-code"
        name="zip"
        label={t('addAddressForm.zipFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="address-level1"
        name="province"
        label={t('addAddressForm.provinceFieldLabel')}
      />
      <StyledSelectField
        control={control}
        rules={validateRequired(t)}
        autoComplete="country"
        name="country"
      >
        {shopifyCountries.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </StyledSelectField>
      <FormCheckboxField
        control={control}
        autoComplete="isDefault"
        name="isDefault"
        label={t('addAddressForm.setDefaultAddressFieldLabel')}
      />

      <SubmitButton
        text={t('addAddressForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
        appearance={'solidGreen'}
      />

      {onCancelClick && (
        <Button onClick={onCancelClick} appearance="outlineGreen">
          Cancel
        </Button>
      )}
    </Form>
  )
}
