import { RegisterForm } from '../forms/RegisterForm'
import { FC } from 'react'
import { styled } from '@magal/styles'
import { LoginForm } from '../forms/LoginForm'
import { useTranslation } from 'react-i18next'
import { useAuthRedirect } from '../useAuthRedirect'
import { Container, SectionWrap } from '@magal/components'

const Root = styled(Container, {
  display: 'grid',
  gridAutoFlow: 'rows',
  gridAutoRows: 'max-content',
  color: '$green_09',
})

const TextContainer = styled('div', {
  display: 'grid',
  gap: '$16',
})

const Heading = styled('h1', {
  projectFont: 'caps01',
})

const Description = styled('p', {
  projectFont: 'body01',
})

const FormsContainer = styled('div', {
  width: '100%',
  display: 'grid',
  gap: '$32',
  '@lg': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'minmax(min-content, max-content)',
    maxWidth: '$maxWidthM',
    margin: 'auto',
  },
})

const FormsWrapper = styled('div', {
  width: '100%',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumn: '1fr',
})

export const LoginPage: FC = () => {
  const { t } = useTranslation('account')
  useAuthRedirect()
  return (
    <SectionWrap type={'MARGINS_S'}>
      <Root>
        <TextContainer>
          <Heading>{t('loginPage.loginHeader')}</Heading>
          <Description>{t('loginPage.loginDescription')}</Description>
        </TextContainer>
        <SectionWrap type={'MARGINS_M'}>
          <FormsWrapper>
            <FormsContainer>
              <LoginForm />
              <RegisterForm />
            </FormsContainer>
          </FormsWrapper>
        </SectionWrap>
      </Root>
    </SectionWrap>
  )
}
