import { FC } from 'react'
import { styled } from '@magal/styles'
import { Button } from '@magal/components'
import { AccountIcon } from '@magal/icons'
import { useRouter } from 'next/router'
import { ROUTE_BASE_ACCOUNT, ROUTE_ACCOUNT_LOGIN } from '@magal/configs'
import { useCustomerContext } from '../customerContext'

const Wrapper = styled(Button, {
  position: 'relative',
  display: 'grid',
  alignItems: 'center',
  padding: '0 $12',
  color: '$green_09',
  '@lg': {
    padding: '0 $16',
    marginRight: '-$16',
    height: '80%',
  },
})

export const AccountButton: FC = () => {
  const router = useRouter()
  const { isLoggedIn } = useCustomerContext()

  const onClick = () => {
    if (isLoggedIn) router.push(ROUTE_BASE_ACCOUNT)
    else router.push(ROUTE_ACCOUNT_LOGIN)
  }

  return (
    <Wrapper onClick={() => onClick()}>
      <AccountIcon />
    </Wrapper>
  )
}
