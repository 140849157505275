import { FC } from 'react'
import { styled } from '@magal/styles'
import { Link } from '@magal/components'
import { useCustomerContext } from '../../../customerContext'
import { OrderItem } from '../components/OrderItem'
import { useTranslation } from 'react-i18next'
import { AccountCardWrapper } from '../components/AccountCardWrapper'

const Root = styled('div', {
  display: 'grid',
  gap: '$32',
})

const OrdersContainer = styled('div', {
  display: 'grid',
})

const Card = styled('div', {
  display: 'grid',
  padding: '$36',
  height: '100%',
})

const AddCard = styled(Card, {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  projectFont: 'caps04',
})

const OrderHeader = styled('ol', {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  justifyItems: 'stretch',
  borderBottom: '1px solid $green_09',
  projectFont: 'body01',
  li: {
    '&:last-child': {
      textAlign: 'right',
    },
  },
})

export const OrdersList: FC = () => {
  const { customer } = useCustomerContext()
  const orders = customer?.orders?.edges || []
  const { t } = useTranslation('account')

  return (
    <Root>
      <AccountCardWrapper title={t('accountPage.ordersListView.header')}>
        <OrdersContainer>
          <OrderHeader>
            <li>{t('accountPage.ordersListView.orderMenuItem')}</li>
            <li>{t('accountPage.ordersListView.dateMenuItem')}</li>
            <li>{t('accountPage.ordersListView.statusMenuItem')}</li>
            <li>{t('accountPage.ordersListView.totalMenuItem')}</li>
          </OrderHeader>
          <ol>
            {orders.map(({ node }, index) => {
              if (!node) return null
              return <OrderItem key={`orderItem-${index}`} order={node} />
            })}
            {orders.length === 0 ? (
              <li>
                <AddCard>
                  <span>{t('accountPage.ordersListView.noOrdersText')}</span>
                </AddCard>
              </li>
            ) : null}
          </ol>
        </OrdersContainer>
      </AccountCardWrapper>
      <Link href={'/'} appearance={'solidGreen'}>
        {t('accountPage.ordersListView.startShoppingButtonLabel')}
      </Link>
    </Root>
  )
}
