import { FC } from 'react'
import { CollectionPromoTile, PromoTileIcon } from '@magal/models'
import { styled } from '@magal/styles'
import { PRODUCT_CARD_IMAGE_RATIO } from '@magal/configs'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { Media } from '../Media/Media'
import { Link } from '../Link/Link'
import { useProductsSettings } from '@magal/product'

const Root = styled('div', {
  gridColumn: 'span 2',
  '@md': {
    gridColumn: 'unset',
  },
})
const ColorWrap = styled('div', {
  alignItems: 'center',
  background: '$gray300',
  color: '$green_09',
  cursor: 'pointer',
  display: 'flex',
  gap: '$16',
  padding: '$16',
  '@md': {
    aspectRatio: PRODUCT_CARD_IMAGE_RATIO,
    display: 'grid',
    gridAutoRow: 'min-content',
    padding: '$32',
    placeContent: 'center',
    textAlign: 'center',
  },
})
const Title = styled('div', {
  color: '$green_09',
  projectFont: 'caps03',
})
const Description = styled('div', {
  projectFont: 'body01',
  fontSize: '14.5px',
})
const TileLink = styled(Link, {
  projectFont: 'body01',
  textDecoration: 'underline',
})
const IconWrap = styled('div', {
  justifySelf: 'center',
  margin: '0 $8',
  width: '$120',
})

const WrapperContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
})

const IconTile: FC<PromoTileIcon> = ({ icon }) => {
  return (
    <>
      {icon && (
        <IconWrap>
          <Media {...icon} />
        </IconWrap>
      )}
    </>
  )
}

export const PromoTile: FC<CollectionPromoTile> = ({
  title,
  description,
  description2,
  description3,
  index,
  href,
  icon,
  icon_fr,
  icon_de,
}) => {
  const { locale, push } = useRouter()
  const { t } = useTranslation('collection')
  const { productCardBackgroundConfig } = useProductsSettings()
  let iconToShow = icon

  switch (locale) {
    case 'fr':
      iconToShow = icon_fr
      break
    case 'de':
      iconToShow = icon_de
      break
  }

  if (!(title && typeof index === 'number')) return null
  return (
    <Root style={{ order: index }}>
      <ColorWrap
        onClick={() => href && push(href)}
        css={
          productCardBackgroundConfig?.backgroundColor
            ? { background: productCardBackgroundConfig?.backgroundColor.hex }
            : {}
        }
      >
        <IconTile icon={iconToShow} />
        <WrapperContent>
          <Title>{title}</Title>
          <div>
            <Description>{description}</Description>
            {description2 && description2 !== '' ? (
              <Description>{description2}</Description>
            ) : null}
            {description3 && description3 !== '' ? (
              <Description>{description3}</Description>
            ) : null}
          </div>
          {href && <TileLink href={href}>{t('readMore')}</TileLink>}
        </WrapperContent>
      </ColorWrap>
    </Root>
  )
}
