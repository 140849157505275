import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/virtual'
import 'swiper/css'

import { useCartContext } from '../useCart'
import { styled } from '@magal/styles'
import { UpsellItem } from './UpsellItem'
import { AddLinesInput, CartLineType, Upsell } from '@magal/models'
import { UPSELL_ATTRIBUTE_KEY } from '@magal/configs'
import { GTMAddToCart } from '@magal/services/gtm-service'
import { useComputedCart } from '../useComputedCart'

const UpsellsMain = styled('div', {
  width: '100%',
  padding: '0 0 $8',
  boxSizing: 'content-box',
  overflowX: 'scroll',
})

const UpsellsWrapper = styled('div', {})

const UpsellHeading = styled('h3', {
  projectFont: 'body01Medium',
  fontSize: 15,
  marginBottom: 8,
  paddingLeft: '$containerMarginMobile',
  '@lg': {
    fontSize: 18,
    marginBottom: 15,
    paddingLeft: '$32',
  },
})

export const CartUpsells: FC<{
  cartUpsells: Upsell[]
  CartUpsellsTitle: string
  CartUpsellsEnabled: boolean
}> = ({ cartUpsells, CartUpsellsTitle, CartUpsellsEnabled }) => {
  const { computedCartLines, removeCartLines } = useComputedCart()
  const { addCartLines, openMiniCart } = useCartContext()
  const { t } = useTranslation('cart')

  const computedUpsells = cartUpsells.filter(
    (upsell) =>
      !computedCartLines?.some((lines) => {
        return upsell.item && lines.merchandise.product.id === upsell.item.id
      }),
  )

  const isUpsell = (line: CartLineType) =>
    line && !!line?.attributes?.find((att) => att.key === UPSELL_ATTRIBUTE_KEY)
  const upsellsOnly = computedCartLines
    ?.map(isUpsell)
    .every((item: boolean) => item === true)

  setTimeout(() => {
    if (upsellsOnly && computedCartLines) {
      const itemsToRemove = computedCartLines?.map((line) => line.id)
      removeCartLines(itemsToRemove)
    }
  }, 200)

  const handleAddToCartClick = async (item: {
    id: string
    original: string | null
  }) => {
    if (item) {
      const linesToAdd: AddLinesInput = [
        {
          merchandiseId: item.id,
          quantity: 1,
          attributes: [
            {
              key: UPSELL_ATTRIBUTE_KEY,
              value: 'true',
            },
            {
              key: t('specialDeal'),
              value: t('addon'),
            },
          ],
        },
      ]

      if (item.original) {
        linesToAdd[0].attributes?.push({
          key: '_original_url',
          value: item.original,
        })
      }

      const addedLines = await addCartLines(linesToAdd)

      if (addedLines) {
        GTMAddToCart(addedLines[0], 1)
        setTimeout(() => openMiniCart(t('addedToCart')), 200)
      }
    }
  }

  const isMobile = window.innerWidth < 1024

  const [lastSlide, setLastSlide] = useState(false)

  useEffect(() => {
    const stylesheet = document.styleSheets[0]
    stylesheet.insertRule(
      '.swiper .swiper-button-disabled { display: none !important;}',
      0,
    )
    stylesheet.insertRule(
      '.swiper-button-prev, .swiper-button-next { color: #565C54 !important; top: calc(50% - 15px) !important;}',
      0,
    )
    stylesheet.insertRule('.swiper-button-prev { left: 0 !important;}', 0)
    stylesheet.insertRule('.swiper-button-next { right: 0 !important;}', 0)
    stylesheet.insertRule(
      `.swiper-wrapper { padding: 0 ${isMobile ? 16 : 32}px !important;}`,
      0,
    )
    stylesheet.insertRule(
      '.swiper-button-prev:after, .swiper-button-next:after { font-size: 32px !important;}',
      0,
    )
    stylesheet.insertRule(
      `body.cart-slider-done .swiper-button-next { display: none !important;}`,
      0,
    )

    if (lastSlide) {
      document.body.classList.add('cart-slider-done')
    } else {
      document.body.classList.remove('cart-slider-done')
    }

    return () => {
      stylesheet.deleteRule(0)
    }
  }, [lastSlide, isMobile])

  const handleSlideChange = (swiper: any) => {
    const slidesNumber = swiper.slides.length - (isMobile ? 2 : 3)
    setLastSlide(swiper.activeIndex >= slidesNumber)
    if (!slidesNumber) swiper.allowTouchMove = false
    else swiper.allowTouchMove = true
  }

  if (!CartUpsellsEnabled || computedUpsells.length < 1) return null

  return (
    <UpsellsMain>
      <UpsellHeading>{CartUpsellsTitle}</UpsellHeading>

      <UpsellsWrapper>
        <Swiper
          modules={[Navigation]}
          onSlideChange={(e) => handleSlideChange(e)}
          onSwiper={(swiper) => handleSlideChange(swiper)}
          onUpdate={(e) => {
            e.slidePrev(0)
            handleSlideChange(e)
          }}
          spaceBetween={12}
          slidesPerView={window.innerWidth < 1024 ? 1.6 : 2.3}
          navigation
        >
          {computedUpsells.map((upsell) => {
            return (
              upsell.item && (
                <SwiperSlide key={upsell.item.id}>
                  <UpsellItem
                    upsell={upsell}
                    onATC={handleAddToCartClick}
                  ></UpsellItem>
                </SwiperSlide>
              )
            )
          })}
          <SwiperSlide></SwiperSlide>
        </Swiper>
      </UpsellsWrapper>
    </UpsellsMain>
  )
}
