import { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { styled } from '@magal/styles'
import { Button } from '@magal/components'

const TitleContainer = styled('div', {})

const Title = styled(Button, {
  display: 'grid',
  gap: '0',
  alignItems: 'center',
  justifyContent: 'left',
  gridAutoFlow: 'column',
  position: 'relative',
  projectFont: 'body01',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  color: '$green_09',
  textDecoration: 'underline',
  textDecorationColor: 'transparent',
  transition: '200ms',
  overflow: 'hidden',
  '&:hover': {
    textDecorationColor: '$green_09',
  },
  '&:before': {
    marginLeft: '-4px',
    display: 'block-inline',
    content: '',
    borderRadius: '100%',
    width: '$4',
    height: '$4',
    backgroundColor: '$green_09',
    opacity: 0,
    transition: '200ms',
  },
  variants: {
    isActive: {
      true: {
        gap: '$12',
        '&:before': {
          opacity: 1,
          marginLeft: '0',
        },
      },
    },
  },
})

interface PanelItemProps {
  title?: string
  slider: HTMLDivElement | null
  onClick: () => void
  isActive?: boolean
}

export const MenuItem: FC<PanelItemProps> = ({
  title,
  slider,
  isActive = false,
  onClick,
}) => {
  const [ref] = useInView({
    root: slider,
    threshold: 1,
  })

  return (
    <TitleContainer ref={ref} onClick={onClick}>
      <Title isActive={isActive}>{title}</Title>
    </TitleContainer>
  )
}
