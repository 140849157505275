import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@magal/styles'
import { MuxVideoMediaPayload, MuxVideoType } from '@magal/models'
import { Image } from './Image'
import { MediaProps } from '../models/MediaProps'
import { ResponsiveMediaComponent } from './ResponsiveMediaComponent'

type MuxVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: MuxVideoMediaPayload
}

type VideoAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  video: MuxVideoType
}

const Container = styled('div', {
  position: 'relative',
  background: 'black',
  img: {
    opacity: 0.75,
  },
  variants: {
    layout: {
      fill: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
      },
      responsive: {},
    },
  },
})

const VideoElement = styled('video', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
})

const VideoAdapter: FC<VideoAdapterProps> = ({
  layout,
  priority,
  video,
  alt,
  loop = true,
}) => {
  const { firstFrame, mp4, height, width } = video

  const containerStyles = (() => {
    const paddingBottom = `${(height / width) * 100}%`
    return layout === 'responsive' ? { paddingBottom } : undefined
  })()

  const containerRef = useRef()
  const [src, setSrc] = useState<string | undefined>(priority ? mp4 : undefined)

  useEffect(() => {
    const container = containerRef.current

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSrc(mp4)
          }
        })
      },
      {
        rootMargin: '200px',
      },
    )

    if (container && !priority) {
      observer.observe(container)
    }

    return () => {
      if (container && !priority) {
        observer.unobserve(container)
        observer.disconnect()
      }
    }
  }, [mp4, priority])

  return (
    <Container layout={layout} style={containerStyles} ref={containerRef}>
      <Image src={firstFrame} priority={priority} layout={'fill'} />
      <VideoElement
        autoPlay
        muted
        loop={loop}
        playsInline
        title={alt}
        src={src}
        poster={firstFrame}
      />
    </Container>
  )
}

export const MuxVideo = ({ mediaPayload, ...restOfProps }: MuxVideoProps) => {
  const { video, mobileVideo } = mediaPayload

  return (
    <ResponsiveMediaComponent
      mobileComponent={
        mobileVideo && <VideoAdapter video={mobileVideo} {...restOfProps} />
      }
      component={<VideoAdapter video={video} {...restOfProps} />}
    />
  )
}
