import { getLocaleRegionIdFromPath } from '@magal/utils'
import { createShopifyClient } from './createShopifyClient'
import { captureException, withScope } from '@sentry/nextjs'
import { gql } from 'graphql-request'
import { ROUTE_BASE_PRODUCTS } from '@magal/configs'

type FetchAllProductsRes = {
  products: {
    nodes: {
      handle: string
    }[]
    pageInfo: {
      hasNextPage: boolean
      endCursor: string
    }
  }
}

const QUERY = gql`
  fragment ProductFragment on Product {
    handle
  }
  query ($regionId: CountryCode!, $endCursor: String)
  @inContext(country: $regionId) {
    products(first: 250, after: $endCursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...ProductFragment
      }
    }
  }
`

const fetchAllProductHandles = async (
  prevProducts: { handle: string }[] = [],
  endCursor: string | undefined = undefined,
): Promise<string[]> => {
  try {
    const [regionId, localeId] = getLocaleRegionIdFromPath('')
    const shopifyClient = createShopifyClient(localeId)

    const allProducts = await shopifyClient.request<FetchAllProductsRes>(
      QUERY,
      {
        regionId,
        endCursor,
      },
    )

    const products = [...(allProducts?.products?.nodes || []), ...prevProducts]

    const hasNextPage = allProducts.products.pageInfo?.hasNextPage
    const newEndCursor = allProducts.products.pageInfo?.endCursor

    if (hasNextPage) {
      return fetchAllProductHandles(products, newEndCursor)
    }

    return products.map((p) => p.handle)
  } catch (e) {
    withScope(function (scope) {
      scope.setTransactionName(
        '[getAllShopifyProductPaths] Cannot fetch products',
      )
      captureException(e)
    })
    return []
  }
}

export const getAllShopifyProductPaths = async (): Promise<string[]> => {
  const allProductsHandles = await fetchAllProductHandles()
  return allProductsHandles.map((h) => `${ROUTE_BASE_PRODUCTS}/${h}`)
}
