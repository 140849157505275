import { LocaleId } from '@magal/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createSEOProjection } from './createSEOProjection'
import { createRichTextProjection } from './createRichTextProjection'
import { createSectionUtilityPageHeaderProjection } from '../sections/createSectionUtilityPageHeaderProjection'

export const createUtilityPageProjection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  return groq`{
      ...,
      "pageBuilder":
        [
          ${createSectionUtilityPageHeaderProjection(localeId)},
          {
            "_type": "sectionUtilityPage",
            "_key": "sectionUtilityPage",
            ${coalesceLocaleField('title', localeId)},
            "utilityPages":  *[ !(_id in path('drafts.**')) && _type == "utilityPage" ]{
              ${coalesceLocaleField('title', localeId)},
              "href": path.current
            },
            panels[] {
              ${coalesceLocaleField('title', localeId)},
              ${coalesceLocaleField(
                'description',
                localeId,
              )}[]${RICH_TEXT_PROJECTION},
            },
            ${coalesceLocaleField(
              'description',
              localeId,
            )}[]${RICH_TEXT_PROJECTION},
          },
        ],
        seo${createSEOProjection(localeId)},
    }`
}
