import React, { FC, useState } from 'react'
import { styled } from '@magal/styles'
import { Button, Link, Modal } from '@magal/components'
import { Close } from '@magal/icons'
import { useTranslation } from 'react-i18next'

const FooterButton = styled(Button, {
  projectFont: 'body01',
  color: '$green_09',
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginRight: '$8',
  },
  '&:hover': {
    textDecoration: 'underline',
  },
})
const Root = styled('div', {
  position: 'relative',
  maxWidth: '$maxWidthM',
  backgroundColor: '$green',
  display: 'grid',
  gap: '$32',
  padding: '$40 $20',
  textAlign: 'center',
  color: '$white',
  '@lg': {
    gap: '$40',
    padding: '$40',
  },
})

const CloseButton = styled(Button, {
  position: 'absolute',
  right: '$12',
  top: '$12',
  color: '$white',
})

const Title = styled('h2', {
  projectFont: 'caps02',
})

const Credit = styled('div', {
  display: 'grid',
  gap: '$40',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  padding: '$16 0 $16 0',
  projectFont: 'body01',
  borderTop: '1px $white solid',
  borderBottom: '1px $white solid',
  [`& + &`]: {
    borderTop: 'none',
    marginTop: '-$32',
  },
  '@lg': {
    [`& + &`]: {
      borderTop: 'none',
      marginTop: '-$40',
    },
  },
})

const Label = styled('p', {
  justifySelf: 'start',
  textAlign: 'left',
})

const Author = styled(Link, {
  justifySelf: 'end',
  textAlign: 'right',
  projectFont: 'caps06',
  color: '$white',
  padding: 0,
  '&:active': {
    opacity: '0.8',
  },
  '&:hover, &:focus-within, &:focus': {
    textDecorationColor: '$white',
  },
})

export const CreditsModal: FC = () => {
  const { t } = useTranslation('credits')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <FooterButton key="footer-credits" onClick={() => setIsOpen(true)}>
        {t('credits')}
      </FooterButton>
      <Modal
        preventScroll={true}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        size={'intrinsic'}
        position={'center'}
        variant={'rounded'}
      >
        <Root>
          <CloseButton onClick={() => setIsOpen(false)}>
            <Close />
          </CloseButton>
          <Title>{t('siteCredits')}</Title>
          <Credit>
            <Label>{t('design')}</Label>
            <Author href="https://brunoferdinand.com/" target={'_blank'}>
              Bruno Ferdinand
            </Author>
          </Credit>
          <Credit>
            <Label>{t('development')}</Label>
            <Author
              href="https://commerce-ui.com/?utm_source=magaljewelry.com&utm_campaign=Credits"
              target={'_blank'}
            >
              Commerce-UI
            </Author>
          </Credit>
        </Root>
      </Modal>
    </>
  )
}
