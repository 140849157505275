import { DEFAULT_LOCALE_ID, LocaleId } from '@magal/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createBlockContentProjection } from './createBlockContentProjection'
import { createMediaProjection } from './createMediaProjection'

export const createEditorialBlockContentProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  const BLOCK_CONTENT_PROJECTION = createBlockContentProjection(
    localeId,
    defaultLocaleId,
  )
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
    ...${BLOCK_CONTENT_PROJECTION},
    _type == "standardImage" => {
      _type,
      _key,
      ${coalesceLocaleField('title', localeId)},
      image${MEDIA_PROJECTION}
    },
    
  }`
}
