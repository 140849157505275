import { getLocaleRegionIdFromPath } from '@magal/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from '..'
import { captureException } from '@sentry/nextjs'
import { ROUTE_BASE_PRODUCTS } from '@magal/configs'
import { formatShopifyItem } from './helpers/formatShopifyItem'
import { SitemapPageInfo } from '@magal/models'

export type ShopifySiteMapProductsNode = {
  node: {
    handle: string
    updatedAt: string
  }
}

export type SitemapProductsRes = {
  products: {
    pageInfo: {
      hasNextPage: boolean
      endCursor: string
    }
    edges: ShopifySiteMapProductsNode[]
  }
  errors?: { message: string }[]
}

const QUERY = (endCursor?: string) => gql`
  fragment ProductFragment on Product {
    handle
    updatedAt
  }

  query ($regionId: CountryCode!) @inContext(country: $regionId) {
    products(first: 250, ${endCursor ? `after: "${endCursor}"` : ''}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
            node {
                ...ProductFragment
            }
        }
    }
  }
`

const getSitemapProducts = async (
  locale: string,
  endCursor?: string,
  prevProducts?: ShopifySiteMapProductsNode[],
): Promise<ShopifySiteMapProductsNode[] | null> => {
  try {
    const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
    const shopifyClient = createShopifyClient(localeId)

    const dataRes = await shopifyClient.request<SitemapProductsRes>(
      QUERY(endCursor),
      {
        regionId,
        endCursor,
      },
    )
    const hasNextPage = dataRes?.products?.pageInfo?.hasNextPage
    const newEndCursor = dataRes?.products?.pageInfo?.endCursor
    const currentProductsNodes = dataRes?.products?.edges || []

    const mergedProductsNodes: ShopifySiteMapProductsNode[] = [
      ...(prevProducts ? prevProducts : []),
      ...currentProductsNodes,
    ]

    const errors = dataRes.errors || []

    if (errors.length) {
      throw new Error(errors[0].message)
    }

    if (hasNextPage) {
      return await getSitemapProducts(locale, newEndCursor, mergedProductsNodes)
    }

    return mergedProductsNodes
  } catch (error) {
    captureException(error)
    return null
  }
}

export const getShopifySitemapProducts = async (
  locale: string,
): Promise<SitemapPageInfo[]> => {
  try {
    const res = await getSitemapProducts(locale)
    const resMapped = res?.map((i) => formatShopifyItem(i, ROUTE_BASE_PRODUCTS))
    return resMapped || []
  } catch (e) {
    captureException(e)
    return []
  }
}
