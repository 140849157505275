import { styled } from '@magal/styles'
import { FC } from 'react'
import { ShopifyImageMediaPayload, ShopifyPriceRange } from '@magal/models'
import { ROUTE_BASE_PRODUCTS } from '@magal/configs'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { ProductPrice } from '../Price/ProductPrice'
import { ProductSwatches } from '../ProductColorSwatches/ProductSwatches'
import { useProductsSettings } from '@magal/product'
import { StringColorSwatches } from '../ProductColorSwatches/StringColorSwatches'
import { useTranslation } from 'react-i18next'

const Root = styled('div', {
  position: 'relative',
  display: 'grid',
  alignContent: 'start',
})

const BottomWrap = styled('div', {
  paddingRight: '$16',
  display: 'grid',
  gridGap: '$4',
  background: 'white',
  zIndex: 1,
  '@lg': {
    paddingRight: '$32',
  },
})
const Title = styled('div', {
  color: '$green_09',
  projectFont: 'body01',
  lineHeight: 1,
})
const PriceWrap = styled('div', {
  padding: '$8 0',
  '@lg': {
    padding: '$16 0',
  },
})
const TopColorLabel = styled('div', {
  color: '$green_09',
  projectFont: 'caps07',
  height: '$32',
  display: 'grid',
  alignItems: 'center',
})
const ProductColors = styled('div', {
  projectFont: 'body02',
  color: '$green_09',
  display: 'grid',
  gridGap: '$4',
  alignItems: 'center',
  span: {
    display: 'none',
    whiteSpace: 'nowrap',
    '@md': {
      display: 'block',
    },
  },
  margin: '0 -$4',
  '@md': {
    margin: 0,
    minHeight: '$24',
    gridTemplateColumns: 'auto 1fr',
  },
})
const ImageWrap = styled(Link, {
  position: 'relative',
  display: 'grid',
  background: '$gray300',
  variants: {
    featured: {
      true: {
        // hacky way to match images height between featured and non-featured
        '@lg': {
          marginBottom: '-1vw',
        },
      },
    },
  },
})

const TagsContainer = styled('div', {
  zIndex: '10',
  position: 'absolute',
  padding: '$16 $20',
  display: 'flex',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maskImage:
    'linear-gradient(to left, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 10%)',
  gap: '$8',
  width: '100%',
  '@lg': {
    padding: '$24 $28',
  },
})

const Tag = styled('div', {
  backgroundColor: '$green',
  padding: '$2 $4',
  projectFont: 'caps07',
  color: '$white',
  borderRadius: '4px',
})

type ProductCardProps = {
  image: ShopifyImageMediaPayload | null
  title: string
  handle: string
  priceRange: ShopifyPriceRange
  compareAtPriceRange: ShopifyPriceRange
  color?: string
  featured?: boolean
  material?: string
  tags?: string[]
  priority?: boolean
}
export const ProductCard: FC<ProductCardProps> = ({
  image,
  title,
  priceRange,
  compareAtPriceRange,
  handle,
  featured = false,
  color,
  tags,
  priority = false,
}) => {
  const { t } = useTranslation('productCard')
  const href = `${ROUTE_BASE_PRODUCTS}/${handle}`
  const {
    colorsConfig,
    productCardBackgroundConfig,
    tagsConfig,
    getProductColors,
  } = useProductsSettings()

  const productColors = getProductColors(title)
  const currentProductColor =
    colorsConfig && color ? colorsConfig[color] : undefined

  return (
    <Root>
      <ImageWrap
        href={href}
        featured={featured}
        css={
          productCardBackgroundConfig?.backgroundColor
            ? { background: productCardBackgroundConfig?.backgroundColor.hex }
            : {}
        }
      >
        {tags && tagsConfig && (
          <TagsContainer>
            {tags.map((tag, i) => {
              if (tagsConfig[tag]) return <Tag key={i}>{tagsConfig[tag]}</Tag>
              return null
            })}
          </TagsContainer>
        )}
        {image && (
          <Media
            alt={title}
            mediaPayload={image}
            layout={'responsive'}
            hardcropRatio={featured ? 'landscape2' : 'portrait2'}
            priority={priority}
            sizes={
              featured
                ? '(min-width: 1000px) 50vw, 100vw'
                : '(min-width: 1000px) 25vw,(min-width: 430px) 50vw, 100vw'
            }
          />
        )}
      </ImageWrap>
      <BottomWrap as={Link} href={href} tabIndex={-1}>
        <TopColorLabel>
          {currentProductColor && currentProductColor.label}
        </TopColorLabel>
        <Title>{title}</Title>
        <PriceWrap>
          <ProductPrice
            priceRange={priceRange}
            compareAtPriceRange={compareAtPriceRange}
            theme={'small'}
          />
        </PriceWrap>
      </BottomWrap>
      <BottomWrap>
        <ProductColors>
          {currentProductColor && productColors && (
            <>
              <span>{t('availableIn')}</span>
              <ProductSwatches
                productColors={productColors}
                currentProductColorId={color}
                small={true}
              />
              {tags && tags?.indexOf('string of love') > 0 && (
                <>
                  <span>{t('stringColors')}</span>
                  <StringColorSwatches href={href} />
                </>
              )}
            </>
          )}
        </ProductColors>
      </BottomWrap>
    </Root>
  )
}
