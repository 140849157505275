import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createLinkProjection } from '../projections/createLinkProjection'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { COLOR_PROJECTION } from '../projections/colorProjection'

export const createSectionHeroProjection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
      _key,
      _type,
      type,
      color${COLOR_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('subtitle', localeId)},
      ${coalesceLocaleField('description', localeId)}[]${RICH_TEXT_PROJECTION},
      buttons[] {
        link${LINK_PROJECTION},
        ${coalesceLocaleField('label', localeId)},
      },
      url${LINK_PROJECTION},
      images[] {
        ...image${MEDIA_PROJECTION}
      },
    }
  `
}
