import { getLocaleRegionIdFromPath } from '@magal/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from '..'
import { captureException } from '@sentry/nextjs'
import { ROUTE_BASE_COLLECTIONS } from '@magal/configs'
import { formatShopifyItem } from './helpers/formatShopifyItem'
import { SitemapPageInfo } from '@magal/models'

export type ShopifySiteMapCollectionsNode = {
  node: {
    handle: string
    updatedAt: string
  }
}

export type SiteMapCollectionsRes = {
  collections: {
    pageInfo: {
      hasNextPage: boolean
      endCursor: string
    }
    edges: ShopifySiteMapCollectionsNode[]
  }
  errors?: { message: string }[]
}

const QUERY = (endCursor?: string) => gql`
  fragment ProductFragment on Collection {
    handle
    updatedAt
  }

  query ($regionId: CountryCode!) @inContext(country: $regionId) {
    collections(first: 250, ${endCursor ? `after: "${endCursor}"` : ''}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
            node {
                ...ProductFragment
            }
        }
    }
  }
`

const getSitemapCollections = async (
  locale: string,
  endCursor?: string,
  prevProducts?: ShopifySiteMapCollectionsNode[],
): Promise<ShopifySiteMapCollectionsNode[] | null> => {
  try {
    const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
    const shopifyClient = createShopifyClient(localeId)

    const dataRes = await shopifyClient.request<SiteMapCollectionsRes>(
      QUERY(endCursor),
      {
        regionId,
        endCursor,
      },
    )

    const hasNextPage = dataRes?.collections?.pageInfo?.hasNextPage
    const newEndCursor = dataRes?.collections?.pageInfo?.endCursor
    const currentCollectionsNodes = dataRes?.collections?.edges || []

    const mergedCollectionsNodes = [
      ...(prevProducts ? prevProducts : []),
      ...currentCollectionsNodes,
    ]
    const errors = dataRes.errors || []

    if (errors.length) {
      throw new Error(errors[0].message)
    }

    if (hasNextPage) {
      return await getSitemapCollections(
        locale,
        newEndCursor,
        mergedCollectionsNodes,
      )
    }

    return mergedCollectionsNodes
  } catch (error) {
    captureException(error)
    return null
  }
}

export const getShopifySitemapCollections = async (
  locale: string,
): Promise<SitemapPageInfo[]> => {
  try {
    const collectionRes = await getSitemapCollections(locale)
    const collectionResMapped = collectionRes?.map((i) =>
      formatShopifyItem(i, ROUTE_BASE_COLLECTIONS),
    )
    return collectionResMapped || []
  } catch (e) {
    captureException(e)
    return []
  }
}
