import { FC } from 'react'
import { useProductsSettings } from '@magal/product'
import { ProductSwatch, ProductSwatchesStyled } from './ProductSwatches'

const NUMBER_OF_SWATCHES_SHOWN = 5

export const StringColorSwatches: FC<{ href: string }> = ({ href }) => {
  const { stringColorsConfig } = useProductsSettings()

  if (!stringColorsConfig || !stringColorsConfig.stringColors) return null

  const visibleSwatches = stringColorsConfig.stringColors?.slice(
    0,
    NUMBER_OF_SWATCHES_SHOWN,
  )
  const invisibleSwatches =
    stringColorsConfig.stringColors?.length - NUMBER_OF_SWATCHES_SHOWN

  return (
    <ProductSwatchesStyled>
      {visibleSwatches?.map((stringColor, index) => {
        return (
          <ProductSwatch
            key={`stringColorSwatch-${index}`}
            color={stringColor.color}
            href={href}
            small={true}
          />
        )
      })}
      {invisibleSwatches > 0 && <span>{`+ ${invisibleSwatches}`}</span>}
    </ProductSwatchesStyled>
  )
}
