import React, { FC } from 'react'
import { styled, useMediaQuery } from '@magal/styles'
import { SiteConfiguration } from '@magal/models'
import { BlockContent, Button, Modal } from '@magal/components'
import { useModalNewsletter } from './useModalNewsletter'
import { NewsletterForm } from './NewsletterForm'
import { Close } from '@magal/icons'

const Root = styled('div', {
  position: 'relative',
  maxWidth: '$maxWidthM',
  backgroundColor: '$green',
  display: 'grid',
  gap: '$32',
  padding: '$40 $12',
  textAlign: 'center',
  color: '$white',
  '@lg': {
    gap: '$40',
    padding: '$40',
  },
})

const CloseButton = styled(Button, {
  position: 'absolute',
  right: '$12',
  top: '$12',
  color: '$white',
})

const HeadingContainer = styled('div', {
  display: 'grid',
  gap: '$16',
})

const Title = styled('h2', {
  projectFont: 'heading02',
})

const Description = styled('div', {
  projectFont: 'body01',
})

export const ModalNewsletter: FC<{
  newsletter: SiteConfiguration['newsletter']['subscribe']
}> = ({ newsletter }) => {
  const matchesMd = useMediaQuery('md')
  const { isOpen, handleClose } = useModalNewsletter()

  if (!newsletter) return null
  const { title, description } = newsletter

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      preventScroll={true}
      position={matchesMd ? 'center' : 'bottom'}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      variant={'rounded'}
    >
      <Root>
        <CloseButton onClick={handleClose}>
          <Close />
        </CloseButton>
        <HeadingContainer>
          {title && <Title>{title}</Title>}
          <Description>
            {description && <BlockContent body={description} />}
          </Description>
        </HeadingContainer>
        <NewsletterForm buttonAppearance={'outlineWhite'} />
      </Root>
    </Modal>
  )
}
