import { useCustomerContext } from '@magal/account'
import { useRouter } from 'next/router'
import { useCartContext } from './useCart'
import { getMultipass } from '@magal/services/shopify-service'
import { useState } from 'react'
import { captureException } from '@sentry/nextjs'
import { addBreadcrumb } from '@sentry/nextjs'
import { Cart } from '@magal/models'
import { GTMBeginCheckout } from '@magal/services/gtm-service'

const checkoutAnalytics = (cart: Cart, type?: 'multipass') => {
  if (cart?.lines) {
    GTMBeginCheckout(
      cart.cost.subtotalAmount.amount,
      cart.cost.subtotalAmount.currencyCode,
      cart.lines,
    )
  }

  addBreadcrumb({
    category: 'cart',
    message: (() => {
      switch (type) {
        case 'multipass':
          return 'Checkout with Multipass'
        default:
          return 'Checkout (default)'
      }
    })(),
    level: 'info',
    data: {
      cartId: cart?.id,
      variants: cart?.lines?.map((i) => ({
        quantity: i.quantity,
        variantId: i.merchandise.id,
      })),
    },
  })
}

type ReturnType = {
  handleCheckout: () => void
  isCheckoutLoading: boolean
}

export const useCheckout = (): ReturnType => {
  const { locale } = useRouter()
  const { isLoggedIn, isVerified } = useCustomerContext()
  const { cart } = useCartContext()
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)
  const checkoutUrl = cart?.checkoutUrl
  const isLoggedInUser = isLoggedIn && isVerified

  const handleCheckout = async () => {
    try {
      setIsCheckoutLoading(true)

      if (isLoggedInUser && locale && checkoutUrl) {
        const { url } = await getMultipass({ locale, returnTo: checkoutUrl })
        checkoutAnalytics(cart, 'multipass')

        window.location.href = url
        return
      }

      if (!isLoggedInUser && checkoutUrl) {
        checkoutAnalytics(cart)

        window.location.href = checkoutUrl
        return
      }
      setIsCheckoutLoading(false)

      throw new Error('[handleCheckout] missing cart checkoutUrl')
    } catch (e) {
      captureException(e)
    }
  }

  return {
    handleCheckout,
    isCheckoutLoading,
  }
}
