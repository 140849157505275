declare global {
  interface Window {
    _klOnsite: {
      push: (a: string[]) => void
    }
  }
}

export const klaviyoPopUp = (id: string): void => {
  if (!window._klOnsite) return
  window._klOnsite.push(['openForm', id])
}
