import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createBlockContentProjection } from '../projections/createBlockContentProjection'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionJournalLandingPageProjection = (
  localeId: LocaleId,
) => {
  const BLOCK_CONTENT_PROJECTION = createBlockContentProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
        "_type": "sectionJournalLandingPage",
        "_key": "sectionJournalLandingPage",
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'description',
          localeId,
        )}[]${BLOCK_CONTENT_PROJECTION},
        "journalPosts":
        *[ !(_id in path('drafts.**')) && _type == "journalPost" ] |
        order(publishDate desc) {
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField('subtitle', localeId)},
          "image": mediaWithCaption.image${MEDIA_PROJECTION},
          "href": path.current
        }
      }
  `
}
