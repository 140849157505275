import { FC, useState, useEffect } from 'react'
import { ShopifyPriceRange } from '@magal/models'
import { PriceRange } from './PriceRange'
import { PricePrimitive } from './PricePrimitve'
import { styled } from '@magal/styles'
import { DiscountPercentage, formatDiscountRange } from '@magal/utils'

const Root = styled('div', {
  color: '$green_09',
  s: {
    color: '$red400',
    opacity: 0.8,
  },
  variants: {
    theme: {
      small: {
        '.prefix': {
          projectFont: 'caps06',
        },
        '.primitive': { projectFont: 'caps05', alignSelf: 'start' },
      },
      big: {
        '.prefix': {
          projectFont: 'heading04',
        },
        '.primitive': {
          projectFont: 'heading04Medium',
        },
      },
    },
  },
})

export const ProductPrice: FC<{
  priceRange?: ShopifyPriceRange
  compareAtPriceRange?: ShopifyPriceRange
  theme?: 'small' | 'big'
}> = ({ priceRange, compareAtPriceRange, theme }) => {
  const [discount, setDiscount] = useState<DiscountPercentage>()

  useEffect(() => {
    setTimeout(() => {
      if (window.discountPercentage.enableDiscount) {
        setDiscount(window.discountPercentage)
      }
    }, 100)
  }, [])

  if (!priceRange) return null

  const shouldShowCompareAtPriceRange =
    (compareAtPriceRange &&
      parseInt(priceRange.minVariantPrice.amount) <
        parseInt(compareAtPriceRange.minVariantPrice.amount) &&
      parseFloat(compareAtPriceRange.minVariantPrice.amount) !== 0) ||
    discount?.enableDiscount

  return (
    <Root theme={theme}>
      <PriceRange
        range={formatDiscountRange(priceRange, discount)}
        shouldComparedPrice={shouldShowCompareAtPriceRange}
      />
      {shouldShowCompareAtPriceRange && (
        <>
          {' '}
          <s>
            <PricePrimitive
              price={
                discount?.enableDiscount
                  ? priceRange.minVariantPrice
                  : compareAtPriceRange?.minVariantPrice
              }
            />
          </s>
        </>
      )}
    </Root>
  )
}
