import React, { FC, useState } from 'react'
import { SiteConfiguration } from '@magal/models'
import { CSS, styled } from '@magal/styles'
import { Button, Link, LogoLink, TrustBox } from '@magal/components'
import { ChevronLeft, ChevronRight, Close } from '@magal/icons'
import { Tab } from './Tab'

type DrawerProps = {
  header: SiteConfiguration['header']
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void
}

const Root = styled('div', {
  display: 'grid',
  background: 'white',
})
const Head = styled('div', {
  position: 'sticky',
  top: 0,
  background: 'white',
  display: 'grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  height: '$headerHeightMobile',
  justifyContent: 'space-between',
  borderBottom: '1px solid transparent',
})
const CloseButton = styled(Button, {
  display: 'grid',
  alignItems: 'center',
  padding: '0 $16',
  color: '$green_09',
})

const Body = styled('div', {
  padding: '$16',
  position: 'relative',
  zIndex: 20,
  background: 'white',
})

const TrustPilot = styled('div', {
  position: 'absolute',
  bottom: 120,
  width: '100%',
  zIndex: 10,
})

const Nav = styled('div', {
  display: 'grid',
  gridGap: '$8',
  variants: {
    visible: {
      false: {
        display: 'none',
      },
    },
  },
})

const navClickableStyle: CSS = {
  background: '$gray400',
  color: '$green_09',
  projectFont: 'body01',
  height: '$buttonHeightL',
  borderRadius: '$r1',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  textAlign: 'left',
  padding: '0 $12 0 $24',
}
const NavButton = styled(Button, {
  ...navClickableStyle,
  variants: {
    inverted: {
      true: {
        padding: '0 $24 0 $12',
        justifyContent: 'start',
      },
    },
  },
})
const NavLink = styled(Link, navClickableStyle)
const NestedTabs = styled('div', {})
const TabWrap = styled('div', {
  display: 'grid',
  variants: {
    visible: {
      false: {
        display: 'none',
      },
    },
  },
})

export const DrawerMobile: FC<DrawerProps> = ({ header, onClose }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null)

  const openTab = (i: number) => setActiveTabIndex(i)
  const closeTab = () => setActiveTabIndex(null)

  return (
    <Root>
      <Head>
        <LogoLink type={'header'} />
        <CloseButton onClick={onClose}>
          <Close />
        </CloseButton>
      </Head>
      <Body>
        <Nav visible={activeTabIndex === null}>
          {header.tabs?.map((tab, i) => {
            if (tab.type === 'link-only') {
              return (
                <NavLink {...tab.link} key={i}>
                  {tab.title} <ChevronRight key={'icon' + i} />
                </NavLink>
              )
            } else {
              return (
                <NavButton onClick={() => openTab(i)} key={i}>
                  {tab.title} <ChevronRight />
                </NavButton>
              )
            }
          })}
        </Nav>
        <NestedTabs>
          {header.tabs?.map((tab, i) => (
            <TabWrap key={i} visible={i === activeTabIndex}>
              <NavButton onClick={closeTab} inverted={true}>
                <ChevronLeft />
                {tab.title}
              </NavButton>
              <Tab tab={tab} />
            </TabWrap>
          ))}
        </NestedTabs>
      </Body>
      <TrustPilot>
        <TrustBox location="mobileDrawer" />
      </TrustPilot>
    </Root>
  )
}
