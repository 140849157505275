import { LocaleId, RegionId } from '@magal/configs'

/**
 *  converts locale string ( 'regionId-languageId' or 'regionId') to { regionId, localeId }
 */
export const decodeLocaleString = (
  localeRegionString?: string,
): { regionId?: RegionId; localeId?: LocaleId } => {
  const localeData = localeRegionString?.match(/^([a-z]{2})-?([a-z]{2})?$/)

  if (!localeData) {
    return { regionId: undefined, localeId: undefined }
  }

  const regionId = localeData[1]?.toUpperCase() as RegionId | undefined
  const localeId = localeData[2] as LocaleId | undefined

  return { regionId, localeId }
}
