import { FC, useRef, useState } from 'react'
import { styled } from '@magal/styles'
import { SiteConfiguration } from '@magal/models'
import { Button, Link } from '@magal/components'
import { Close } from '@magal/icons'
import { useRouter } from 'next/router'
import { SearchInput } from './SearchInput'
import { ROUTE_SEARCH } from '@magal/configs'

const RootWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  height: '100vh',
  padding:
    '$headerHeightMobile $containerMarginMobile $40 $containerMarginMobile',
  backgroundColor: '$white',
  projectFont: 'body01',
  color: '$green_09',
  '@md': {
    height: 'auto',
    padding: '$headerHeightDesktop $56',
    borderBottom: '1px solid $green_09',
  },
})

const Root = styled('div', {
  justifySelf: 'center',
  position: 'relative',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gap: '$40',
  height: '100%',
  width: '100%',
  '@md': {
    maxWidth: '$maxWidthM',
    gridTemplateRows: '1fr auto',
  },
})

const SearchWrap = styled('div', {
  width: '100%',
  display: 'grid',
  position: 'relative',
  '&:focus-within': {},
})

const CloseButton = styled(Button, {
  position: 'absolute',
  display: 'grid',
  alignItems: 'center',
  justifySelf: 'end',
  height: '$headerHeightMobile',
  padding: '0 $16',
  color: '$green_09',
})

const ContentWrap = styled('div', {
  alignContent: 'end',
  display: 'grid',
  gap: '$20',
  gridTemplateRows: 'min-content',
  height: '100%',
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
})

const Title = styled('h2', {
  projectFont: 'caps02',
})

const SuggestionsList = styled('div', {})
const Suggestion = styled('p', {})

const StyledLink = styled(Link, {
  projectFont: 'body01',
  color: '$green_09',
  padding: 0,
  '&:active': {
    opacity: '0.8',
  },
  '&:hover, &:focus-within, &:focus': {
    textDecorationColor: '$green_09',
  },
})

export const SearchModalContent: FC<{
  searchConfig: SiteConfiguration['search']
  closeSearch: () => void
}> = ({ searchConfig, closeSearch }) => {
  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const searchInputRef = useRef<HTMLInputElement>(null)
  const { locale } = useRouter()
  const router = useRouter()

  const handleSearchSubmit = async () => {
    router.push(
      {
        pathname: ROUTE_SEARCH,
        query: {
          q: searchInputValue,
        },
      },
      undefined,
      {
        shallow: false,
      },
    )
  }

  const handleSearchChange = (value?: string) => {
    setSearchInputValue(value || '')
  }

  const handleSearchResetRequest = () => {
    setSearchInputValue('')
    searchInputRef.current?.focus()
  }

  const linkByLocale = (links: any) => {
    if (locale?.includes('fr') && links.link_fr) return links.link_fr
    if (locale?.includes('de') && links.link_de) return links.link_de

    return links.link
  }

  return (
    <RootWrap>
      <CloseButton onClick={closeSearch}>
        <Close />
      </CloseButton>
      <Root>
        <SearchWrap>
          <SearchInput
            value={searchInputValue}
            onResetRequest={handleSearchResetRequest}
            onChange={handleSearchChange}
            onSubmit={handleSearchSubmit}
            ref={searchInputRef}
            key={`searchInputModal`}
          />
        </SearchWrap>
        <ContentWrap>
          {searchConfig.title && <Title>{searchConfig.title}</Title>}
          {searchConfig.links && (
            <SuggestionsList>
              {searchConfig.links.map((link, index) => {
                return (
                  <Suggestion key={`searchSuggestion-${index}`}>
                    {link.link?.href ? (
                      <StyledLink href={linkByLocale(link)?.href}>
                        {link.title}
                      </StyledLink>
                    ) : (
                      <StyledLink
                        href={{
                          pathname: ROUTE_SEARCH,
                          query: {
                            q: link.title,
                          },
                        }}
                      >
                        {link.title}
                      </StyledLink>
                    )}
                  </Suggestion>
                )
              })}
            </SuggestionsList>
          )}
        </ContentWrap>
      </Root>
    </RootWrap>
  )
}
