/**
 * Convention for color naming is tailwind-like with a _ suffix describing opacity
 */
export const colors = {
  gray300: '#F9F6F2', //cold grey
  gray400: '#F3EDE7', //warm grey
  gray500: '#D9D2CA', //dark grey
  gray550: '#8e9d91',
  gray600: '#748477',

  beige: '#FFFCF9',
  white: '#FFFFFF',
  black: '#000',

  green: '#5A7162',
  green_02: '#dee2e0', //light green
  green_09: '#565C54', //dark green

  red300: '#d92b3d', //red
  red400: '#CD1627', //red
  red500: '#AE1321', //dark red

  darkGreen: '#424A41', // DARK GREEN
}

export type Color = keyof typeof colors
