import { styled } from '@magal/styles'
import { FC } from 'react'
import { StyledClickable } from '../StyledClickable/StyledClickable'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { MediaFieldType } from '@magal/models'
import { useTranslation } from 'react-i18next'

const Caption = styled('div', {
  padding: '$24',
  '@md': {
    padding: '$32',
  },
})

const Title = styled('h3', {
  projectFont: 'caps03',
})
const Subtitle = styled('p', {
  projectFont: 'body01',
  marginTop: '$12',
})

const Card = styled(Link, {
  width: '100%',
  //scrollSnapAlign: 'start',
  scrollSnapAlign: 'center',
  display: 'grid',
  '&:focus-visible': {
    outlineOffset: '-1px!important',
  },
})

const StyledLink = styled(StyledClickable, {
  display: 'inline-flex',
  marginTop: '$12',
})

type MediaCardProps = {
  title?: string
  subtitle?: string
  href?: string
  image?: MediaFieldType
  cardSize?: 'default' | 'large'
  imageSizes?: string
  priority?: boolean
}

export const MediaCard: FC<MediaCardProps> = ({
  title,
  subtitle,
  href,
  image,
  imageSizes,
  priority = false,
}) => {
  const { t } = useTranslation('sectionCollectionSlider')
  return (
    <Card href={href}>
      {image && (
        <Media
          {...image}
          hardcropRatio={'portrait2'}
          sizes={imageSizes}
          priority={priority}
        />
      )}
      <Caption>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <StyledLink as={'span'} appearance={'textLinkLite'}>
          {t('discover')}
        </StyledLink>
      </Caption>
    </Card>
  )
}
