import { CartLineType } from '@magal/models'
import {
  getBundleId,
  getCartLinesByBundleId,
  getTotalBundleCost,
  isBundleAdditional,
  isBundleMain,
} from '@magal/services/shopify-service'
import { useCartContext } from './useCart'

export const useComputedCart = () => {
  const { updateCartLines, removeCartLines, cart } = useCartContext()

  const cartLines = cart?.lines?.filter((line) => !!line) // strip off nulls

  const computedCartLines: CartLineType[] | undefined = (() => {
    if (!cartLines) return undefined
    return cartLines.reduce<CartLineType[]>((acc, line) => {
      if (isBundleAdditional(line)) return acc // we hide additional bundle items in Cart

      const bundleId = getBundleId(line)
      if (isBundleMain(line) && bundleId) {
        // we sum up totalAmount of all bundle elements
        return [
          ...acc,
          {
            ...line,
            cost: {
              totalAmount: getTotalBundleCost(cartLines, bundleId),
            },
          },
        ]
      }
      return [...acc, line]
    }, [])
  })()

  const handleUpdate = (mainLine: CartLineType, quantity: number) => {
    if (isBundleMain(mainLine)) {
      const bundleId = getBundleId(mainLine)
      if (!(cartLines && bundleId)) return
      const subjectLines = getCartLinesByBundleId(cartLines, bundleId)
      updateCartLines(
        subjectLines.map(({ id, attributes }) => ({
          id,
          quantity,
          attributes,
        })),
      )
    } else {
      updateCartLines([
        { id: mainLine.id, quantity, attributes: mainLine.attributes },
      ])
    }

    // TODO ANALYTICS
  }
  const handleRemove = (mainLine: CartLineType) => {
    if (isBundleMain(mainLine)) {
      const bundleId = getBundleId(mainLine)
      if (!(cartLines && bundleId)) return
      const subjectLines = getCartLinesByBundleId(cartLines, bundleId)
      removeCartLines(subjectLines.map((l) => l.id))
    } else {
      removeCartLines([mainLine.id])
    }

    // TODO ANALYTICS
  }

  return {
    computedCartLines,
    handleUpdate,
    handleRemove,
    removeCartLines,
  }
}
