import { ServiceResponse } from '@magal/models'
import { sleep } from './sleep'

type WithRetry = <T>(
  fn: () => Promise<ServiceResponse<T>>,
  retriesTotal: number,
  retriesLeft?: number,
  errors?: Array<any>,
) => Promise<ServiceResponse<T>>

export const fetchServiceWithRetry: WithRetry = async (
  fn,
  retriesTotal,
  retriesLeft,
  errors,
) => {
  const res = await fn()
  if (retriesLeft === undefined) retriesLeft = retriesTotal
  if (res.status === 'ERROR' && retriesLeft > 0) {
    await sleep((retriesTotal - retriesLeft) * 1000)
    const resErrors = res.errors ?? [undefined]
    errors = errors ? [...errors, ...resErrors] : resErrors
    return await fetchServiceWithRetry(
      fn,
      retriesTotal,
      retriesLeft - 1,
      errors,
    )
  }
  return res
}
