import { groq } from 'next-sanity'

export const createSectionCollectionsSliderProjection = () => {
  return groq`{
      _key,
      _type,
      "collectionsIds": collections[]-> store.gid,
      "shopifyIds": [
        ...collections[] -> {
          "id": store.gid,
          "type": "collectionSliderExcerpt",
        }
      ]
    }
  `
}
