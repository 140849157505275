import { FC } from 'react'
import { styled } from '@magal/styles'
import { getI18nPrice } from '@magal/utils'
import { OrderResponseType } from '../../../customerService'
import { formatDate } from '../../../helpers/formatDate'
import { useRouter } from 'next/router'
import { usePageContext } from '../pageContext'

const Text = styled('span', {
  projectFont: 'caps07',
  '@md': {
    projectFont: 'body01',
  },
})

const AddressContainer = styled('li', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  justifyItems: 'stretch',
  padding: '$16 0',
  borderTop: '1px solid $green_09',
  borderBottom: '1px solid $green_09',

  '&:first-child': {
    borderTop: 'none',
  },
  '&:last-child': {
    marginBottom: '$32',
  },
  span: {
    '&:last-child': {
      textAlign: 'right',
    },
  },
  '&:before': {
    content: '',
    position: 'absolute',
    backgroundColor: 'transparent',
    height: '1px',
    // width: '100%',
    bottom: 0,
    transition: '200ms',
  },
  '&:hover': {
    '&:before': {
      backgroundColor: '$green_09',
    },
    cursor: 'pointer',
  },
  '&:active': {
    span: {
      opacity: '0.8',
    },
  },
  //TODO: behaviour when clicked, hovered, etc.
})

export const OrderItem: FC<{
  order: OrderResponseType
  title?: string
  className?: string
}> = ({ order, className }) => {
  const { id, name, processedAt, fulfillmentStatus, currentTotalPrice } = order
  const { locale } = useRouter()
  const date = processedAt ? formatDate(processedAt) : null
  const { setActiveView } = usePageContext()

  return (
    <AddressContainer
      className={className}
      onClick={() => setActiveView('orders-view', id)}
    >
      {name ? <Text>{name}</Text> : null}
      {date && <Text>{date}</Text>}
      {fulfillmentStatus ? <Text>{fulfillmentStatus}</Text> : null}
      {currentTotalPrice ? (
        <Text>{getI18nPrice(currentTotalPrice, locale)}</Text>
      ) : null}
    </AddressContainer>
  )
}
