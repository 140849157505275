import { ErrorResponseType } from '../customerService'
import { Path } from 'react-hook-form'

export function formatResponseErrors<T>(errors: ErrorResponseType[]) {
  let generalError = ''
  const fieldErrors: { fieldName: Path<T>; message: string }[] = []

  errors.forEach((e) => {
    const fieldName = e?.field?.[1] as Path<T>
    if (fieldName) {
      fieldErrors.push({ fieldName, message: e?.message })
    }
    if (!fieldName && e.message) {
      generalError = e.message
    }
  })

  return {
    generalError,
    fieldErrors,
  }
}
