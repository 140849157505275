import { FC } from 'react'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@magal/utils'
import { ShopifyPrice } from '@magal/models'

export const PricePrimitive: FC<{
  price?: ShopifyPrice
  noZeros?: boolean
}> = ({ price, noZeros = false }) => {
  const { locale } = useRouter()

  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  let internationalizedPrice =
    price &&
    new Intl.NumberFormat(`${localeId}-${regionId}`, {
      style: 'currency',
      currency: price.currencyCode,
    }).format(Number(price.amount))

  if (noZeros) {
    internationalizedPrice = `${internationalizedPrice}`.replace(/\.00$/, '') // remove trailing .00
  }

  return <span className={'primitive'}>{price && internationalizedPrice}</span>
}
