import { ShopifyVideoType } from '@magal/models'
import { HTMLAttributes } from 'react'
import { shopifyImageLoader } from '../helpers/shopifyImageLoader'
import { styled } from '@magal/styles'
import { Image } from './Image'
import { MediaProps } from '../models/MediaProps'

type ShopifyVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  /**
   * An object with fields that correspond to the Storefront API's [ExternalVideo object](https://shopify.dev/api/storefront/reference/products/externalvideo).
   */
  mediaPayload: ShopifyVideoType
  /** An object of image size options for the video's `previewImage`. Uses `shopifyImageLoader` to generate the `poster` URL. */
  previewImageOptions?: Parameters<typeof shopifyImageLoader>[0]
  /** Props that will be passed to the `video` element's `source` children elements. */
  sourceProps?: HTMLAttributes<HTMLSourceElement>
  priority?: boolean
}

const Container = styled('div', {
  position: 'relative',
  background: 'black',
  img: {
    opacity: 0.75,
  },
  variants: {
    layout: {
      fill: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
      },
      responsive: {},
      raw: {},
    },
  },
})

const VideoElement = styled('video', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
})

/**
 * The `Video` component renders a `video` for the Storefront API's [Video object](https://shopify.dev/api/storefront/reference/products/video).
 */
export function ShopifyVideo(
  props: JSX.IntrinsicElements['video'] & ShopifyVideoProps,
) {
  const {
    mediaPayload,
    priority,
    previewImageOptions = {},
    id = mediaPayload.id,
    playsInline = true,
    controls = false,
    sourceProps = {},
    layout,
    loop = true,
  } = props

  const posterUrl = shopifyImageLoader({
    src: mediaPayload.previewImage?.url ?? '',
    // TODO: fix required width
    width: mediaPayload.previewImage?.width || 0,
    ...previewImageOptions,
  })

  if (!mediaPayload.sources) {
    throw new Error(`<Video/> requires a 'data.sources' array`)
  }

  const height = mediaPayload.previewImage?.height || 1
  const width = mediaPayload.previewImage?.width || 1
  const ratio = (height / width) * 100

  const containerStyles = (() => {
    const paddingBottom = `${ratio}%`
    return layout === 'responsive' ? { paddingBottom } : undefined
  })()

  return (
    <Container layout={layout} style={containerStyles}>
      <Image src={posterUrl} priority={priority} layout={'fill'} />
      <VideoElement
        autoPlay
        muted
        loop={loop}
        id={id}
        playsInline={playsInline}
        controls={controls}
        poster={posterUrl}
      >
        {mediaPayload.sources.map((source) => {
          if (!(source?.url && source?.mimeType)) {
            throw new Error(`<Video/> needs 'source.url' and 'source.mimeType'`)
          }
          return (
            <source
              {...sourceProps}
              key={source.url}
              src={source.url}
              type={source.mimeType}
            />
          )
        })}
      </VideoElement>
    </Container>
  )
}
