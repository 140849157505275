import { AccountCardWrapper } from './AccountCardWrapper'
import { AddressItem } from './AddressItem'
import { Button } from '@magal/components'
import { FC, useState } from 'react'
import { styled } from '@magal/styles'
import { EditAddress } from '../views/EditAddress'
import { useTranslation } from 'react-i18next'
import { AddressResponseType } from '../../../customerService'

const CardActions = styled('div', {
  display: 'flex',
  gap: '$12',
})

export const AddressItemCard: FC<{
  node: AddressResponseType
  defaultAddressId: string
  handleAddressDelete: (id: string | undefined) => Promise<void>
  isDeletingPossible: boolean
}> = ({ node, defaultAddressId, handleAddressDelete, isDeletingPossible }) => {
  const { t } = useTranslation('account')
  const { id } = node
  const isDefault = id === defaultAddressId
  const heading = isDefault
    ? t('accountPage.addressListView.defaultAddress')
    : undefined
  const [isEditing, setIsEditing] = useState(false)

  return isEditing ? (
    <EditAddress
      title={heading}
      setIsEditVisible={setIsEditing}
      address={node}
      isDefault={isDefault}
    />
  ) : (
    <AccountCardWrapper title={heading}>
      <AddressItem address={node} title={heading} />
      <CardActions>
        <Button appearance="outlineGreen" onClick={() => setIsEditing(true)}>
          {t('accountPage.addressListView.editButtonLabel')}
        </Button>
        {isDeletingPossible && (
          <Button
            appearance="outlineGreen"
            onClick={() => handleAddressDelete(id)}
          >
            {t('accountPage.addressListView.deleteButtonLabel')}
          </Button>
        )}
      </CardActions>
    </AccountCardWrapper>
  )
}
