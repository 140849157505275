import { FC } from 'react'
import { styled } from '@magal/styles'

import { useTranslation } from 'react-i18next'
import { ResetPasswordForm } from '../forms/ResetPasswordForm'
import { ROUTE_BASE_ACCOUNT } from '@magal/configs'
import { useRouter } from 'next/router'
import { FormWrapper } from '../components/FormWrapper'
import { Container, SectionWrap } from '@magal/components'

const Root = styled(Container, {
  padding: '$48 0 $64',
  maxWidth: '$maxWidthS',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
})

type Props = {
  resetUrl?: string
}

export const ResetPasswordPage: FC<Props> = ({ resetUrl }) => {
  const { t } = useTranslation('account')
  const router = useRouter()

  const handleSubmitSuccess = () => {
    router.push(ROUTE_BASE_ACCOUNT)
  }

  return (
    <SectionWrap type={'MARGINS_S'}>
      <Root>
        <FormWrapper
          title={t('resetPasswordPage.heading')}
          description={t('resetPasswordPage.text')}
        >
          <ResetPasswordForm
            resetUrl={resetUrl}
            onSubmitSuccess={handleSubmitSuccess}
          />
        </FormWrapper>
      </Root>
    </SectionWrap>
  )
}
