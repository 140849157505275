import React, { FC, useEffect, useState } from 'react'
import { styled } from '@magal/styles'
import { Button } from '@magal/components'
import router from 'next/router'
import { SearchIcon } from '@magal/icons'
import { SiteConfiguration } from '@magal/models'
import { SearchModal } from './SearchModal'

const Wrapper = styled(Button, {
  position: 'relative',
  display: 'grid',
  alignItems: 'center',
  background: 'transparent',
  padding: '0 $12',
  color: '$green_09',
  '@lg': {
    padding: '0 $16',
    marginRight: '-$16',
    height: '80%',
  },
})

export const SearchButton: FC<{
  searchConfig: SiteConfiguration['search']
}> = ({ searchConfig }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <SearchModal
        searchConfig={searchConfig}
        isOpen={isOpen}
        closeSearch={() => {
          setIsOpen(false)
        }}
      />
      <Wrapper
        onClick={() => (router.pathname !== '/search' ? setIsOpen(true) : null)}
      >
        <SearchIcon />
      </Wrapper>
    </>
  )
}
