import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionTrustpilotBannerProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        link${LINK_PROJECTION},
        reviews[] {
          ${coalesceLocaleField('author', localeId)},
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField('description', localeId)},
        },
      }
  `
}
