import { ShopifyPrice } from '@magal/models'
import { getLocaleRegionIdFromPath } from './getLocaleRegionIdFromPath'

export const getI18nPrice = (price: ShopifyPrice, locale?: string): string => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  return new Intl.NumberFormat(`${localeId}-${regionId}`, {
    style: 'currency',
    currency: price.currencyCode,
  }).format(Number(price.amount))
}
