import { ShopifySiteMapProductsNode } from '../getShopifySitemapProducts'
import { ShopifySiteMapCollectionsNode } from '../getShopifySitemapCollections'
import { SitemapPageInfo } from '@magal/models'

export const formatShopifyItem = (
  item: ShopifySiteMapProductsNode | ShopifySiteMapCollectionsNode,
  routeBase: string,
): SitemapPageInfo => {
  const { updatedAt, handle } = item.node

  return {
    _updatedAt: updatedAt,
    path: `${routeBase}/${handle}`,
    alternateRefs: [],
  }
}
