import { forwardRef, ReactNode } from 'react'
import { styled } from '@magal/styles'

const Wrap = styled('div', {
  $$topMarginValue: '$sizes$32',
  $$bottomMarginValue: '$sizes$32',
  margin: '$$topMarginValue 0 $$bottomMarginValue 0',
  '@lg': {
    $$topMarginValue: '$sizes$96',
    $$bottomMarginValue: '$sizes$96',
  },
  variants: {
    type: {
      MARGINS_L: {
        $$topMarginValue: '$sizes$144',
        $$bottomMarginValue: '$sizes$144',
      },
      MARGINS_M: {},
      MARGINS_S: {
        $$topMarginValue: '$sizes$32',
        $$bottomMarginValue: '$sizes$32',
      },
      MARGINS_0: {
        $$topMarginValue: '0',
        $$bottomMarginValue: '0',
      },
      BORDERS: {
        borderTop: '1px solid $green_09',
        borderBottom: '1px solid $green_09',
        padding: '$32 0 $32 0',
        [`& + &`]: {
          borderTop: 'none',
          marginTop: '-$$topMarginValue',
        },
        '&:last-of-type': {
          marginBottom: 0,
          borderBottom: 0,
        },
      },
    },
  },
})

export const SectionWrap = forwardRef<
  HTMLDivElement,
  {
    type?: 'MARGINS_L' | 'MARGINS_M' | 'MARGINS_S' | 'MARGINS_0' | 'BORDERS'
    children: ReactNode
    id?: string
  }
>(({ children, type = 'MARGINS_M', id }, ref) => {
  return (
    <Wrap id={id} ref={ref} type={type}>
      {children}
    </Wrap>
  )
})
