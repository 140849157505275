import { FC } from 'react'
import { styled } from '@magal/styles'
import { AddressResponseType } from '../../../customerService'
import { formatUserName } from '../../../helpers/formatUserName'

const Text = styled('span', {
  projectFont: 'body01',
})

const AddressContainer = styled('div', {
  display: 'grid',
  gap: '$32',
})

const AddressBlock = styled('div', {
  display: 'grid',
  gridAutoFlow: 'rows',
  gap: '$4',
})

export const formatCityZip = (city?: string, zip?: string): string | null =>
  [city, zip].filter(Boolean).join(', ')

export const AddressItem: FC<{
  address: AddressResponseType
  title?: string
  className?: string
}> = ({ address, className }) => {
  const { firstName, lastName, address1, address2, phone, zip, city } = address

  const userName = formatUserName(firstName, lastName)
  const cityAndZip = formatCityZip(city, zip)

  return (
    <AddressContainer className={className}>
      <AddressBlock>
        {userName ? <Text>{userName}</Text> : null}
        {phone ? <Text>{phone}</Text> : null}
      </AddressBlock>
      <AddressBlock>
        {address1 ? <Text>{address1}</Text> : null}
        {address2 ? <Text>{address2}</Text> : null}
        {cityAndZip ? <Text>{cityAndZip}</Text> : null}
      </AddressBlock>
    </AddressContainer>
  )
}
