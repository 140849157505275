import { gql } from 'graphql-request'

export const PRODUCT_MEDIA_FRAGMENT = gql`
  fragment BaseImageFragment on Image {
    url
    width
    height
    altText
    id
  }

  fragment MediaImageFragment on MediaImage {
    alt
    id
    image {
      ...BaseImageFragment
    }
    mediaContentType
  }

  fragment ExternalVideoFragment on ExternalVideo {
    id
    mediaContentType
    embedUrl
    alt
    originUrl
    host
    previewImage {
      ...BaseImageFragment
    }
  }

  fragment VideoFragment on Video {
    mediaContentType
    alt
    id
    sources {
      format
      height
      mimeType
      url
      width
    }
    previewImage {
      ...BaseImageFragment
    }
  }

  fragment ProductMediaFragment on Media {
    ...ExternalVideoFragment
    ...MediaImageFragment
    ...VideoFragment
  }
`
