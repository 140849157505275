import { ShopifyImageMediaPayload, ShopifyImageRes } from '@magal/models'

export const mapImage = (
  input?: ShopifyImageRes,
): ShopifyImageMediaPayload | null => {
  if (!input?.url) return null
  return {
    type: 'shopify-image',
    image: {
      id: input.id,
      height: input.height,
      width: input.width,
      src: input.url,
      alt: input.altText || null,
    },
  }
}

export const mapImages = (input?: {
  nodes: ShopifyImageRes[]
}): ShopifyImageMediaPayload[] => {
  return (
    input?.nodes
      ?.map(mapImage)
      .filter((img): img is ShopifyImageMediaPayload => !!img) || []
  )
}
