import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Loader } from '@magal/components'
import { styled } from '@magal/styles'

const Container = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridGap: '$12',
})

const HelpText = styled('span', {
  placeSelf: 'left',
  position: 'relative',
  projectFont: 'body02',
  marginTop: '$4',
  variants: {
    appearance: {
      outlineWhite: {
        color: '$white',
      },
      outlineGreen: {
        color: '$green_09',
      },
      solidGreen: {
        color: '$white',
      },
    },
    invalid: {
      true: {
        color: '$red500',
      },
    },
    success: {
      true: {
        color: '$green_09',
      },
    },
  },
  compoundVariants: [
    {
      invalid: true,
      appearance: 'outlineWhite',
      css: {
        color: '$white',
      },
    },
    {
      success: true,
      appearance: 'outlineWhite',
      css: {
        color: '$white',
      },
    },
  ],
})

const StyledButton = styled(Button, {
  transition: 'all 100ms ease-in-out',
  '&:disabled': {
    opacity: '0.75',
  },
  variants: {
    invalid: {
      true: {
        border: '1px solid $colors$red500',
        color: '$red500',
        '&:hover, &:focus, &:focus-within': {
          textDecorationColor: '$red500',
        },
      },
    },
  },
})

type SubmitButtonProps = {
  text?: string
  helpText?: string
  invalid?: boolean
  success?: boolean
  loading?: boolean
  disabled?: boolean
  appearance?: 'solidGreen' | 'outlineGreen' | 'outlineWhite'
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  text,
  helpText,
  success,
  invalid,
  loading,
  disabled,
  appearance = 'outlineGreen',
}) => {
  const { t } = useTranslation('forms')

  const buttonText = text || t('defaultSubmitButtonLabel')
  return (
    <Container>
      <StyledButton
        type="submit"
        appearance={appearance}
        disabled={loading || disabled}
        invalid={Boolean(invalid && helpText)}
      >
        {loading ? <Loader size={'small'} /> : buttonText}
      </StyledButton>
      <HelpText invalid={invalid} success={success} appearance={appearance}>
        {helpText}
      </HelpText>
    </Container>
  )
}
