import React from 'react'

export const TrustpilotIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="#FFFCF9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26953 5.73193H15L10.3652 9.26807L10.4043 9.39502L7.55859 11.5L7.49463 11.4531L2.86035 15L4.63477 9.26807L0 5.72119L5.73047 5.73193L7.50537 0L9.26953 5.73193ZM10.7832 10.626L12.1294 15L7.55859 11.5L10.7832 10.626Z"
      />
    </svg>
  )
}
