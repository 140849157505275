import { FC } from 'react'
import { useCartContext } from '../useCart'
import { styled } from '@magal/styles'
import { useRouter } from 'next/router'
import { Link } from '@magal/components'
import { SiteConfiguration } from '@magal/models'

const EmptyCartLinksList = styled('ul', {
  display: 'grid',
  gridRowGap: '$16',
  width: '100%',
  justifyItems: 'center',
  alignContent: 'end',
  alignSelf: 'end',
  height: '100%',
  // to keep offset of buttons in container
  padding: '$24 3px',
})

const EmptyCartLinkWrap = styled('li', {
  display: 'grid',
  width: '100%',
})

export const EmptyCartLinks: FC<{
  emptyCartLinks: SiteConfiguration['cart']['emptyCartLinks']
}> = ({ emptyCartLinks }) => {
  const { locale } = useRouter()
  const { closeMiniCart } = useCartContext()

  if (!emptyCartLinks) return null

  const linkByLocale = (links: any) => {
    if (locale?.includes('fr') && links.link_fr) return links.link_fr
    if (locale?.includes('de') && links.link_de) return links.link_de

    return links.link
  }

  return (
    <EmptyCartLinksList>
      {emptyCartLinks.map((item) => {
        return (
          <EmptyCartLinkWrap key={item.label}>
            <Link
              appearance={'solidGreen'}
              {...linkByLocale(item)}
              onClick={closeMiniCart}
            >
              {item.label}
            </Link>
          </EmptyCartLinkWrap>
        )
      })}
    </EmptyCartLinksList>
  )
}
