import { FC } from 'react'
import { styled } from '@magal/styles'

const Tooltip = styled('div', {
  background: '#F6F4F0',
  color: '#000000',
  fontSize: '12px',
  fontFamily: 'FormaDJRMicro',
  letterSpacing: '1px',
  fontWeight: '500',
  position: 'absolute',
  minWidth: '$144',
  maxWidth: '90vw',
  zIndex: 10000,
  padding: '$12 $16',
  borderRadius: '$r1 0 $r1 $r1',
  textAlign: 'left',
  '&::after': {
    position: 'absolute',
    content: '',
    border: '7px solid #F6F4F0',
    borderTop: '7px solid transparent !important',
    borderRight: '7px solid transparent !important',
    borderLeft: '7px solid transparent !important',
    display: 'inline-block',
    width: '0',
    height: '0',
    top: '-14px',
    right: '0',
  },
  '@md': {
    maxWidth: '$378',
  },
  '& strong': {
    fontWeight: 'bold',
  },
  '& i': {
    fontStyle: 'italic',
  },
  '& span': {
    textDecoration: 'underline',
  },
})

export const ToolTip: FC<any> = ({ promo, parentRef }) => {
  if (!parentRef?.current || !promo) return null
  const parent = parentRef.current

  return (
    <Tooltip
      css={{
        right: `${parent.offsetLeft}px`,
        top: `${parent.offsetTop + 25}px`,
        backgroundColor: promo.bgColor?.hex || '#F9F6F2',
        color: promo.textColor?.hex || '#000000',
        '&::after': {
          borderColor: promo.bgColor?.hex || '#F9F6F2',
        },
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: promo.tooltip }}></div>
    </Tooltip>
  )
}
