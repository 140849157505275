import { FC } from 'react'
import { styled } from '@magal/styles'
import { useTranslation } from 'react-i18next'

const CountdownGrid = styled('div', {
  fontWeight: 500,
  textTransform: 'uppercase',
  textAlign: 'center',
  display: 'flex',
  '& div': {
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: 'FormaDJRMicro',
    fontSize: 13,
    '@lg': {
      fontSize: 18,
    },
  },
  '& span': {
    display: 'inline-block',
    margin: '0 6px',
    textAlign: 'center',
    lineHeight: 1,
    '@lg': {
      margin: '0 10px',
    },
  },
  '& small': {
    fontSize: 8,
    '@lg': {
      fontSize: 11,
    },
  },
})

const withZero = (arg: number) => (arg < 10 ? `0${arg}` : arg)

export const CountDownRenderer: FC<{
  days: number
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}> = ({ days, hours, minutes, seconds, completed }) => {
  const { t } = useTranslation('timers')

  if (completed) return <>{t('completed')}</>

  return (
    <CountdownGrid>
      <div>
        <span>
          {withZero(days)}
          <br />
          <small>{t('days')}</small>
        </span>
        :
        <span>
          {withZero(hours)}
          <br />
          <small>{t('hours')}</small>
        </span>
        :
        <span>
          {withZero(minutes)}
          <br />
          <small>{t('minutes')}</small>
        </span>
        :
        <span>
          {withZero(seconds)}
          <br />
          <small>{t('seconds')}</small>
        </span>
      </div>
    </CountdownGrid>
  )
}
