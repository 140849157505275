import { getLocaleRegionIdFromPath } from '@magal/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import {
  PageBuilderSection,
  SectionCollectionProps,
  ServiceResponse,
  PromoBanner,
} from '@magal/models'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createLinkProjection } from './projections/createLinkProjection'
import { createMediaProjection } from './projections/createMediaProjection'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'

export const getCollectionPageByHandle = async (
  handle: string,
  localeRegionString: string,
  preview = false,
): Promise<
  ServiceResponse<{
    promoTiles: SectionCollectionProps['promoTiles']
    promoBanner: PromoBanner
    pageBuilder: PageBuilderSection[]
  }>
> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)

  const query = groq`*[!(_id in path('drafts.**')) && _type == "collection" && store.slug.current == "${handle}"][0]{
    promoTiles{
      "index": index,
      icon${MEDIA_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      ${coalesceLocaleField('description2', localeId)},
      ${coalesceLocaleField('description3', localeId)},
      "href": link${LINK_PROJECTION}.href,
    }[],
    promoBanner {
      showTrustpilot,
      captionsTool[]{
        ${coalesceLocaleField('caption', localeId)},
        ${coalesceLocaleField('tooltip', localeId)},
        bgColor,
        textColor
      },
      captions[] {
        ${coalesceLocaleField('caption', localeId)},
      },
      dateItFinishes,
      enabledTimer,
      backgroundColor,
      textColor
    },
    "pageBuilder": *[ !(_id in path('drafts.**')) && _type == "collectionsSettings"][0] {
      pageBuilder[]${PAGE_BUILDER_PROJECTION}
    }.pageBuilder
  }`

  const queryPT = groq`*[_type == "collectionsSettings"][0] {
    globalPromoTiles{
      "index": index,
      icon${MEDIA_PROJECTION},
      icon_fr${MEDIA_PROJECTION},
      icon_de${MEDIA_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      ${coalesceLocaleField('description2', localeId)},
      ${coalesceLocaleField('description3', localeId)},
      "href": link${LINK_PROJECTION}.href,
    }[]
  }`

  const pageData = await getClient(preview).fetch(query)
  const globalPromoTilesData = await getClient(preview).fetch(queryPT)

  pageData.promoTiles =
    pageData.promoTiles || globalPromoTilesData?.globalPromoTiles

  return { status: 'OK', data: pageData }
}
