import { FC } from 'react'
import { useCartContext } from '../useCart'
import { styled } from '@magal/styles'
import { useTranslation } from 'react-i18next'
import { Button, Loader, PricePrimitive } from '@magal/components'
import { useCheckout } from '../useCheckout'

const SummaryWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  justifyContent: 'space-between',
  alignItems: 'center',
  projectFont: 'heading04Medium',
})

const InfoWrap = styled('div', {
  display: 'grid',
  gap: '$4',
  marginBottom: '$16',
})

const SummaryLabel = styled('h3', {
  projectFont: 'body01',
})

const ShippingShortInfo = styled('div', {
  padding: '$12',
  backgroundColor: '$gray400',
  textAlign: 'center',
  margin: '0 0 $16 0',
})

const ShippingInfoCaption = styled('span', {
  projectFont: 'body04',
})

export const CartSummary: FC<{ freeDeliveryMinimumAmount?: number }> = ({
  freeDeliveryMinimumAmount,
}) => {
  const { cart, processing } = useCartContext()
  const { t } = useTranslation('cart')
  const { handleCheckout } = useCheckout()

  function putShippingPriceInfo() {
    if (freeDeliveryMinimumAmount && cart?.cost.totalAmount) {
      const amount =
        freeDeliveryMinimumAmount - parseFloat(cart?.cost.totalAmount.amount)
      const stringAmount = amount.toString()
      const price = {
        amount: stringAmount,
        currencyCode: cart?.cost.totalAmount.currencyCode,
      }
      if (amount >= 0) {
        return (
          <>
            {`${t('freeDelivery.youre')} `}
            <PricePrimitive price={price} />
            {` ${t('freeDelivery.awayFromFreeShipping')}`}
          </>
        )
      }
    }
    return t('freeDelivery.youreEligibleForFreeShipping')
  }

  return (
    <>
      <InfoWrap>
        {freeDeliveryMinimumAmount && (
          <ShippingShortInfo>
            <ShippingInfoCaption>{putShippingPriceInfo()}</ShippingInfoCaption>
          </ShippingShortInfo>
        )}
        <SummaryWrap>
          <SummaryLabel>{t('estimatedTotal')}</SummaryLabel>
          <PricePrimitive price={cart?.cost.totalAmount} />
        </SummaryWrap>
      </InfoWrap>

      {processing ? (
        <Button appearance={'solidRed'} disabled={true}>
          <Loader size={'small'} />
        </Button>
      ) : (
        <Button onClick={handleCheckout} appearance={'solidRed'}>
          <div>
            {`${t('checkout')} —`}&nbsp;
            <PricePrimitive price={cart?.cost.totalAmount} />
          </div>
        </Button>
      )}
    </>
  )
}
