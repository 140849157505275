import { styled } from '@magal/styles'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const FormContainer = styled('div', {
  display: 'grid',
  gap: '$48',
  gridAutoFlow: 'row',
  backgroundColor: '$gray300',
  padding: '$48 $32 $32 $32',
  borderRadius: '$r2',
  height: 'fit-content',
  color: '$green_09',
})

const TextContainer = styled('div', {
  display: 'grid',
  '@lg': {
    gridAutoFlow: 'column',
  },
})

const HeaderContainer = styled('div', {
  display: 'grid',
  gap: '$4',
})

const Title = styled('h2', {
  projectFont: 'caps05',
})

const Description = styled('p', {
  maxWidth: '$maxWidthS',
  projectFont: 'body01',
})

const RequiredText = styled('p', {
  projectFont: 'body02',
  textAlign: 'right',
  '@lg': {
    gridColumn: 'span 2',
    order: 2,
  },
})

type FormProps = {
  isRequiredTextVisible?: boolean
  title?: string
  description?: string | undefined
}

export const FormWrapper: FC<FormProps> = ({
  isRequiredTextVisible = false,
  children,
  title,
  description,
}) => {
  const { t } = useTranslation('account')
  return (
    <FormContainer>
      {(title || description || isRequiredTextVisible) && (
        <TextContainer>
          {isRequiredTextVisible && (
            <RequiredText>{t('loginPage.requiredFields')}</RequiredText>
          )}
          <HeaderContainer>
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
          </HeaderContainer>
        </TextContainer>
      )}
      {children}
    </FormContainer>
  )
}
