import { CartLineType } from '@magal/models'

export const BUNDLE_ID_KEY = '_bundleId'
export const BUNDLE_ID_PREFIX_MAIN = 'main_'
export const BUNDLE_ID_PREFIX_ADDITIONAL = 'additional_'

// TODO TESTS
export const isBundleAdditional = (cartLine: CartLineType) =>
  !!cartLine.attributes
    ?.find((a) => a.key === BUNDLE_ID_KEY)
    ?.value.startsWith(BUNDLE_ID_PREFIX_ADDITIONAL)
export const isBundleMain = (cartLine: CartLineType) =>
  !!cartLine.attributes
    ?.find((a) => a.key === BUNDLE_ID_KEY)
    ?.value.startsWith(BUNDLE_ID_PREFIX_MAIN)

export const normalizeBundleId = (bundleId: string) => {
  return bundleId
    .replace(BUNDLE_ID_PREFIX_MAIN, '')
    .replace(BUNDLE_ID_PREFIX_ADDITIONAL, '')
}
export const getBundleId = (line: CartLineType) => {
  const rawId = line?.attributes?.find((a) => a.key === BUNDLE_ID_KEY)?.value
  if (!rawId) return undefined
  return normalizeBundleId(rawId)
}

export const getCartLinesByBundleId: (
  cartLines: CartLineType[],
  bundleId: string,
) => CartLineType[] = (cartLines, bundleId) => {
  bundleId = normalizeBundleId(bundleId)
  return cartLines.reduce((acc: CartLineType[], line) => {
    return line.attributes
      ?.find((a) => a.key === BUNDLE_ID_KEY)
      ?.value.includes(bundleId)
      ? [...acc, line]
      : acc
  }, [])
}

const getAmountSum: (money: string[]) => string = (money) =>
  money.reduce((acc, val) => {
    const partialSum =
      Math.round((parseFloat(acc) + parseFloat(val)) * 100) / 100
    return partialSum.toString()
  }, '0')

export const getTotalBundleCost = (
  cartLines: CartLineType[],
  bundleId: string,
) => {
  const bundleCartLines = getCartLinesByBundleId(cartLines, bundleId)

  return bundleCartLines.reduce(
    (acc, val) => {
      return {
        ...acc,
        amount: getAmountSum([acc.amount, val.cost.totalAmount.amount]),
      }
    },
    {
      amount: '0',
      currencyCode: cartLines[0].cost.totalAmount.currencyCode,
    },
  )
}
