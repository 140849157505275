import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createBlockContentProjection } from '../projections/createBlockContentProjection'
import { createMediaWithCaptionProjection } from '../projections/createMediaWithCaptionProjection'

export const createSectionJournalColumnProjection = (localeId: LocaleId) => {
  const BLOCK_CONTENT_PROJECTION = createBlockContentProjection(localeId)
  const MEDIA_WITH_CAPTION_PROJECTION =
    createMediaWithCaptionProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'description',
          localeId,
        )}[]${BLOCK_CONTENT_PROJECTION},
        mediaWithCaption${MEDIA_WITH_CAPTION_PROJECTION},
      }
  `
}
