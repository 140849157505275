import { ShopifyPriceRange, ShopifyPrice } from '@magal/models'

declare global {
  interface Window {
    discountPercentage: any
  }
}

export interface DiscountPercentage {
  enableDiscount: boolean
  percentage: number
}

export function formatDiscountRange(
  price: ShopifyPriceRange,
  discount: DiscountPercentage | undefined,
): ShopifyPriceRange {
  if (!discount || !discount?.enableDiscount) return price

  const finalDiscountAmount = (100 - discount.percentage) / 100

  const discountedPrice = {
    maxVariantPrice: {
      amount: `${
        parseFloat(price.maxVariantPrice.amount) * finalDiscountAmount
      }`,
      currencyCode: 'USD',
    },
    minVariantPrice: {
      amount: `${
        parseFloat(price.minVariantPrice.amount) * finalDiscountAmount
      }`,
      currencyCode: 'USD',
    },
  }

  return discountedPrice
}

export function formatDiscount(
  price: ShopifyPrice,
  discount: DiscountPercentage | undefined,
): ShopifyPrice {
  if (!discount || !discount?.enableDiscount) return price

  const finalDiscountAmount = (100 - discount.percentage) / 100

  const discountedPrice = {
    amount: `${parseFloat(price.amount) * finalDiscountAmount}`,
    currencyCode: 'USD',
  }

  return discountedPrice
}
