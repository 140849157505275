import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createBlockContentProjection } from '../projections/createBlockContentProjection'

export const createSectionUtilityPageHeaderProjection = (
  localeId: LocaleId,
) => {
  const BLOCK_CONTENT_PROJECTION = createBlockContentProjection(localeId)

  return groq`{
        "_type": "sectionUtilityPageHeader",
        "_key": "sectionUtilityPageHeader",
        "text": *[ !(_id in path('drafts.**')) && _type == "utilityPagesSettings" ] {
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${BLOCK_CONTENT_PROJECTION},
        }[0],
      }
  `
}
