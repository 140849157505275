import { FC } from 'react'
import { styled } from '@magal/styles'
import { useCustomerContext } from '../../../customerContext'
import { usePageContext } from '../pageContext'
import { formatDate } from '../../../helpers/formatDate'
import { Link, ProductVariantPrice, CartLine } from '@magal/components'
import { AddressItem } from '../components/AddressItem'
import { CartLineType } from '@magal/models'
import { useTranslation } from 'react-i18next'
import { BackButton } from '../../components/BackButton'
import { AccountCardWrapper } from '../components/AccountCardWrapper'
import { formatVariant } from '@magal/services/shopify-service'

const Root = styled('div', {
  projectFont: 'body01',
  color: '$green_09',
  display: 'grid',
  gap: '$32',
})

const Header = styled('div', {
  display: 'grid',
  gap: '$8',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@lg': {
    gridTemplateColumns: 'auto auto',
  },
})

const Heading = styled('h2', {
  projectFont: 'caps02',
})

const SubHeading = styled('h3', {
  projectFont: 'caps06',
})

const OrderDetails = styled('div', {
  display: 'grid',
  gap: '$16',
  '@md': {
    gridTemplateColumns: '1fr 1fr',
    gap: '$32',
  },
})

const OrderDetailsHeading = styled('h3', {
  projectFont: 'caps03',
})

const AddressContainer = styled('div', {
  display: 'grid',
  gap: '$16',
})

const LineItemsList = styled('ul', {
  display: 'grid',
  gridRowGap: '$36',
})

const FooterWrap = styled('div', {
  borderTop: '1px solid $green_09',
  paddingTop: '$20',
  display: 'grid',
  alignContent: 'start',
  '@lg': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
})

const PricesWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  '@lg': {
    gridColumn: '2',
  },
})

const PriceWrap = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  variants: {
    hasTopBorder: {
      true: {
        paddingTop: '$8',
        borderTop: '1px solid $green_09',
      },
    },
  },
})

const PriceText = styled('h3', {
  projectFont: 'heading03',
})

export const ViewOrder: FC = () => {
  const { customer } = useCustomerContext()
  const { id, setActiveView } = usePageContext()
  const { t } = useTranslation('account')

  const order = customer?.orders?.edges?.find(
    (order) => order?.node?.id === id,
  )?.node

  if (!order) {
    return null
  }

  const {
    name,
    processedAt,
    shippingAddress,
    statusUrl,
    billingAddress,
    lineItems,
    currentTotalPrice,
    subtotalPriceV2,
    totalTaxV2,
  } = order

  const date = processedAt ? formatDate(processedAt) : null

  const lines: (CartLineType | null)[] = lineItems.edges.map(
    ({ node }, index) => {
      if (!node) {
        return null
      }

      const { originalTotalPrice, quantity, variant, customAttributes } = node
      return {
        id: index.toString(),
        quantity,
        attributes: customAttributes,
        cost: {
          amountPerQuantity: originalTotalPrice,
          compareAtAmountPerQuantity: originalTotalPrice,
          subtotalAmount: originalTotalPrice,
          totalAmount: originalTotalPrice,
        },
        merchandise: formatVariant(variant),
      }
    },
  )

  return (
    <Root>
      <BackButton onClick={() => setActiveView('orders-list')}>
        {t('accountPage.general.backToOrdersListButtonLabel')}
      </BackButton>
      <AccountCardWrapper>
        <Header>
          <Heading>
            {t('accountPage.orderView.heading', { value: name })}
          </Heading>
          <SubHeading>{date}</SubHeading>
        </Header>
        <OrderDetails>
          <AddressContainer>
            <OrderDetailsHeading>Shipping address</OrderDetailsHeading>
            {shippingAddress && (
              <AddressItem
                address={shippingAddress}
                title={t('accountPage.orderView.shippingAddress')}
              />
            )}
          </AddressContainer>
          <AddressContainer>
            <OrderDetailsHeading>Billing address</OrderDetailsHeading>
            {billingAddress && (
              <AddressItem
                address={billingAddress}
                title={t('accountPage.orderView.billingAddress')}
              />
            )}
          </AddressContainer>
        </OrderDetails>
        <Link href={statusUrl} appearance="solidGreen">
          {t('accountPage.orderView.trackOrderButtonLabel')}
        </Link>
        <LineItemsList>
          {lines.map(
            (line) =>
              line && (
                <li key={line.id}>
                  {<CartLine line={line} isEditable={false} />}
                </li>
              ),
          )}
        </LineItemsList>
        <FooterWrap>
          <PricesWrap>
            <PriceWrap>
              <PriceText>
                {t('accountPage.orderView.subtotalPriceLabel')}
              </PriceText>
              {subtotalPriceV2 && (
                <ProductVariantPrice
                  price={subtotalPriceV2}
                  noDiscount={true}
                />
              )}
            </PriceWrap>
            <PriceWrap>
              <PriceText>
                {t('accountPage.orderView.taxesPriceLabel')}
              </PriceText>
              {totalTaxV2 && (
                <ProductVariantPrice price={totalTaxV2} noDiscount={true} />
              )}
            </PriceWrap>
            <PriceWrap hasTopBorder={true}>
              <PriceText>
                {t('accountPage.orderView.totalPriceLabel')}
              </PriceText>
              {currentTotalPrice && (
                <ProductVariantPrice
                  price={currentTotalPrice}
                  noDiscount={true}
                />
              )}
            </PriceWrap>
          </PricesWrap>
        </FooterWrap>
      </AccountCardWrapper>
    </Root>
  )
}
