import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClientError } from 'graphql-request'
import { useRouter } from 'next/router'
import {
  validateRequired,
  useFormSubmitState,
  validateEmail,
  SubmitButton,
  FormTextField,
} from '@magal/forms'

import { createCustomerClient, ErrorResponseType } from '../customerService'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { Form } from '../components/Form'

interface RecoverPasswordFormProps {
  className?: string
  onSubmitSuccess?: () => void
}

type FormValues = {
  email: string
}

const defaultValues: FormValues = {
  email: '',
}

export const RecoverPasswordForm: FC<RecoverPasswordFormProps> = ({
  className,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({ form, defaultValues })

  const handleResponseErrors = (errors: ErrorResponseType[]) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()

      const response = await customerClient.recover(data)
      const errors = response?.customerRecover?.customerUserErrors

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="recover-password-form"
    >
      <FormTextField
        control={control}
        rules={{ ...validateRequired(t), ...validateEmail(t) }}
        autoComplete="email"
        type="email"
        name="email"
        label={t('recoverPasswordForm.emailFieldLabel')}
      />
      <SubmitButton
        text={t('recoverPasswordForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
    </Form>
  )
}
