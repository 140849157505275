import { styled } from '@magal/styles'
import React from 'react'

const Grid = styled('div', {
  display: 'grid',
  gridColumnGap: '6px',
  gridRowGap: '$24',
  gridTemplateColumns: 'repeat(2, 1fr)',
  '@lg': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@xl': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})

export const ProductGrid: React.FC<{
  className?: string
}> = ({ children, className }) => {
  return <Grid className={className}>{children}</Grid>
}
