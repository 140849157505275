import Script from 'next/script'
import { FC, useEffect, useState } from 'react'
import { styled } from '@magal/styles'

const DESKTOP_OFFSET = 28
const MOBILE_OFFSET = 16
const SIZE = 40

const FakeChatButton = styled('button', {
  position: 'fixed',
  bottom: MOBILE_OFFSET,
  right: MOBILE_OFFSET,
  zIndex: '999999999999',
  width: SIZE,
  height: SIZE,
  borderRadius: '50%',
  background: 'rgb(66, 74, 65)',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px',

  '@lg': {
    bottom: DESKTOP_OFFSET,
    right: DESKTOP_OFFSET,
  },
})

export const ReamazeChatWidget: FC = () => {
  const [shouldLoadScript, setShouldLoadScript] = useState(false)
  const [openedByInteraction, setOpenedByInteraction] = useState(false)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const handleFakeButtonClick = () => {
    setOpenedByInteraction(true)
    setShouldLoadScript(true)
  }

  useEffect(() => {
    const handler = () => {
      if (openedByInteraction) {
        setTimeout(() => {
          ;(window as any)['Reamaze'].popup()
        }, 500)
      }

      setTimeout(() => {
        setScriptLoaded(true)
      }, 500)
    }

    document.addEventListener('Reamazeinit', handler)

    return () => {
      document.removeEventListener('Reamazeinit', handler)
    }
  }, [openedByInteraction])

  useEffect(() => {
    const handler = () => {
      setShouldLoadScript(true)
    }

    if (!scriptLoaded) {
      window.addEventListener('scroll', handler, { passive: true })
      window.addEventListener('mousemove', handler, { passive: true })
    }

    if (scriptLoaded) {
      window.removeEventListener('mousemove', handler)
      window.removeEventListener('scroll', handler)
    }

    return () => {
      window.removeEventListener('mousemove', handler)
      window.removeEventListener('scroll', handler)
    }
  }, [scriptLoaded])

  return (
    <>
      {!scriptLoaded && (
        <FakeChatButton onClick={handleFakeButtonClick}>
          <svg width={SIZE} height={SIZE}>
            <g transform="scale(1.1111111111111112) translate(2, 2)">
              <g transform="scale(0.75) translate(5.625, 5.625)">
                <path
                  fill="rgba(255, 255, 255, 1)"
                  d="M15.985,5.972c-7.563,0-13.695,4.077-13.695,9.106c0,2.877,2.013,5.44,5.147,7.108c-0.446,1.479-1.336,3.117-3.056,4.566c0,0,4.015-0.266,6.851-3.143c0.163,0.04,0.332,0.07,0.497,0.107c-0.155-0.462-0.246-0.943-0.246-1.443c0-3.393,3.776-6.05,8.599-6.05c3.464,0,6.379,1.376,7.751,3.406c1.168-1.34,1.847-2.892,1.847-4.552C29.68,10.049,23.548,5.972,15.985,5.972zM27.68,22.274c0-2.79-3.401-5.053-7.599-5.053c-4.196,0-7.599,2.263-7.599,5.053c0,2.791,3.403,5.053,7.599,5.053c0.929,0,1.814-0.116,2.637-0.319c1.573,1.597,3.801,1.744,3.801,1.744c-0.954-0.804-1.447-1.713-1.695-2.534C26.562,25.293,27.68,23.871,27.68,22.274z"
                ></path>
              </g>
            </g>
          </svg>
        </FakeChatButton>
      )}

      {shouldLoadScript && (
        <>
          <Script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
                   var _support = _support || { 'ui': {}, 'user': {} };
                   _support['account'] = 'magaljewelrynew';
                   _support['ui']['contactMode'] = 'mixed';
                   _support['ui']['enableKb'] = 'true';
                   _support['ui']['disableAudio'] = true;
                   _support['ui']['shoutboxFacesMode'] = 'default';
                   _support['ui']['shoutboxHeaderLogo'] = true;
                   _support['ui']['widget'] = {
                     displayOn: 'all',
                     fontSize: 'default',
                     allowBotProcessing: true,
                     label: {},
                     position: {
                       bottom: '${DESKTOP_OFFSET}px',
                       right: '${DESKTOP_OFFSET}px'
                     },
                     mobilePosition: {
                       bottom: '${MOBILE_OFFSET}px',
                       right: '${MOBILE_OFFSET}px'
                     },
                     size: '${SIZE}',
                     mobileSize: '${SIZE}',
                     icon: 'chat',
                     color: 'rgb(66, 74, 65)'
                   };
                   _support['apps'] = {
                     faq: {"enabled":true},
                     recentConversations: {},
                     orders: {}
                   };
           `,
            }}
          />
          <Script
            type="text/javascript"
            async
            src="https://cdn.reamaze.com/assets/reamaze.js"
          />
        </>
      )}
    </>
  )
}
