import { styled } from '@magal/styles'
import React, { FC } from 'react'
import { BlockContent } from '@magal/components'

const Wrapper = styled('div', {
  display: 'grid',
  gap: '$48',
  gridAutoFlow: 'row',
  backgroundColor: '$gray300',
  padding: '$32',
  borderRadius: '$r2',
  height: 'fit-content',
  width: '100%',
  color: '$green_09',
})

const Title = styled('h2', {
  projectFont: 'caps05',
})

const Description = styled('div', {
  maxWidth: '$maxWidthS',
  projectFont: 'body01',
})

type ViewCardWrapperProps = {
  title?: string
  description?: string
}

export const AccountCardWrapper: FC<ViewCardWrapperProps> = ({
  children,
  title,
  description,
}) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {description && (
        <Description>
          <BlockContent body={description} />
        </Description>
      )}
      {children}
    </Wrapper>
  )
}
