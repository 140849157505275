import { useState, createContext, FC, useContext } from 'react'

type ViewsType = 'orders-list' | 'orders-view' | 'address-list'

const DEFAULT_VIEW = 'orders-list'

export type PageContextType = {
  activeView: ViewsType
  id: string | null
  setActiveView: (viewName: ViewsType, id?: string) => void
}

export const PageContext = createContext<PageContextType>({
  activeView: DEFAULT_VIEW,
  id: null,
  setActiveView: () => null,
})

export const PageContextProvider: FC = ({ children }) => {
  const [view, setView] = useState<ViewsType>('orders-list')
  const [id, setId] = useState<string | null>(null)

  const setActiveView = (viewName?: ViewsType, id?: string) => {
    setView(viewName || DEFAULT_VIEW)
    setId(id || null)
  }

  return (
    <PageContext.Provider
      value={{
        activeView: view,
        id,
        setActiveView,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

export const usePageContext = (): PageContextType => {
  return useContext(PageContext)
}
