import { FC } from 'react'
import { useCartContext } from '../useCart'
import { styled } from '@magal/styles'
import { Button } from '@magal/components'
import { Cart } from '@magal/icons'
import router from 'next/router'

const Wrapper = styled(Button, {
  position: 'relative',
  display: 'grid',
  alignItems: 'center',
  padding: '0 $12',
  color: '$green_09',
  '@lg': {
    padding: '0 $16',
    marginRight: '-$16',
    height: '80%',
  },
})

const Quantity = styled('div', {
  height: '$16',
  width: '$16',
  border: '1px solid $green_09',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$rMax',
  projectFont: 'caps07',
  background: '$green_09',
  color: '$white',
  position: 'absolute',
  top: 'calc(50% - 1px)',
  left: 'calc(50% - 3px)',
})

export const CartButton: FC = () => {
  const { cart, openMiniCart } = useCartContext()

  const quantity = cart?.totalQuantity ?? 0

  return (
    <Wrapper
      onClick={() => (router.pathname !== '/cart' ? openMiniCart() : null)}
    >
      <Cart />
      {quantity > 0 && <Quantity>{quantity}</Quantity>}
    </Wrapper>
  )
}
