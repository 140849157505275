// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const enabledContexts = ['production', 'deploy-preview']

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn:
    SENTRY_DSN ||
    'https://00553eae8dd748bfaf3bc1fb9b39721b@o1421083.ingest.sentry.io/6767128',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.tags?.sampleRate === 'high') {
      return 1
    }
    return 0.5
  }, // Sentry will not run on localhost
  enabled: enabledContexts.includes(process.env.SENTRY_ENVIRONMENT),
  // Set up integrations
  // https://docs.sentry.io/platforms/javascript/configuration/integrations/plugin/
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // "log" not included
      levels: ['error'],
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
