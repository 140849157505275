import { FC, useEffect, useState, useRef } from 'react'
import { SiteConfiguration } from '@magal/models'
import { styled } from '@magal/styles'
import { ToolTip, TrustBox, ArrowButton } from '@magal/components'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { handlePopUpTrigger } from '@magal/services/gtm-service'
import { useRouter } from 'next/router'
import { CountDownRenderer } from './components/CountDownRenderer'

export type PromoBarProps = {
  promoBar: SiteConfiguration['header']['promoBar']
}

const Root = styled('div', {
  position: 'relative',
  height: '$promoBarHeightMobile',
  background: '$green',
  display: 'grid',
  color: 'white',
  '@lg': {
    height: '$promoBarHeightDesktop',
  },
})
const Inner = styled('div', {
  display: 'flex',
  textAlign: 'center',
  alignContent: 'center',
  alignItems: 'center',
  projectFont: 'caps09',
  fontSize: '12px',
  padding: '0 10px',
  lineHeight: 1.8,
  width: '100%',
  '@lg': {
    lineHeight: 1.2,
    textAlign: 'left',
    padding: '0 $containerMarginDesktop',
  },
})
const Captions = styled('div', {
  display: 'grid',
  textAlign: 'center',
  width: '100%',
})
const Caption = styled('div', {
  gridArea: '1 / 1',
  transition: 'opacity 300ms ease 300ms, visibility 0ms',
  lineHeight: 1.65,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  variants: {
    active: {
      true: {},
      false: {
        transition: 'opacity 300ms ease 0ms, visibility 300ms',
        visibility: 'hidden',
        opacity: 0,
      },
    },
    absolute: {
      true: {
        position: 'absolute',
        display: 'none',
      },
    },
  },
  '& a': {
    borderBottom: '1px solid',
    paddingBottom: '2px',
  },
})
const CountdownWrap = styled('div', {
  display: 'none',
  '@lg': {
    alignItems: 'center',
    display: 'flex',
    gap: '$24',
    position: 'absolute',
    right: 'var(--space-containerMarginDesktop)',
    top: 'calc(50% - 7px)',
  },
})
const CountdownLabel = styled('div', {
  display: 'none',
  '@lg': {
    display: 'block',
  },
})
const CampaignTrigger = styled('span', {
  textDecoration: 'underline',
  cursor: 'pointer',
})

type CountDownR = {
  days: number
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}

const LOCAL_STORAGE_PROMO_BAR = 'magal_promo_time'
const DEFAULT_PROMO_BAR_HOURS = 1

function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function getMilliseconds(hours: number) {
  return hours * 60000 * 60 - 60000 * randomInteger(2, 4)
}

export const PromoBar: FC<PromoBarProps> = ({ promoBar }) => {
  const {
    hoursToFinish,
    captions,
    captionsTool,
    showTrustpilot,
    enabledTimer,
    sliderTime,
  } = promoBar

  const PROMO_SLIDE_INTERVAL = sliderTime || 14
  const parsedHoursToFinish = hoursToFinish ?? DEFAULT_PROMO_BAR_HOURS
  const [activeCaptionIndex, setActiveCaptionIndex] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [countdownKey, setCountdownKey] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const [correctFinishTime, setCorrectFinishTime] = useState<Date>()

  const { t } = useTranslation('header')
  const { locale } = useRouter()

  const countDownRenderer: FC<CountDownR> = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => (
    <CountDownRenderer
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      completed={completed}
    />
  )

  const setNewDate = (newHoursToFinish: number) => {
    const newDate = new Date(Date.now() + getMilliseconds(newHoursToFinish))
    setCorrectFinishTime(newDate)
    window.localStorage.setItem(
      LOCAL_STORAGE_PROMO_BAR,
      newDate.getTime().toString(),
    )
  }

  useEffect(() => {
    const data = window.localStorage.getItem(LOCAL_STORAGE_PROMO_BAR)
    if (data !== null) {
      const date = new Date(JSON.parse(data))
      if (date.getTime() < Date.now()) {
        setNewDate(parsedHoursToFinish)
      } else {
        setCorrectFinishTime(date)
      }
    } else {
      setNewDate(parsedHoursToFinish)
    }
  }, [parsedHoursToFinish])

  const resetTimer = () => {
    setCountdownKey((prev) => prev + 1)
    setNewDate(parsedHoursToFinish)
  }
  const captionsLength =
    (captions || []).length +
    (captionsTool || []).length +
    (showTrustpilot ? 1 : 0)

  useEffect(() => {
    setLoaded(true)
    setIsMobile(window.innerWidth < 1024)

    const intervalID = setInterval(() => {
      setActiveCaptionIndex((prevIndex) => {
        return prevIndex + 1 < captionsLength ? prevIndex + 1 : 0
      })
    }, PROMO_SLIDE_INTERVAL * 1000)
    return () => clearInterval(intervalID)
  }, [captionsLength, activeCaptionIndex, PROMO_SLIDE_INTERVAL])

  const ToolTipRef = useRef(null)
  const [showToolTip, setShowToolTip] = useState(false)

  const handleMouseOver = (state: boolean) => {
    setShowToolTip(state)
  }

  const handleArrows = (next: boolean) => {
    if (next) {
      setActiveCaptionIndex((prevIndex) => {
        return prevIndex + 1 < captionsLength ? prevIndex + 1 : 0
      })

      return
    }

    setActiveCaptionIndex(() => {
      return activeCaptionIndex - 1 < 0
        ? captionsLength - 1
        : activeCaptionIndex - 1
    })

    return
  }

  if (captionsLength < 1) return null

  type promoCaption = {
    caption: string
  }

  return (
    <Root
      css={{
        backgroundColor: promoBar.backgroundColor?.hex,
        color: promoBar.textColor?.hex,
      }}
    >
      <Inner>
        <ArrowButton onClick={() => handleArrows(false)} type={'prev'} />
        <Captions>
          {captions?.map((promo: promoCaption, i: number) => (
            <Caption key={i} active={activeCaptionIndex === i}>
              <div>
                {promo.caption.includes('[attentive]') ? (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: promo.caption.split('[attentive]')[0],
                      }}
                    />
                    {promo.caption.includes('[attentive]') && (
                      <CampaignTrigger
                        role="button"
                        onClick={() => handlePopUpTrigger(locale)}
                      >
                        {t('signUp')}
                      </CampaignTrigger>
                    )}
                    {promo.caption.split('[attentive]')[1] && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: promo.caption.split('[attentive]')[1],
                        }}
                      />
                    )}
                  </>
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: promo.caption.replace('[attentive]', ''),
                    }}
                  />
                )}
              </div>
            </Caption>
          ))}

          {captionsTool?.map((promo, i) => {
            const j = i + (captions || []).length
            return (
              <Caption key={j} active={activeCaptionIndex === j}>
                <span
                  onMouseEnter={() => handleMouseOver(true)}
                  onMouseLeave={() => handleMouseOver(false)}
                  ref={ToolTipRef}
                  dangerouslySetInnerHTML={{ __html: promo.caption }}
                ></span>
                {showToolTip && activeCaptionIndex === j && (
                  <ToolTip parentRef={ToolTipRef} promo={promo}></ToolTip>
                )}
              </Caption>
            )
          })}
          {showTrustpilot && loaded ? (
            <Caption
              absolute={activeCaptionIndex !== captionsLength - 1}
              active={activeCaptionIndex === captionsLength - 1}
            >
              <TrustBox
                theme={'dark'}
                height={isMobile ? '45px' : '20px'}
                location="promobar"
              />
            </Caption>
          ) : null}
        </Captions>

        {enabledTimer &&
          parsedHoursToFinish &&
          loaded &&
          !(activeCaptionIndex === captionsLength - 1) && (
            <CountdownWrap>
              <CountdownLabel>Offer ends in</CountdownLabel>
              <Countdown
                key={`countdown-${countdownKey}`}
                date={correctFinishTime}
                renderer={countDownRenderer}
                onComplete={resetTimer}
              />
            </CountdownWrap>
          )}
        <ArrowButton onClick={() => handleArrows(true)} type={'next'} />
      </Inner>
    </Root>
  )
}
