import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionImageAndContentProjection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
        _key,
        _type,
        hasBorders,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'description',
          localeId,
        )}[]${RICH_TEXT_PROJECTION},
        imageAlignment,
        image${MEDIA_PROJECTION},
        button {
          ${coalesceLocaleField('label', localeId)},
          link${LINK_PROJECTION}
        }
      }
  `
}
