import { Action } from '@magal/models'
import { FC } from 'react'
import { captureException } from '@sentry/nextjs'

type LinkActionProps = {
  action: Action
  className?: string
}

export const LinkAction: FC<LinkActionProps> = ({ action }) => {
  switch (action.type) {
    default:
      captureException(
        new Error(
          `[LinkAction] There is no defined actionType${
            (action as Action)?.type && `: ${(action as Action).type}`
          }`,
        ),
      )
      return <h3>There is no defined actionType.</h3>
  }
}
