import { gql } from 'graphql-request'
import { IMAGE_FRAGMENT } from './image'
import { MONEY_FRAGMENT } from './money'
import { PRODUCT_VARIANT_FRAGMENT } from './productFragment'

export const CART_LINE_FRAGMENT = gql`
  fragment CartLineFragment on CartLine {
    id
    quantity
    attributes {
      key
      value
    }
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
    }
    cost {
      amountPerQuantity {
        amount
        currencyCode
      }
      compareAtAmountPerQuantity {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
    merchandise {
      ...ProductVariantFragment
    }
  }
`
export const CART_FRAGMENT = gql`
  ${MONEY_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  ${CART_LINE_FRAGMENT}
  fragment CartFragment on Cart {
    id
    checkoutUrl
    note
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
      totalDutyAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
    }
    buyerIdentity {
      countryCode
      email
    }
    lines(first: 200) {
      nodes {
        ...CartLineFragment
      }
    }
  }
`
