interface LineItem {
  item: string | number
  q: number
}

declare global {
  interface Window {
    TriplePixel: (type: string, item: LineItem) => null
  }
}

export const TripleAtc = (item: LineItem): void => {
  if (!window.TriplePixel) return
  window.TriplePixel('AddToCart', item)
}
