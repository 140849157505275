export const ChevronRight = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.1352 12.1938C15.121 12.2871 15.0884 12.4177 14.9974 12.517L9.99743 17.9715C9.86279 18.1184 9.68569 18.2 9.5 18.2C9.31432 18.2 9.13721 18.1184 9.00257 17.9715C8.73274 17.6771 8.73248 17.2331 9.0018 16.9384L13.48 11.9469L9.00257 7.06241C8.73248 6.76777 8.73248 6.32313 9.00257 6.02849C9.13721 5.88161 9.31432 5.8 9.5 5.8C9.68569 5.8 9.86279 5.88161 9.99743 6.02849L14.96 11.4422C15.0137 11.4674 15.0524 11.5085 15.0767 11.5468C15.1111 11.6011 15.1263 11.6615 15.1342 11.7048C15.1501 11.7912 15.15 11.8882 15.15 11.9404L15.15 11.9485C15.15 12.0026 15.15 12.0969 15.1352 12.1938Z" />
  </svg>
)
