import { FC } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { CheckboxField } from './CheckboxField/CheckboxField'
import { CheckboxFieldPropsType } from './CheckboxField/CheckboxFieldPropsType'

type FormTextFieldProps = UseControllerProps<any> & CheckboxFieldPropsType

export const FormCheckboxField: FC<FormTextFieldProps> = ({
  rules,
  control,
  name,
  shouldUnregister,
  defaultValue,
  helpText,
  disabled,
  ...restOfProps
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return (
    <CheckboxField
      disabled={disabled}
      id={name}
      helpText={helpText || error?.message}
      invalid={invalid}
      {...field}
      {...restOfProps}
    />
  )
}
