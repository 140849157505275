import { useEffect, useState } from 'react'
import { ShopifyProductColor } from '@magal/models'
import { useProductsSettings } from '@magal/product'

export const useOrderProductsGlobalColor = (
  productColors: ShopifyProductColor[],
) => {
  const { colorOrder } = useProductsSettings()
  const [orderProductColor, setOrderProductColor] = useState(productColors)

  useEffect(() => {
    const newOrderProducts: ShopifyProductColor[] = productColors
      ?.map((product) => {
        const index = colorOrder?.findIndex(
          (orderProduct) => orderProduct === product.id,
        )
        return {
          ...product,
          index,
        }
      })
      .sort((a, b) => a.index - b.index)
      .map(({ handle, id }) => ({ handle, id })) as ShopifyProductColor[]

    setOrderProductColor(newOrderProducts)
  }, [productColors, colorOrder])

  return {
    orderProductColor,
  }
}
