// Stateless section, only for presentation. Intentionally doesn't appear in Page Builder.
// It only displays title and product cards.
// Examples of use: SectionRecentlyViewed / SectionProductRecommendationsProps

import { Container } from '../Container/Container'
import { ProductCard } from '../ProductCard/ProductCard'
import { ProductGrid } from '../ProductGrid/ProductGrid'
import { SectionWrap } from '../SectionWrap/SectionWrap'

import { ShopifyProduct } from '@magal/models'
import { styled } from '@magal/styles'
import { FC } from 'react'

const Title = styled('h2', {
  projectFont: 'caps02',
  color: '$green_09',
  marginBottom: '$32',
  '@lg': {
    marginBottom: '$40',
  },
})

type SectionProductGridAndTitleProps = {
  title?: string
  products: ShopifyProduct[]
}

export const SectionProductGridAndTitle: FC<
  SectionProductGridAndTitleProps
> = ({ title, products }) => {
  if (!(products.length > 0)) return null

  return (
    <SectionWrap>
      {title && (
        <Container>
          <Title>{title}</Title>
        </Container>
      )}
      <ProductGrid>
        {products.map((product) => {
          return (
            <ProductCard
              key={product.id}
              image={product.featuredImage}
              title={product.title}
              handle={product.handle}
              priceRange={product.priceRange}
              color={product.color}
              tags={product.tags}
              compareAtPriceRange={product.compareAtPriceRange}
            />
          )
        })}
      </ProductGrid>
    </SectionWrap>
  )
}
