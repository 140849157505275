import { FC } from 'react'
import { useCartContext } from '../useCart'
import { useMediaQuery } from '@magal/styles'
import { Modal } from '@magal/components'
import { SiteConfiguration } from '@magal/models'
import { MiniCart } from './MiniCart'

export const MiniCartModal: FC<{ cartConfig: SiteConfiguration['cart'] }> = ({
  cartConfig,
}) => {
  const matchesMd = useMediaQuery('md')
  const { miniCartState, closeMiniCart } = useCartContext()
  return (
    <Modal
      preventScroll={true}
      isOpen={miniCartState.isOpen}
      onRequestClose={closeMiniCart}
      size={matchesMd ? 'stretch-y' : 'stretch'}
      position={matchesMd ? 'right' : 'center'}
      transition={'slide-from-right'}
    >
      <MiniCart cartConfig={cartConfig} />
    </Modal>
  )
}
