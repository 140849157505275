import { FC } from 'react'
import { styled } from '@magal/styles'

import { useTranslation } from 'react-i18next'
import { ActivateAccountForm } from '../forms/ActivateAccountForm'
import { useRouter } from 'next/router'
import { ROUTE_BASE_ACCOUNT } from '@magal/configs'
import { Container, SectionWrap } from '@magal/components'
import { FormWrapper } from '../components/FormWrapper'

const Root = styled(Container, {
  padding: '$48 0 $64',
  maxWidth: '$maxWidthS',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
})

type Props = {
  activationUrl?: string
}

export const ActivateAccountPage: FC<Props> = ({ activationUrl }) => {
  const { t } = useTranslation('account')
  const router = useRouter()

  const handleSubmitSuccess = () => {
    router.push(ROUTE_BASE_ACCOUNT)
  }

  return (
    <SectionWrap type={'MARGINS_S'}>
      <Root>
        <FormWrapper
          title={t('activateAccountPage.heading')}
          description={t('activateAccountPage.text')}
        >
          <ActivateAccountForm
            activationUrl={activationUrl}
            onSubmitSuccess={handleSubmitSuccess}
          />
        </FormWrapper>
      </Root>
    </SectionWrap>
  )
}
