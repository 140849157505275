import React, { FC } from 'react'
import { styled } from '@magal/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClientError } from 'graphql-request'
import { useRouter } from 'next/router'

import {
  validateRequired,
  useFormSubmitState,
  validateEmail,
  FormTextField,
  SubmitButton,
} from '@magal/forms'

import { createCustomerClient, ErrorResponseType } from '../customerService'
import { useCustomerContext } from '../customerContext'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { Link } from '@magal/components'
import { ROUTE_ACCOUNT_FORGOT_PASSWORD } from '@magal/configs'
import { FormWrapper } from '../components/FormWrapper'
import { Form } from '../components/Form'

interface RegisterFormProps {
  className?: string
  onSubmitSuccess?: () => void
  onForgotPasswordClick?: () => void
}

type FormValues = {
  email: string
  password: string
}

const defaultValues: FormValues = {
  email: '',
  password: '',
}

const ForgotPwdButton = styled(Link, {
  marginTop: '-$12',
  alignSelf: 'baseline',
  projectFont: 'body04',
  textDecoration: 'underline',
  textDecorationColor: 'transparent',
  '&:active': {
    transform: 'scale(0.95)',
  },
  '&:hover, &:focus-within, &:focus': {
    textDecorationColor: '$green_09',
  },
})

const ForgotPwdButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
})

export const LoginForm: FC<RegisterFormProps> = ({
  className,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })
  const { setToken } = useCustomerContext()

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({ form, defaultValues })

  const handleResponseErrors = (errors: ErrorResponseType[]) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const response = await customerClient.createToken(data)
      const errors = response?.customerAccessTokenCreate?.customerUserErrors

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      const token =
        response?.customerAccessTokenCreate?.customerAccessToken?.accessToken

      if (!token) {
        return setError()
      }

      setToken(token)
      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <FormWrapper
      isRequiredTextVisible={true}
      title={t('loginForm.header')}
      description={t('loginForm.description')}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={className}
        name="login-form"
        extraSubmitButtonPadding={true}
      >
        <FormTextField
          control={control}
          rules={{ ...validateRequired(t), ...validateEmail(t) }}
          autoComplete="email"
          type="email"
          name="email"
          id="email-login"
          label={t('loginForm.emailFieldLabel') + '*'}
        />
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="password"
          name="password"
          type="password"
          id="password-login"
          label={t('loginForm.passwordFieldLabel') + '*'}
        />
        <ForgotPwdButtonContainer>
          <ForgotPwdButton href={ROUTE_ACCOUNT_FORGOT_PASSWORD}>
            {t('loginForm.forgotPasswordButtonLabel')}
          </ForgotPwdButton>
        </ForgotPwdButtonContainer>

        <SubmitButton
          text={t('loginForm.submitButtonLabel')}
          helpText={submitMessage}
          invalid={invalid}
          success={success}
          loading={isSubmitting}
        />
      </Form>
    </FormWrapper>
  )
}
