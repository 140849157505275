import { useState, useEffect, createContext, FC } from 'react'
import { useRouter } from 'next/router'
import {
  AddLinesInput,
  Cart,
  CartLineType,
  UpdateLinesInput,
} from '@magal/models'
import {
  CartClientResponse,
  createCartClient,
} from '@magal/services/shopify-service'
import {
  equalAttributes,
  errorDebugMessage,
  fetchServiceWithRetry,
  getLocaleRegionIdFromPath,
  logDebugMessage,
} from '@magal/utils'

import { captureException } from '@sentry/nextjs'
import toast from 'react-hot-toast'

export type CartContextType = {
  cart: Cart | null
  miniCartState: {
    isOpen: boolean
    title?: string
  }
  processing: boolean
  addCartLines: (
    lines: AddLinesInput,
  ) => Promise<CartLineType[] | undefined> | undefined
  removeCartLines: (ids: string[]) => void
  updateCartLines: (lines: UpdateLinesInput) => void
  updateCartNote: (note: string) => void
  openMiniCart: (title?: string) => void
  closeMiniCart: () => void
}

export const CartContext = createContext<CartContextType>({
  cart: null,
  miniCartState: {
    isOpen: false,
    title: undefined,
  },
  processing: false,
  openMiniCart: () => undefined,
  closeMiniCart: () => undefined,
  addCartLines: () => undefined,
  removeCartLines: () => undefined,
  updateCartLines: () => undefined,
  updateCartNote: () => undefined,
})

const createLsCartIdService = (regionId: string) => {
  const LOCAL_STORAGE_CART_ID_KEY = `cartId_${regionId}`
  return {
    get: () => localStorage.getItem(LOCAL_STORAGE_CART_ID_KEY),
    set: (id: string) => localStorage.setItem(LOCAL_STORAGE_CART_ID_KEY, id),
    remove: () => localStorage.removeItem(LOCAL_STORAGE_CART_ID_KEY),
  }
}

export const CartProvider: FC = ({ children }) => {
  const { locale } = useRouter()
  const [cart, setCart] = useState<Cart | null>(null)

  const [miniCartState, setMiniCartState] = useState<{
    isOpen: boolean
    title?: string
  }>({
    isOpen: false,
    title: undefined,
  })

  const [processing, setProcessing] = useState(false)

  const regionId = getLocaleRegionIdFromPath(locale)[0]
  const lsCartId = createLsCartIdService(regionId)

  const cartClient = createCartClient({
    locale,
    cartId: cart?.id || null,
  })

  const __fetchOrCreateCart = async () => {
    setProcessing(true)

    const LSCartId = lsCartId.get()

    const res = await fetchServiceWithRetry<Cart>(
      LSCartId ? () => cartClient.fetch(LSCartId) : () => cartClient.create(),
      5,
    )

    if (res.status === 'ERROR') {
      // we catch cartClient errors of fetchOrCreate without passing res to resolver
      // when function hits the retry limit we show the toast
      toast(
        `We couldn't prepare the cart for You. Please refresh the browser and try again.`,
      )
      const err = new Error(
        `[fetchOrCreateCart] Reached retry limit. ${JSON.stringify(
          res.errors,
        )}`,
      )
      errorDebugMessage(err)
      captureException(err)
      return
    }
    __resolveCartOperation(res)
  }

  const __resolveCartOperation = (cartOperationRes: CartClientResponse) => {
    if (cartOperationRes.status === 'OK' && cartOperationRes.data) {
      // proper cart
      logDebugMessage('[refreshCart] FRESH CART RECEIVED. updating state...')
      setCart(cartOperationRes.data)
      setProcessing(false)
      lsCartId.set(cartOperationRes.data.id)
    }
    if (cartOperationRes.status === 'OK' && !cartOperationRes.data) {
      // empty cart
      logDebugMessage('[refreshCart] EMPTY CART RECEIVED. recreating...')
      setCart(null)
      lsCartId.remove()
      __fetchOrCreateCart()
    }
    if (cartOperationRes.status === 'ERROR') {
      // cartClient returned error
      const err = new Error(JSON.stringify(cartOperationRes.errors))
      errorDebugMessage(err)
      captureException(err)
      toast(
        `Sorry, we couldn't complete your request. Please refresh the browser and try again.`,
      )
    }
  }

  const __revalidateCart = async (fn: () => Promise<CartLineType[] | void>) => {
    setProcessing(true)
    if (!lsCartId.get() || !cart) {
      await __fetchOrCreateCart()
      await fn()
    }
  }

  const __reloadCart = async () => {
    await __fetchOrCreateCart()
  }

  const addCartLines = async (newLines: AddLinesInput) => {
    logDebugMessage('[addCartLines] ADD LINES')
    await __revalidateCart(() => addCartLines(newLines))
    const res = await cartClient.addLines(newLines)

    if (res.status === 'OK' && res.data) {
      const updatedCart = res.data
      const addedLines = newLines.reduce((acc: CartLineType[], line) => {
        const matchedLine = updatedCart?.lines?.find((l) => {
          return (
            l.merchandise.id === line.merchandiseId &&
            equalAttributes(l.attributes, line.attributes)
          )
        })
        return matchedLine ? [...acc, matchedLine] : acc
      }, [])

      __resolveCartOperation(res)

      if (addedLines.length === newLines.length) {
        return addedLines
      } else {
        const err = new Error(
          `[addCartLines] Added lines not found in incoming cart, lines: ${JSON.stringify(
            newLines,
          )}, cart: ${JSON.stringify(updatedCart)}`,
        )
        captureException(err)
        return undefined
      }
    }
    __resolveCartOperation(res)
    return undefined
  }

  const removeCartLines = async (ids: string[]) => {
    logDebugMessage('[removeCartLines] REMOVE LINES')
    await __revalidateCart(() => removeCartLines(ids))
    const res = await cartClient.removeLines(ids)
    __resolveCartOperation(res)
  }

  const updateCartLines = async (lines: UpdateLinesInput) => {
    logDebugMessage('[updateCartLines] UPDATE LINES')
    await __revalidateCart(() => updateCartLines(lines))
    const res = await cartClient.updateLines(lines)
    __resolveCartOperation(res)
  }

  const updateCartNote = async (note: string) => {
    logDebugMessage('[updateCartNote] UPDATE NOTE')
    await __revalidateCart(() => updateCartNote(note))
    const res = await cartClient.updateNote(note)
    __resolveCartOperation(res)
  }

  const openMiniCart = (title?: string) =>
    setMiniCartState({
      title: title,
      isOpen: true,
    })

  const closeMiniCart = () =>
    setMiniCartState({
      isOpen: false,
    })
  //refresh cart on focus
  useEffect(() => {
    __reloadCart()
    window.addEventListener('focus', __reloadCart)
    return () => window.removeEventListener('focus', __reloadCart)
  }, [locale])

  return (
    <CartContext.Provider
      value={{
        cart,
        processing,
        miniCartState,
        addCartLines,
        openMiniCart,
        closeMiniCart,
        removeCartLines,
        updateCartLines,
        updateCartNote,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
