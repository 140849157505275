import { FC } from 'react'
import { SiteConfiguration } from '@magal/models'
import { styled } from '@magal/styles'
import { Tab } from './Tab'

type DrawerProps = {
  header: SiteConfiguration['header']
  activeTabIndex: number | null
}

const Root = styled('div', {
  display: 'none',
  '@lg': {
    display: 'grid',
  },
  position: 'absolute',
  top: '100%',
  marginTop: '-1px',
  width: '100%',
  height: 'auto',
  transition: 'height 100ms ease, opacity 200ms',
  borderBottom: '1px solid',
  borderColor: '$green_09',
  background: 'white',
  variants: {
    visible: {
      true: {},
      false: { opacity: 0, pointerEvents: 'none' },
    },
  },
})

const TabWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  transition: 'opacity 200ms',
  display: 'grid',
  padding: '$24',
  variants: {
    visible: {
      true: { opacity: 1, transition: 'opacity 200ms', zIndex: 1 },
      false: { opacity: 0, transition: 'opacity 200ms' },
    },
  },
})
export const DrawerDesktop: FC<DrawerProps> = ({ header, activeTabIndex }) => {
  const activeTab =
    activeTabIndex && header.tabs ? header.tabs[activeTabIndex] : undefined
  return (
    <Root visible={activeTabIndex !== null && activeTab?.type !== 'link-only'}>
      {header.tabs &&
        header.tabs.map((tab, index: number) => (
          <TabWrap key={`tab-${tab.title}`} visible={index === activeTabIndex}>
            <Tab tab={tab} />
          </TabWrap>
        ))}
    </Root>
  )
}
