import { FC } from 'react'
import { MediaFieldType } from '@magal/models'
import { Media } from '../Media/Media'
import { styled } from '@magal/styles'

const MediaWrap = styled('div', {
  display: 'block',
  width: '100%',
})

export type StandardImageProps = {
  _type: 'standardImage'
  _key: string
  title?: string
  image: MediaFieldType
}

export const StandardImage: FC<StandardImageProps> = ({ image }) => {
  return (
    <MediaWrap>
      <Media {...image} />
    </MediaWrap>
  )
}
