import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionValueAndStyleProjection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        panels[] {
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${RICH_TEXT_PROJECTION},
        },
        button {
          ${coalesceLocaleField('label', localeId)},
          link${LINK_PROJECTION}
        }
      }
  `
}
