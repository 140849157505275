import {
  styled,
  fonts,
  useMediaQuery,
  getFontStyles,
  colors,
  screenSizes,
  ScreenSize,
} from '@magal/styles'

const MatchBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50px',
  width: '50px',
  border: '1px solid lightgrey',
  margin: '2px',
  variants: {
    matches: {
      true: {
        background: 'lightgreen',
      },
      false: {
        background: 'lightgrey',
      },
    },
  },
})

const SmallText = styled('span', {
  projectFont: 'heading03',
})

const ColorBox = styled('div', {
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid lightgrey',
})

const Text = styled('span', {})

const DemoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

const SectionTitle = styled('h2', {
  projectFont: 'heading02',
  margin: '$48 0 $12',
})

const MediaQueriesContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const LinDemo = styled('div', {
  lin: {
    width: ['25px', '500px'],
    height: ['50px', '$48'],
  },
  background: 'red',
  projectFont: 'body01',
  '@md': {
    background: 'blue',
    projectFont: 'heading01',
  },
})

const MediaQueryComponent = ({ screenSize }: { screenSize: ScreenSize }) => {
  const matchedSize = useMediaQuery(screenSize)
  return (
    <MatchBox matches={matchedSize}>
      <SmallText>{screenSize}</SmallText>
    </MatchBox>
  )
}

const Spark = () => <span role="img">✨ </span>
export const ThemeDemo = () => {
  return (
    <>
      <SectionTitle>
        <Spark />
        Fonts
      </SectionTitle>
      <DemoContainer>
        {Object.keys(fonts).map((fontName) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Text key={fontName} css={getFontStyles(fontName)}>
            {fontName}
          </Text>
        ))}
      </DemoContainer>
      <SectionTitle>
        <Spark /> Colors
      </SectionTitle>
      <DemoContainer>
        {Object.keys(colors).map((colorName) => (
          <ColorBox
            key={colorName}
            css={{ backgroundColor: `$${colorName}` }}
          >{`$${colorName}`}</ColorBox>
        ))}
      </DemoContainer>
      <SectionTitle>
        <Spark /> Media Queries
      </SectionTitle>
      <DemoContainer>
        <MediaQueriesContainer>
          {Object.keys(screenSizes).map((screenSize) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <MediaQueryComponent key={screenSize} screenSize={screenSize} />
          ))}
        </MediaQueriesContainer>
      </DemoContainer>
      <SectionTitle>
        <Spark /> Fluid range
      </SectionTitle>
      <LinDemo>Demo text</LinDemo>
    </>
  )
}
