import React from 'react'

export const AccountIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.55 10.1C12.78 10.1 14.6 8.28 14.6 6.05C14.6 3.82 12.78 2 10.55 2C8.32 2 6.5 3.82 6.5 6.05C6.5 8.28 8.32 10.1 10.55 10.1ZM10.55 3.43C11.97 3.43 13.17 4.63 13.17 6.05C13.17 7.47 11.97 8.67 10.55 8.67C9.13 8.67 7.93 7.47 7.93 6.05C7.93 4.63 9.13 3.43 10.55 3.43Z"
        fill="currentColor"
      />
      <path
        d="M17.9455 14.1042C17.3542 13.1771 16.5067 12.4479 15.4423 11.9271C13.5206 11 11.3328 11 10.5049 11C9.6771 11 7.48928 11 5.56754 11.9271C4.50319 12.4479 3.65565 13.1771 3.06435 14.1042C2.35478 15.2083 2 16.6146 2 18.2604C2 18.4688 2.06899 18.6562 2.1971 18.7917C2.32522 18.9271 2.50261 19 2.69971 19H18.3003C18.4974 19 18.6748 18.9271 18.8029 18.7917C18.931 18.6562 19 18.4688 19 18.2604C19 16.6146 18.6354 15.2187 17.9357 14.1042H17.9455ZM3.42899 17.5104C3.51768 16.4479 3.81333 15.5417 4.31594 14.8229C4.76928 14.1562 5.41971 13.625 6.22783 13.2396C7.62725 12.5833 9.25333 12.5 10.5148 12.5C11.7762 12.5 13.3925 12.5833 14.8017 13.2396C15.6099 13.6146 16.2504 14.1562 16.7136 14.8229C17.2064 15.5417 17.502 16.4479 17.5907 17.5104H3.42899Z"
        fill="currentColor"
      />
    </svg>
  )
}
