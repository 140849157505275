import { FC } from 'react'
import Script from 'next/script'

export const KlaviyoPopupsScript: FC = () => {
  return (
    <Script
      src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SxLRQV"
      strategy="lazyOnload"
    />
  )
}
