import { createItem } from './createItem'
import { GTMResetEcommerce } from './gtmResetEcommerce'
import { CartLineType } from '@magal/models'

export const GTMViewCart = (
  amount: string,
  currency: string,
  cartLines?: CartLineType[],
) => {
  GTMResetEcommerce()

  const w = window as any

  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      currency,
      value: parseFloat(amount),
      items: cartLines?.map((cartLine, index) => {
        return createItem({
          productTitle: cartLine.merchandise.product.title,
          currency: cartLine.merchandise.priceV2.currencyCode,
          index,
          variantTitle: cartLine.merchandise.title,
          priceAmount: cartLine.merchandise.priceV2.amount,
          quantity: cartLine.quantity,
          id: cartLine.merchandise.sku,
        })
      }),
    },
  })
}
