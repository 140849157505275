export const Instagram = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5993 2H6.40073C3.98389 2 2 3.98361 2 6.4008V17.589C2 20.0161 3.98389 22 6.40073 21.9997H17.5993C20.0161 21.9997 22 20.0161 22 17.589V6.4008C22 3.98361 20.0161 2 17.5993 2ZM9.0672 18.8071H14.9315C17.0679 18.8071 18.8061 17.0691 18.8061 14.9328V9.06801C18.8061 6.93134 17.0679 5.19336 14.9315 5.19336H9.0672C6.93087 5.19336 5.19261 6.93164 5.19261 9.06801V14.9324C5.19261 17.0691 6.93087 18.8071 9.0672 18.8071ZM6.35937 9.0677C6.35937 7.57475 7.57427 6.36014 9.0672 6.36014L14.9315 6.36045C16.4248 6.36045 17.6394 7.57474 17.6394 9.06801V14.9324C17.6394 16.4254 16.4248 17.6403 14.9315 17.6403H9.0672C7.57396 17.6403 6.35937 16.4254 6.35937 14.9324V9.0677ZM12.0002 15.4861C10.078 15.4861 8.51442 13.9225 8.51442 12.0003C8.51442 10.078 10.078 8.51442 12.0002 8.51442C13.9224 8.51442 15.486 10.0784 15.486 12.0003C15.486 13.9221 13.9221 15.4861 12.0002 15.4861ZM12.0002 9.68151C10.7214 9.68151 9.68118 10.7218 9.68118 12.0003C9.68118 13.2787 10.7214 14.3193 12.0002 14.3193C13.279 14.3193 14.3189 13.279 14.3189 12.0003C14.3189 10.7215 13.2787 9.68151 12.0002 9.68151ZM15.6353 9.15266C16.0979 9.15266 16.4731 8.77772 16.4731 8.31514C16.4731 7.85226 16.0979 7.47701 15.6353 7.47701C15.1727 7.47701 14.7975 7.85257 14.7975 8.31514C14.7975 8.77772 15.1727 9.15266 15.6353 9.15266Z"
    />
  </svg>
)
