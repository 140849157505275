import { Button } from '../Button/Button'
import { keyframes, styled } from '@magal/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ANIMATION_TIME = 7000

const Root = styled('div', {
  display: 'grid',
})

const Slider = styled('div', {
  display: 'grid',
})

const Slide = styled('div', {
  display: 'grid',
  textAlign: 'center',
  gridRow: 1,
  gridColumn: 1,
  transition: 'opacity 400ms',
  variants: {
    isActive: {
      true: {
        opacity: 1,
        zIndex: 9,
      },
      false: {
        opacity: 0,
        zIndex: 1,
      },
    },
  },
})

const ProgressBar = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$4',
  marginTop: '$24',
  paddingLeft: '$36',
  paddingRight: '$36',
  order: 3,
  '@lg': {
    order: 2,
  },
})

const progressAnimation = keyframes({
  '0%': { transform: 'translateX(-100%)' },
  '100%': { transform: 'translateX(0%)' },
})

const Indicator = styled(Button, {
  position: 'relative',
  display: 'block',
  height: '1px',
  paddingTop: '$12',
  paddingBottom: '$12',
  overflow: 'hidden',
  background: 'none',
  '&:before': {
    content: '',
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '1px',
    backgroundColor: '$gray600',
    opacity: 1,
  },
  '&:after': {
    position: 'absolute',
    backgroundColor: '$beige',
    left: 0,
    width: '100%',
    height: '1px',
  },
  variants: {
    theme: {
      dark: {
        '&:before': {
          backgroundColor: '#DBD2C9',
        },
        '&:after': {
          backgroundColor: '$green_09',
        },
      },
    },
    state: {
      loading: {
        '&:after': {
          content: '',
          animation: `${progressAnimation} ${ANIMATION_TIME}ms linear`,
          opacity: 1,
        },
      },
      visited: {
        '&:after': {
          content: '',
          transform: 'translateX(0)',
          transition: `transform 0ms linear`,
          opacity: 1,
        },
      },
      inactive: {
        '&:after': {
          content: '',
          transform: 'translateX(-100%)',
          transition: `transform 0ms linear`,
          opacity: 0,
        },
      },
    },
  },
})

export const ProgressSlider: React.FC<{
  indicatorTheme?: 'dark'
  slides?: JSX.Element[]
}> = ({ slides, indicatorTheme }) => {
  const { t } = useTranslation('progressSlider')
  const [activeReviewIndex, setActiveReviewIndex] = useState(-1)
  const [animationTime, setAnimationTime] = useState(100)

  useEffect(() => {
    const timer = setTimeout(() => {
      //trick for first render
      if (animationTime !== ANIMATION_TIME) {
        setAnimationTime(ANIMATION_TIME)
      }
      if (slides && activeReviewIndex !== slides.length - 1) {
        setActiveReviewIndex(activeReviewIndex + 1)
      } else {
        setActiveReviewIndex(0)
      }
    }, animationTime)
    return () => {
      clearTimeout(timer)
    }
  }, [activeReviewIndex])

  const jumpToSlide = (index: number) => {
    setActiveReviewIndex(index)
  }

  if (!(slides && slides.length > 1)) return null

  return (
    <Root>
      <Slider as={'div'}>
        {slides?.map((slide, index) => {
          return (
            <Slide
              key={index}
              isActive={activeReviewIndex === index}
              aria-label={`${t('slide')} ${index + 1}`}
              aria-hidden={activeReviewIndex !== index}
            >
              {slide}
            </Slide>
          )
        })}
      </Slider>
      <ProgressBar>
        {slides?.map((_, index) => {
          return (
            <Indicator
              key={index}
              theme={indicatorTheme}
              aria-current={activeReviewIndex === index}
              state={
                activeReviewIndex < index
                  ? 'inactive'
                  : activeReviewIndex === index
                  ? 'loading'
                  : 'visited'
              }
              onClick={() => jumpToSlide(index)}
            >
              <span className="sr-only">
                {t('goToSlide')} {index + 1}
              </span>
            </Indicator>
          )
        })}
      </ProgressBar>
    </Root>
  )
}
