import {
  MetafieldRes,
  ServiceResponse,
  ShopifyProductColor,
  ShopifyProductColorsMap,
} from '@magal/models'
import {
  errorDebugMessage,
  fetchServiceWithRetry,
  getLocaleRegionIdFromPath,
} from '@magal/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from '..'
import { getProductRootTitle } from './helpers/getProductRootTitle'
import { captureException } from '@sentry/nextjs'

export type ShopifyProductMaterialRes = {
  title: string
  handle: string
  metafields: MetafieldRes[]
}

type RelatedProductsQueryRes = {
  products: {
    nodes?: ShopifyProductMaterialRes[]
    pageInfo?: { hasNextPage: boolean; endCursor: string }
  }
}

const RELATED_PRODUCTS_QUERY = (endCursor?: string) => gql`
  fragment ProductColorFragment on Product {
    title
    handle
    metafields(
      identifiers: [
        { namespace: "product", key: "color" }
      ]
    ) {
      key
      namespace
      value
    }
  }
  query ($regionId: CountryCode!, $relatedProductsQuery: String!)
  @inContext(country: $regionId) {
    products(first: 250, query: $relatedProductsQuery, ${
      endCursor ? `after: "${endCursor}"` : ''
    }) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...ProductColorFragment
      }
    }
  }
`

/**
 * Why?
 * We use product titles convention to group products by colors.
 * example: product can be spit into 3 colors by title: "Shirt - Red" "Shirt - Blue" "Shirt - Green"
 *
 * How?
 * Based on a list of products Ids we fetch all products that have the same name prefix
 * example: based on a product "Shirt - Red" get all other products that start with "Shirt - *"
 *
 * Recursion
 * Since we are limited to 250 results we need to paginate through all products
 */
const fetchRelatedProducts = async (
  productTitles: string[],
  locale?: string,
  endCursor?: string,
  prevMap?: ShopifyProductColorsMap,
): Promise<ServiceResponse<ShopifyProductColorsMap>> => {
  try {
    const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
    const shopifyClient = createShopifyClient(localeId)

    const allProductsTitlePatterns = productTitles.map(
      (title) => `${getProductRootTitle(title)} - *`,
    )

    const uniqueProductTitlePatterns = [...new Set(allProductsTitlePatterns)]

    if (uniqueProductTitlePatterns.length === 0) {
      return {
        status: 'OK',
        data: {},
      }
    }

    const relatedProductsQuery = uniqueProductTitlePatterns
      .map((title) => `(title:${title})`)
      .join(' OR ')

    const res = await shopifyClient.request<RelatedProductsQueryRes>(
      RELATED_PRODUCTS_QUERY(endCursor),
      {
        regionId,
        relatedProductsQuery,
      },
    )

    const productColorsMap: ShopifyProductColorsMap =
      res?.products?.nodes?.reduce(
        (acc, val) => {
          const stripedTitle = getProductRootTitle(val.title)
          const id = val.metafields.find((m) => m?.key === 'color')?.value
          if (!id) return acc
          const productColor: ShopifyProductColor = {
            handle: val.handle,
            id,
          }
          if (acc[stripedTitle]) {
            acc[stripedTitle].push(productColor)
          } else {
            acc[stripedTitle] = [productColor]
          }
          return acc
        },
        { ...(prevMap || {}) },
      ) ?? {}

    const hasNextPage = res.products.pageInfo?.hasNextPage
    const newEndCursor = res.products.pageInfo?.endCursor

    if (hasNextPage) {
      return fetchRelatedProducts(
        productTitles,
        locale,
        newEndCursor,
        productColorsMap,
      )
    }

    return { status: 'OK', data: productColorsMap }
  } catch (e) {
    return { status: 'ERROR', errors: [e] }
  }
}

export const getShopifyProductColorsMap2 = async (
  productTitles: string[],
  locale?: string,
): Promise<ShopifyProductColorsMap> => {
  const res = await fetchServiceWithRetry(
    () => fetchRelatedProducts(productTitles, locale),
    5,
  )

  if (res.status === 'OK') {
    return res.data ?? {}
  } else {
    const err = new Error(JSON.stringify(res.errors))
    captureException(err)
    errorDebugMessage(err)
    return {}
  }
}
