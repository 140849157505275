import { useToaster } from 'react-hot-toast'
import React from 'react'
import { styled, useMediaQuery } from '@magal/styles'

const Root = styled('div', {
  position: 'fixed',
  left: '$containerMarginMobile',
  right: '$containerMarginMobile',
  display: 'grid',
  justifyItems: 'center',
  zIndex: 9999,
  variants: {
    position: {
      top: {
        top: '$containerMarginMobile',
      },
      bottom: {
        bottom: '$96',
      },
    },
  },
})

const Toast = styled('div', {
  projectFont: 'body02',
  textAlign: 'center',
  background: '#414141',
  color: 'white',
  width: '$240',
  padding: '$8 $12',
  display: 'grid',
  placeItems: 'center',
  borderRadius: '$r2',
  boxShadow: '0px 7px 15px rgba(0,0,0,0.3)',
  '@lg': {
    maxWidth: '$378',
  },
})

export const Toaster = () => {
  const {
    toasts,
    handlers: { startPause, endPause, calculateOffset, updateHeight },
  } = useToaster()

  const matchesMd = useMediaQuery('md')

  return (
    <Root
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      position={matchesMd ? 'bottom' : 'top'}
    >
      {toasts.map((toast) => {
        const offset = calculateOffset(toast, {
          reverseOrder: true,
          gutter: 8,
          defaultPosition: matchesMd ? 'bottom-center' : 'top-center',
        })
        const ref = (el: HTMLDivElement) => {
          if (el && typeof toast.height !== 'number') {
            const height = matchesMd
              ? -el.getBoundingClientRect().height - 12
              : el.getBoundingClientRect().height
            updateHeight(toast.id, height)
          }
        }
        // const show = keyframes({
        //   '0%': { transform: `scale(0.6) translateY(${offset - 30}px)` },
        //   '100%': { transform: `scale(1) translateY(${offset}px)` },
        // })
        // const hide = keyframes({
        //   '0%': { opacity: 1 },
        //   '100%': { opacity: 0 },
        // })
        return (
          <Toast
            key={toast.id}
            ref={ref}
            style={{
              position: 'absolute',
              transition: 'all 0.4s cubic-bezier(.2,.4,.2,1)',
              opacity: toast.visible ? 1 : 0,
              // animation: toast.visible
              //   ? `${show} 200ms cubic-bezier(.2,.4,.2,1)`
              //   : `${hide} 200ms ease`,
              transform: `translateY(${offset}px)`,
            }}
            {...toast.ariaProps}
          >
            {toast.message}
          </Toast>
        )
      })}
    </Root>
  )
}
