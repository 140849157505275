import { LocaleId } from '@magal/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { sanityImageProjection } from './createMediaProjection'

export const createSEOProjection = (localeId: LocaleId) => {
  return groq`{
    ${coalesceLocaleField('metaTitle', localeId)},
    ${coalesceLocaleField('metaDescription', localeId)},
    ${coalesceLocaleField('ogTitle', localeId)},
    ${coalesceLocaleField('ogDescription', localeId)},
    ${coalesceLocaleField('ogAltImage', localeId)},
    "ogImage": {
      "mediaPayload": {
        "type": "sanity-image",
        "image": ${sanityImageProjection('ogImage')}
      }
    }
  }`
}
