import { ShopifyProduct, ShopifyProductVariant } from '@magal/models'

declare global {
  interface Window {
    attentive: {
      analytics: Record<string, (item: Item) => void>
    }
    __attentive: {
      trigger: (a: null, b: null, c: null, id: number) => void
    }
  }
}

interface Item {
  items: Array<{
    productId: string
    productVariantId: string
    name: string
    productImage?: string | null
    category: string
    price: {
      value: string
      currency: string
    }
    quantity: number
  }>
}

interface Product {
  productId: string
  productVariantId: string
  name: string
  productImage?: string | null
  category?: string
  value: string
  currency?: string
  quantity?: number
}

export const AttentiveAddToCart = (product: Product): void => {
  const {
    productId,
    productVariantId,
    name,
    productImage,
    category = '',
    value,
    currency = 'USD',
    quantity = 1,
  } = product

  if (!window.attentive) return

  const item: Item = {
    items: [
      {
        productId,
        productVariantId,
        name,
        productImage,
        category,
        price: {
          value,
          currency,
        },
        quantity,
      },
    ],
  }
  window.attentive.analytics.addToCart(item)
}

export const AttentiveProductView = (
  product: ShopifyProduct,
  productVariant: ShopifyProductVariant,
): void => {
  const debounce = setTimeout(() => {
    if (window.attentive) {
      clearTimeout(debounce)
      const item: Item = {
        items: [
          {
            productId: product.id,
            productVariantId: productVariant?.sku,
            name: product?.title,
            productImage: product.featuredImage?.image.src,
            category: product?.productType,
            price: {
              value: productVariant?.priceV2.amount,
              currency: 'USD',
            },
            quantity: 1,
          },
        ],
      }
      window.attentive.analytics.productView(item)
    }
  }, 3000)
}
