import { FC, ReactElement, useState } from 'react'
import { AccordionStateless, AccordionHeaderProps } from './AccordionStateless'

type AccordionStatefulProps = {
  transitionTime?: number
  accordionId: string
  header: (headerElementProps: AccordionHeaderProps) => ReactElement
  isInitiallyOpen?: boolean
}

export const AccordionStateful: FC<AccordionStatefulProps> = ({
  isInitiallyOpen = false,
  ...restProps
}) => {
  const [isOpen, setOpen] = useState(isInitiallyOpen)

  return (
    <AccordionStateless
      isOpen={isOpen}
      onRequestOpen={() => setOpen(true)}
      onRequestClose={() => setOpen(false)}
      {...restProps}
    />
  )
}
