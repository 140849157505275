import { FC, useEffect, useState } from 'react'
import { SiteConfiguration } from '@magal/models'
import { styled } from '@magal/styles'
import { Hamburger } from '@magal/icons'
import { Button, Link, LogoLink, Modal } from '@magal/components'
import { DrawerDesktop } from './components/DrawerDesktop'
import { DrawerMobile } from './components/DrawerMobile'
import { useRouter } from 'next/router'
import { CartButton } from '@magal/cart'
import { AccountButton } from '@magal/account'
import { SearchButton } from '@magal/search'

export type HeaderProps = {
  headerConfig: SiteConfiguration['header']
  searchConfig: SiteConfiguration['search']
}

const Root = styled('div', {
  position: 'relative',
  height: '$headerHeightMobile',
  borderBottom: '1px solid',
  borderColor: '$green_09',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  background: 'white',
  '@md': {
    padding: '0 $containerMarginDesktop',
  },
  '@lg': {
    height: '$headerHeightDesktop',
    alignItems: 'center',
  },
})

const Nav = styled('div', {
  display: 'grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  gridGap: '$28',
})

const NavLink = styled(Link, {
  display: 'none',
  projectFont: 'body01',
  lineHeight: 1,
  color: '$green_09',
  '@lg': {
    display: 'inline-flex',
  },
  variants: {
    active: {
      true: {
        textDecoration: 'underline',
      },
    },
  },
})
const NavButton = styled(Button, {
  projectFont: 'body01',
  lineHeight: 1,
  color: '$green_09',
  variants: {
    active: {
      true: {
        textDecoration: 'underline',
      },
    },
  },
  '@lg': {
    display: 'inline-flex',
  },
})
const Utils = styled('div', {
  display: 'grid',
  //gap: '$4',
  gridAutoFlow: 'column',
  justifyContent: 'end',
  '@lg': {
    gap: '$8',
    alignItems: 'center',
    height: '100%',
  },
})

const BurgerButton = styled(Button, {
  display: 'grid',
  alignItems: 'center',
  padding: '0 $16',
  '@lg': {
    display: 'none',
  },
})

export const Header: FC<HeaderProps> = ({ headerConfig, searchConfig }) => {
  const router = useRouter()
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null)
  const [isDrawerMobileOpen, setDrawerMobileOpen] = useState(false)

  const openDrawerTab = (index: number) => {
    setActiveTabIndex(index)
  }
  const closeDrawer = () => {
    setActiveTabIndex(null)
  }
  const closeMobileDrawer = () => setDrawerMobileOpen(false)
  const openMobileDrawer = () => setDrawerMobileOpen(true)

  useEffect(() => {
    const handleRouteChange = () => {
      closeDrawer()
      closeMobileDrawer()
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  return (
    <Root onMouseLeave={closeDrawer}>
      <Nav>
        <LogoLink type={'header'} />
        {headerConfig.tabs?.map((tab, i) => {
          return tab.link ? (
            <NavLink
              key={tab.title}
              {...tab.link}
              onMouseEnter={() => openDrawerTab(i)}
              onFocus={() => openDrawerTab(i)}
              active={activeTabIndex === i}
            >
              {tab.title}
            </NavLink>
          ) : (
            <NavButton
              key={tab.title}
              active={activeTabIndex === i}
              onMouseEnter={() => openDrawerTab(i)}
              onFocus={() => openDrawerTab(i)}
            >
              {tab.title}
            </NavButton>
          )
        })}
      </Nav>
      <Utils>
        <SearchButton searchConfig={searchConfig} />
        <AccountButton />
        <CartButton />
        <BurgerButton onClick={openMobileDrawer}>
          <Hamburger />
        </BurgerButton>
      </Utils>
      <DrawerDesktop header={headerConfig} activeTabIndex={activeTabIndex} />
      <Modal
        isOpen={isDrawerMobileOpen}
        onRequestClose={closeMobileDrawer}
        size={'stretch'}
        transition={'slide-from-right'}
      >
        <DrawerMobile header={headerConfig} onClose={closeMobileDrawer} />
      </Modal>
    </Root>
  )
}
