import { FC, useMemo, useState, Dispatch, SetStateAction } from 'react'
import { getProductRootTitle } from '@magal/services/shopify-service'
import {
  CART_LINE_QUANTITY_CAP,
  PACKAGING_ATTRIBUTE_KEY,
  UPSELL_ATTRIBUTE_KEY,
  UPSELL_ORIGINAL_ATTRIBUTE_KEY,
  ROUTE_BASE_PRODUCTS,
} from '@magal/configs'
import { styled } from '@magal/styles'
import { CartLineType } from '@magal/models'
import { CircleMinus, CirclePlus } from '@magal/icons'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button/Button'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { ProductVariantPrice } from '../Price/ProductVariantPrice'

import debounce from 'lodash/debounce'
import { GTMAddToCart, GTMRemoveFromCart } from '@magal/services/gtm-service'
import { useProductsSettings } from '@magal/product'

const Root = styled('article', {
  display: 'grid',
  gap: '$16',
  gridTemplateColumns: '116px 1fr',
  color: '$green_09',
  '@sm': {
    gridTemplateColumns: '174px 1fr',
    gap: '$28',
  },
  variants: {
    isGreyOut: {
      true: {
        opacity: 0.7,
        pointerEvents: 'none',
      },
    },
  },
})

const MediaWrap = styled('div', {
  background: '$gray300',
})

const DetailsWrap = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto auto 1fr',
  gap: '$20',
})

const TitleWrap = styled('div', {
  display: 'grid',
  gap: '$4',
})

const ProductColor = styled('span', {
  display: 'block',
  projectFont: 'caps07',
})

const Title = styled('h2', {
  projectFont: 'body01',
})

const PriceWrap = styled('div', {
  projectFont: 'heading04Medium',
  alignSelf: 'start',
})

const OptionsAndAttributesWrap = styled('ul', {
  display: 'grid',
  rowGap: '$4',
  projectFont: 'body02',
  placeItems: 'start',
})

const ProductDetail = styled('li', {
  projectFont: 'caps07',
})

const ProductDetailLower = styled('li', {
  projectFont: 'body07',
  display: 'inline',
})

const ShowAllButton = styled(Button, {
  projectFont: 'caps07',
  color: '$green_09',
  textDecoration: 'underline',
})

const ActionsWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'auto',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '$32',
  alignSelf: 'end',
})

const QuantityWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  gap: '$4',
  color: '$green09',
  marginLeft: '-$8',
})

const QuantityButton = styled(Button, {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$green09',
  padding: '$8',
  '&:disabled': {
    opacity: '0.5',
  },
})

const Quantity = styled('span', {
  minWidth: '$16',
  projectFont: 'body01',
  textAlign: 'center',
})

const RemoveButton = styled(Button, {
  projectFont: 'body04',
  textDecoration: 'underline',
  color: '$green09',
  padding: '$8',
})

const Packaging = styled('div', {
  projectFont: 'caps07',
})

const getMaxQuantity = ({
  merchandise: { quantityAvailable, availableForSale },
  quantity,
}: CartLineType) => {
  const allowSellOutOfStock = availableForSale
  const productsQuantity =
    quantity > quantityAvailable ? quantity : quantityAvailable
  return allowSellOutOfStock ? CART_LINE_QUANTITY_CAP : productsQuantity
}

type CartLineProps = {
  className?: string
  line: CartLineType
  isEditable?: boolean
  handleRemoveCartLine?: () => void
  handleUpdateCartLine?: (newQuantity: number) => void
  setCartModalOpen?: Dispatch<SetStateAction<boolean>>
}

export const CartLine: FC<CartLineProps> = ({
  line,
  isEditable = true,
  handleRemoveCartLine,
  handleUpdateCartLine,
}) => {
  const { t } = useTranslation('cart')
  const { colorsConfig } = useProductsSettings()
  const {
    merchandise: {
      product: { title, handle, color },
      image,
    },
    cost,
  } = line

  const [attributesExpanded, setAttributesExpanded] = useState(false)
  const [quantity, setQuantity] = useState(line.quantity)
  const [isDisabled, setIsDisabled] = useState(false)
  const maxQuantity = getMaxQuantity(line)

  const gtmQuantityChange = (line: CartLineType, newQuantity: number) => {
    // Removing from cart
    if (line.quantity - newQuantity >= 0) {
      GTMRemoveFromCart(line, line.quantity - newQuantity)
      return
    }
    // Adding to cart
    GTMAddToCart(line, newQuantity - line.quantity)
  }

  const debouncedUpdate = useMemo(
    () =>
      debounce((newQuantity: number) => {
        if (handleUpdateCartLine) {
          handleUpdateCartLine(Number(newQuantity))
          setQuantity(newQuantity)
          gtmQuantityChange(line, newQuantity)
        }
      }, 600),
    [],
  )

  const handleQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity)
    debouncedUpdate(newQuantity)
  }

  const handleLineItemRemove = () => {
    if (handleRemoveCartLine) {
      setIsDisabled(true)
      handleRemoveCartLine()
    }
  }

  const computedAttributes: [string, string][] = (() => {
    // I'm doing map just to avoid duplicates
    const resultFromSelectedOptions = line.merchandise.selectedOptions.reduce(
      (acc, val) => {
        if (val.name.startsWith('_')) return acc
        if (val.name === 'Title') return acc
        return {
          ...acc,
          [val.name]: val.value,
        }
      },
      {},
    )
    const resultFromSelectedAttributes = line.attributes?.reduce((acc, val) => {
      if (val.key === PACKAGING_ATTRIBUTE_KEY) return acc
      if (val.key.startsWith('_')) return acc
      return {
        ...acc,
        [val.key]: val.value,
      }
    }, {})

    return Object.entries({
      ...resultFromSelectedAttributes,
      ...resultFromSelectedOptions,
    })
  })()

  const findAttribute = (keyToFind: string) =>
    line.attributes?.find((a) => a.key === keyToFind)?.value ?? undefined

  const packaging = findAttribute(PACKAGING_ATTRIBUTE_KEY)
  const isUpsell = findAttribute(UPSELL_ATTRIBUTE_KEY)
  const hasOriginalUrl =
    (isUpsell && findAttribute(UPSELL_ORIGINAL_ATTRIBUTE_KEY)) || null

  return (
    <Root key={line.id} isGreyOut={isDisabled}>
      <MediaWrap>
        {isUpsell && !hasOriginalUrl ? (
          image && (
            <Media
              mediaPayload={image}
              sizes={'(min-width: 430px) 174px, 116px'}
              hardcropRatio="portrait2"
            />
          )
        ) : (
          <Link
            href={`${ROUTE_BASE_PRODUCTS}/${
              isUpsell && hasOriginalUrl ? hasOriginalUrl : handle
            }`}
          >
            {image && (
              <Media
                mediaPayload={image}
                sizes={'(min-width: 430px) 174px, 116px'}
                hardcropRatio="portrait2"
              />
            )}
          </Link>
        )}
      </MediaWrap>
      <DetailsWrap>
        <TitleWrap>
          {isUpsell ? (
            <>
              {colorsConfig && colorsConfig[color] && (
                <ProductColor>{colorsConfig[color]?.label}</ProductColor>
              )}
              <Title>{getProductRootTitle(title)}</Title>
            </>
          ) : (
            <Link href={`${ROUTE_BASE_PRODUCTS}/${handle}`}>
              {colorsConfig && colorsConfig[color] && (
                <ProductColor>{colorsConfig[color]?.label}</ProductColor>
              )}
              <Title>{getProductRootTitle(title)}</Title>
            </Link>
          )}
        </TitleWrap>

        <PriceWrap>
          <ProductVariantPrice
            price={cost.totalAmount}
            noZeros={true}
            noDiscount={true}
            mockDiscount={!isUpsell}
          />
        </PriceWrap>

        <OptionsAndAttributesWrap>
          {computedAttributes.map((a, i) => {
            if (!attributesExpanded && i > 1) {
              return null
            } else {
              return (
                <ProductDetail key={a[0]}>
                  {a[0]} -{' '}
                  {a[0].toLowerCase().includes('grav') ? (
                    <ProductDetailLower>{a[1]}</ProductDetailLower>
                  ) : (
                    a[1]
                  )}
                </ProductDetail>
              )
            }
          })}
          {!attributesExpanded && computedAttributes.length > 2 && (
            <ShowAllButton onClick={() => setAttributesExpanded(true)}>
              {t('showAll')}
            </ShowAllButton>
          )}
        </OptionsAndAttributesWrap>

        {packaging && (
          <Packaging>
            {PACKAGING_ATTRIBUTE_KEY}: {packaging}
          </Packaging>
        )}

        <ActionsWrap>
          {!isUpsell && (
            <QuantityWrap>
              {isEditable && (
                <QuantityButton
                  onClick={() =>
                    handleQuantityChange(quantity > 0 ? quantity - 1 : 0)
                  }
                >
                  <CircleMinus />
                  <span className="sr-only">{t('decreaseQuantity')}</span>
                </QuantityButton>
              )}

              <Quantity>{quantity}</Quantity>

              {isEditable && (
                <QuantityButton
                  disabled={quantity + 1 > maxQuantity}
                  onClick={() =>
                    quantity + 1 <= maxQuantity
                      ? handleQuantityChange(quantity + 1)
                      : null
                  }
                >
                  <CirclePlus />
                  <span className="sr-only">{t('increaseQuantity')}</span>
                </QuantityButton>
              )}
            </QuantityWrap>
          )}

          {isEditable && (
            <RemoveButton onClick={handleLineItemRemove}>
              {t('remove')}
            </RemoveButton>
          )}
        </ActionsWrap>
      </DetailsWrap>
    </Root>
  )
}
