import { FC } from 'react'
import { ErrorPageData } from '@magal/models'
import { styled } from '@magal/styles'
import { BlockContent } from '../BlockContent/BlockContent'
import { Link } from '../Link/Link'
import { ChevronLeft } from '@magal/icons'

const Root = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$green_09',
  minHeight: 'calc(100vh - $headerHeightMobile - $promoBarHeightMobile)',
  '@lg': {
    minHeight: 'calc(100vh - $headerHeightDesktop - $promoBarHeightDesktop)',
  },
})
const Content = styled('div', {
  display: 'grid',
  gap: '$32',
  textAlign: 'center',
  maxWidth: '$maxWidthS',
})
const TextWrapper = styled('div', {
  display: 'grid',
  gap: '$12',
})
const Title = styled('h1', {
  projectFont: 'heading01',
  color: '$green',
})
const Description = styled('div', {
  projectFont: 'body01',
})

const StyledLink = styled(Link, {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  justifyContent: 'center',
  alignItems: 'center',
})

export type ErrorPageProps = {
  pageData: ErrorPageData
  initialTitle: string
  initialDescription: string
  initialButtonLabel: string
}

export const ErrorPage: FC<ErrorPageProps> = ({
  pageData,
  initialTitle,
  initialDescription,
  initialButtonLabel,
}) => {
  return (
    <Root>
      <Content>
        <TextWrapper>
          <Title>{pageData.title ? pageData.title : initialTitle}</Title>
          <Description>
            {pageData.description ? (
              <BlockContent body={pageData.description} />
            ) : (
              initialDescription
            )}
          </Description>
        </TextWrapper>
        <StyledLink
          size={'medium'}
          appearance={'textLinkLite'}
          href={pageData.ctaLink?.href ? pageData.ctaLink.href : '/'}
        >
          <ChevronLeft />
          {pageData.ctaLabel ? pageData.ctaLabel : initialButtonLabel}
        </StyledLink>
      </Content>
    </Root>
  )
}
