import { styled } from '@magal/styles'
import { useState } from 'react'
import { Button } from '../Button/Button'
import { Modal } from '../Modal/Modal'

const ExampleModalWrap = styled('div', {})
const ExampleModalContent = styled('div', {
  display: 'grid',
  placeItems: 'center',
  minWidth: '50vw',
  minHeight: '50vh',
})

export const ExampleModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen((prevIsOpen) => !prevIsOpen)

  return (
    <ExampleModalWrap>
      <Button onClick={toggleModal}>Open example modal</Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        variant="white-rounded"
      >
        <ExampleModalContent>I'm example Modal</ExampleModalContent>
      </Modal>
    </ExampleModalWrap>
  )
}
