import { FC } from 'react'
import { styled } from '@magal/styles'
import { useTranslation } from 'react-i18next'

import { RecoverPasswordForm } from '../forms/RecoverPasswordForm'
import { ROUTE_ACCOUNT_LOGIN } from '@magal/configs'
import { BackButton } from './components/BackButton'
import { Container, Link, SectionWrap } from '@magal/components'
import { FormWrapper } from '../components/FormWrapper'

const Root = styled(Container, {
  padding: '$48 0 $64',
  maxWidth: '$maxWidthS',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
})

const StyledLink = styled(Link, {
  projectFont: 'body01',
})

export const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation('account')

  return (
    <SectionWrap type={'MARGINS_S'}>
      <Root>
        <FormWrapper
          title={t('forgotPasswordPage.heading')}
          description={t('forgotPasswordPage.text')}
        >
          <RecoverPasswordForm />
          <StyledLink href={ROUTE_ACCOUNT_LOGIN}>
            <BackButton>{t('forgotPasswordPage.cancelButtonLabel')}</BackButton>
          </StyledLink>
        </FormWrapper>
      </Root>
    </SectionWrap>
  )
}
