const TERMINA_REGULAR = {
  fontFamily: 'termina-m, Helvetica, sans-serif',
  fontWeight: 400,
}
const TERMINA_MEDIUM = {
  fontFamily: 'termina-m, Helvetica, sans-serif',
  fontWeight: 500,
}
const TERMINA_DEMI = {
  fontFamily: 'termina-m, Helvetica, sans-serif',
  fontWeight: 600,
}
const FORMA_REGULAR = {
  fontFamily: 'FormaDJRMicro, Helvetica, sans-serif',
  fontWeight: 400,
}
const FORMA_BOLD = {
  fontFamily: 'FormaDJRMicro, Helvetica, sans-serif',
  fontWeight: 600,
}
const FORMA_MEDIUM = {
  fontFamily: 'FormaDJRMicro, Helvetica, sans-serif',
  fontWeight: 500,
}
const AMALFI_REGULAR = {
  fontFamily: 'amalfi, Helvetica, sans-serif',
  fontWeight: 400,
}

export const fonts = {
  heading01: {
    lin: {
      fontSize: [30, 50],
    },
    lineHeight: 1,
    overflowWrap: 'break-word',
    ...TERMINA_REGULAR,
  },
  heading02: {
    lin: {
      fontSize: [20, 24],
    },
    lineHeight: 1.2,
    ...TERMINA_REGULAR,
  },
  heading03: {
    fontSize: 16,
    lineHeight: 1,
    ...TERMINA_DEMI,
  },
  heading04: {
    fontSize: 16,
    lineHeight: 1,
    ...TERMINA_REGULAR,
  },
  heading04Medium: {
    fontSize: 16,
    lineHeight: 1.2,
    ...TERMINA_MEDIUM,
  },
  heading05: {
    fontSize: 24,
    lineHeight: 1,
    ...TERMINA_DEMI,
  },
  heading06: {
    fontSize: 12,
    lineHeight: 1,
    ...TERMINA_DEMI,
  },
  caps01: {
    lin: {
      fontSize: [30, 50],
    },
    lineHeight: 1,
    overflowWrap: 'break-word',
    textTransform: 'uppercase',
    ...TERMINA_REGULAR,
  },
  caps02: {
    lin: {
      fontSize: [20, 24],
    },
    lineHeight: 1.2,
    textTransform: 'uppercase',
    ...TERMINA_REGULAR,
  },
  caps03: {
    fontSize: 16,
    lineHeight: 1,
    textTransform: 'uppercase',
    ...TERMINA_DEMI,
  },
  caps04: {
    fontSize: 16,
    lineHeight: 1,
    textTransform: 'uppercase',
    ...TERMINA_REGULAR,
  },
  caps05: {
    fontSize: 16,
    lineHeight: 1,
    textTransform: 'uppercase',
    ...TERMINA_MEDIUM,
  },
  priceSmall: {
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'uppercase',
    ...TERMINA_MEDIUM,
  },
  caps06: {
    fontSize: 14,
    lineHeight: 1.43,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    ...FORMA_REGULAR,
  },
  caps07: {
    fontSize: 10,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    ...FORMA_MEDIUM,
  },
  caps08: {
    fontSize: 12,
    lineHeight: 1,
    textTransform: 'uppercase',
    ...TERMINA_DEMI,
  },
  caps09: {
    fontSize: 13,
    lineHeight: 1,
    textTransform: 'uppercase',
    ...TERMINA_DEMI,
  },
  caps10: {
    lin: {
      fontSize: 16,
    },
    lineHeight: 1.2,
    textTransform: 'uppercase',
    ...TERMINA_REGULAR,
  },
  caps11: {
    lin: {
      fontSize: 15,
    },
    lineHeight: 1.2,
    textTransform: 'uppercase',
    ...TERMINA_MEDIUM,
  },
  body01: {
    fontSize: 16,
    lineHeight: 1.5,
    ...FORMA_REGULAR,
  },
  body01Medium: {
    fontSize: 16,
    lineHeight: 1.5,
    ...FORMA_MEDIUM,
  },
  body02: {
    fontSize: 15,
    lineHeight: 1.2,
    ...FORMA_REGULAR,
  },
  body03: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.05em',
    ...FORMA_REGULAR,
  },
  body04: {
    fontSize: 13,
    lineHeight: 1.2,
    ...FORMA_REGULAR,
  },
  body05: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.05em',
    ...FORMA_BOLD,
  },
  body06: {
    fontSize: 9,
    lineHeight: 1,
    letterSpacing: '0.05em',
    ...FORMA_BOLD,
  },
  body07: {
    fontSize: 10,
    lineHeight: 1.2,
    letterSpacing: '0.05em',
    textTransform: 'initial',
    ...FORMA_MEDIUM,
  },
  amalfi01: {
    fontSize: 30,
    lineHeight: 2,
    ...AMALFI_REGULAR,
  },
}

export type FontType = keyof typeof fonts

export const getFontStyles = (fontType: FontType) => fonts[fontType]
