import { forwardRef } from 'react'
import { captureException, withScope } from '@sentry/nextjs'
import {
  FormTextField,
  SubmitButton,
  useFormSubmitState,
  validateEmail,
  validateRequired,
} from '@magal/forms'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { newsletterSubscribe } from '@magal/klaviyo'
import { Form } from '@magal/account'
import { styled } from '@magal/styles'

type FormValues = {
  email?: string
}

const defaultValues: FormValues = {
  email: '',
}

type FormProps = {
  buttonAppearance?: 'solidGreen' | 'outlineGreen' | 'outlineWhite'
  appearance?: 'onRed'
}

const StyledSubmitButton = styled(SubmitButton, {
  variants: {
    invalid: {
      true: {
        border: '1px solid $white',
        color: '$white',
        '&:hover, &:focus, &:focus-within': {
          textDecorationColor: '$white',
        },
      },
    },
  },
})

export const NewsletterForm = forwardRef<FormProps, HTMLFormElement>(
  ({ buttonAppearance, appearance }) => {
    const { t } = useTranslation('newsletter')
    const form = useForm<FormValues>({
      defaultValues,
    })

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
      clearErrors,
    } = form

    const {
      invalid,
      success,
      setSuccess,
      submitMessage,
      setError,
      setFieldError,
    } = useFormSubmitState<FormValues>({ form, defaultValues })

    const onSubmitSuccess = () => {
      return
    }

    const onSubmit = async (data: FormValues) => {
      const { email = '' } = data

      clearErrors()

      const subscribeRes = await newsletterSubscribe({
        email,
      }).catch((error: Error) => {
        withScope((scope) => {
          scope.setTransactionName('Newsletter Form - Subscription failed')
          captureException(error)
        })

        setError(t('error.general'))

        return undefined
      })

      if (!subscribeRes?.ok) {
        return
      }

      const subscribeJson = await subscribeRes
        .json()
        .catch((error: Error): undefined => {
          withScope((scope) => {
            scope.setTransactionName('Newsletter Form - Subscription failed')
            captureException(error)
          })

          setError(t('error.general'))

          return undefined
        })

      if (!subscribeJson) {
        return
      }

      if (subscribeJson.error === 'EMAIL_EXISTS') {
        setFieldError('email', t('error.alreadyExist'))
        return
      }

      if (!subscribeJson.length) {
        setSuccess(t('successCheckEmail'))
        return
      }

      setSuccess(t('success'))
      return onSubmitSuccess && onSubmitSuccess()
    }

    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={'newsletterForm'}
        name="reset-password-form"
      >
        <FormTextField
          control={control}
          rules={{ ...validateRequired(t), ...validateEmail(t) }}
          autoComplete="email"
          type="email"
          name="email"
          label={t('emailFieldLabel')}
          appearance={appearance}
        />
        <StyledSubmitButton
          text={t('subscribe')}
          helpText={submitMessage}
          invalid={invalid}
          success={success}
          loading={isSubmitting}
          appearance={buttonAppearance}
        />
      </Form>
    )
  },
)
