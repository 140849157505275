import { Logo, LogoLetter } from '@magal/icons'
import { styled } from '@magal/styles'
import { FC } from 'react'
import { Link } from './Link/Link'

const Root = styled(Link, {
  display: 'grid',
  alignItems: 'center',
  '.hideOnMobile': {
    display: 'none',
    '@lg': {
      display: 'block',
    },
  },
  '.hideOnDesktop': {
    '@lg': {
      display: 'none',
    },
  },
  variants: {
    type: {
      footer: {
        svg: {
          width: '60px',
          '@lg': {
            width: '262px',
          },
        },
      },
      header: {
        padding: '0 $16',
        svg: {
          width: '48px',
        },
        '@lg': {
          padding: '0',
          svg: {
            width: '98px',
          },
        },
      },
    },
  },
})

export const LogoLink: FC<{ type: 'footer' | 'header' }> = ({ type }) => (
  <Root href={'/'} type={type}>
    <div className="hideOnMobile">
      <Logo />
    </div>
    <div className="hideOnDesktop">
      <LogoLetter />
    </div>
  </Root>
)
