import { useEffect } from 'react'
import { useCustomerContext } from './customerContext'
import { useRouter } from 'next/router'
import { ROUTE_BASE_ACCOUNT, ROUTE_ACCOUNT_LOGIN } from '@magal/configs'

export const useAuthRedirect = () => {
  const { isLoggedIn, isVerified } = useCustomerContext()
  const router = useRouter()

  useEffect(() => {
    if (isVerified && !isLoggedIn) {
      router.push(ROUTE_ACCOUNT_LOGIN)
      return
    }
    if (isVerified && isLoggedIn) {
      router.push(ROUTE_BASE_ACCOUNT)
      return
    }
  }, [isVerified, isLoggedIn])
}
