import { useState, FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Media } from '../Media/Media'
import { styled } from '@magal/styles'
import { MediaFieldType } from '@magal/models'
import { Modal } from '../Modal/Modal'

const ModalWrap = styled('div', {})
const ModalTitle = styled('p', {
  projectFont: 'caps04',
  fontWeight: 500,
  fontSize: 22,
  padding: 20,
  textAlign: 'center',
})
const ModalText = styled('p', {
  projectFont: 'body01',
  textAlign: 'center',
  marginBottom: 25,
})
const ModalAction = styled('a', {
  projectFont: 'caps06',
  fontWeight: 500,
  border: '2px solid $black',
  padding: '10px 30px',
  textTransform: 'uppercase',
  width: '100%',
  display: 'inline-block',
  marginBottom: 15,
  textAlign: 'center',
  cursor: 'pointer',
  borderColor: '$white',
  '&:hover': {
    borderColor: '$black',
  },
  variants: {
    full: {
      true: {
        background: '$black',
        borderColor: '$black',
        color: '$white',
        '&:hover': {
          background: '$white',
          color: '$black',
        },
      },
    },
  },
})
const ModalContent = styled('div', {
  display: 'grid',
  placeItems: 'center',
  borderSize: 'content-box',
  minHeight: '15vh',
  margin: 12,
  border: '1px solid #000',
  '@lg': {
    minWidth: '450px',
    margin: 20,
  },
})
const Banner = styled('div', {
  width: '100%',
  '@md': {},
})

const createLsCSService = () => {
  const LOCAL_STORAGE_COUNTRY_SELECTOR = `magal_cs`
  return {
    get: () => localStorage.getItem(LOCAL_STORAGE_COUNTRY_SELECTOR),
    set: (id: string | undefined) =>
      localStorage.setItem(LOCAL_STORAGE_COUNTRY_SELECTOR, `${id}`),
    remove: () => localStorage.removeItem(LOCAL_STORAGE_COUNTRY_SELECTOR),
  }
}

const localesMap: { [index: string]: string } = {
  en: 'en',
  us: 'en',
  fr: 'fr',
  il: 'il',
}

export const CountryPicker: FC<{
  props: {
    title: { [index: string]: string }
    text: { [index: string]: string }
    stayTitle: string
    buttons: {
      title: string
      link: string
      for: string
    }[]
    icon: MediaFieldType
  }
  localeProps: {
    name: string
    path: string
  }
  country: string
}> = ({ props, localeProps = { path: '/', name: 'us' }, country = 'us' }) => {
  const { locale, push } = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [done, setDone] = useState(false)
  const [userCountry, setUserCountry] = useState('us')
  const requestingLocale = localesMap[localeProps?.name]
  const countriesToCheck = ['US', 'FR', 'IL']

  useEffect(() => {
    setUserCountry(country)
  }, [country])

  useEffect(() => {
    if (!done && countriesToCheck.includes(userCountry)) {
      const LS = createLsCSService()
      const currentSelection = LS.get()

      console.log('LOCALE', locale)
      console.log('NAME', localeProps.name, requestingLocale)

      if (currentSelection && currentSelection !== requestingLocale) {
        setIsOpen(false)
        push(`${currentSelection}`, `${currentSelection}`, {
          locale: currentSelection,
        })
      } else {
        setIsOpen(locale !== requestingLocale)
      }

      setDone(true)
    }
  }, [locale, userCountry, done])

  const toggleModal = (newLocale?: string) => {
    const LS = createLsCSService()
    LS.set(newLocale)

    if (newLocale) {
      LS.set(newLocale)
    } else {
      LS.set(locale)
    }

    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  const { title, text, buttons, stayTitle, icon } = props

  const filteredButtons = buttons.filter(
    (button) => button.for === requestingLocale,
  )

  return (
    <ModalWrap>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => toggleModal()}
        variant="white-rounded"
      >
        <ModalContent>
          <Banner>
            <Media {...icon} sizes={'500px'} />
          </Banner>
          <ModalTitle>{title[requestingLocale]}</ModalTitle>
          <ModalText
            dangerouslySetInnerHTML={{ __html: text[requestingLocale] }}
          ></ModalText>
          <div>
            {filteredButtons.map(({ title, link }) => (
              <ModalAction key={link} href={link} full={true}>
                {title}
              </ModalAction>
            ))}
            <br />
            <ModalAction onClick={() => toggleModal(locale)}>
              {stayTitle}
            </ModalAction>
          </div>
        </ModalContent>
      </Modal>
    </ModalWrap>
  )
}
