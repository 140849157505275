export const Facebook = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.40321 2H17.5968C20.0148 2 21.9997 3.9842 21.9997 6.40104V17.5891C21.9997 20.0161 20.0148 22 17.5968 22H6.40321C3.97499 22 2 20.0161 2 17.5891V6.40104C2 3.9842 3.97499 2 6.40321 2ZM12.9627 9.7338V8.76701C12.9627 8.30382 13.2749 8.19304 13.4968 8.19304H14.847V6.12861H12.9831C10.9177 6.12861 10.4539 7.65919 10.4539 8.64635V9.7338H9.25511V12.1405H10.4641V18.1526H12.872V12.1405H14.6555L14.7361 11.1937L14.8772 9.73349L12.9627 9.7338Z" />
  </svg>
)
