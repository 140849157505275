import { globalCss } from './stitches'
const reset = {
  /*RESET ALL DEFAULT STYLES*/
  [`html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, menu, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video`]:
    {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'baseline',
    },
  /* HTML5 display-role reset for older browsers */
  [`article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section`]:
    {
      display: 'block',
    },
  /* HTML5 hidden-attribute fix for newer browsers */
  [`*[hidden]`]: {
    display: 'none',
  },
  body: {
    lineHeight: 1,
  },
  [`menu, ol, ul`]: {
    listStyle: 'none',
  },
  [`blockquote, q`]: {
    quotes: 'none',
  },

  [`blockquote:before, blockquote:after, q:before, q:after`]: {
    content: 'none',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  [`*`]: {
    boxSizing: 'border-box',
  },
  [`a:focus, button:focus, input:focus, select:focus, div:focus`]: {
    outline: 'none',
  },
}

const projectDefaults = {
  html: {
    backgroundColor: 'white',
  },
  body: {
    backgroundColor: 'white',
    /*textRendering: 'geometricPrecision'*/
    '-webkit-font-smoothing': 'antialiased',
  },
  'body.ReactModal__Body--open': {
    overflow: 'hidden',
  },
  [`input[type='search']::-webkit-search-decoration, input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-results-button, input[type='search']::-webkit-search-results-decoration`]:
    {
      appearance: 'none',
    },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    appearance: 'none',
    padding: 0,
    margin: 0,
  },
  [`input:focus-visible + label `]: {
    outline: '#36b7b2 solid 3px',
    outlineOffset: '-2px',
  },
  [`_::-webkit-full-page-media, _:future, :root button:focus, :root a:focus`]: {
    outline: '#36b7b2 solid 3px',
    outlineOffset: '-2px',
  },
}
const utils = {
  '.sr-only': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: 0,
  },
  '.portalProduct': {
    width: '100%',
    position: 'fixed',
    bottom: '-150px',
    height: '0',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    transition: 'bottom ease-out 0.2s',
  },
  '.showPortal': {
    bottom: '100px',
    transition: 'bottom ease-out 0.05s',
    '@lg': {
      transition: 'bottom ease-out 0.2s',
      bottom: '190px',
    },
  },
}
const fonts = {
  '@font-face': [
    {
      src: `url('/fonts/FormaDJRMicro-Regular.woff2') format('woff2'),
        url('/fonts/FormaDJRMicro-Regular.woff') format('woff')`,
      fontFamily: 'FormaDJRMicro',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: `url('/fonts/FormaDJRMicro-Italic.woff2') format('woff2'),
        url('/fonts/FormaDJRMicro-Italic.woff') format('woff')`,
      fontFamily: 'FormaDJRMicro',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    {
      src: `url('/fonts/FormaDJRMicro-Medium.woff2') format('woff2'),
        url('/fonts/FormaDJRMicro-Medium.woff') format('woff')`,
      fontFamily: 'FormaDJRMicro',
      fontStyle: 'normal',
      fontWeight: '500',
    },
    {
      src: `url('/fonts/FormaDJRMicro-MediumItalic.woff2') format('woff2'),
        url('/fonts/FormaDJRMicro-MediumItalic.woff') format('woff')`,
      fontFamily: 'FormaDJRMicro',
      fontStyle: 'italic',
      fontWeight: '500',
    },

    {
      src: `url('/fonts/Termina-Regular.woff2') format('woff2'),
        url('/fonts/Termina-Regular.woff') format('woff');`,
      fontFamily: 'termina-m',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: `url('/fonts/Termina-Medium.woff2') format('woff2'),
        url('/fonts/Termina-Medium.woff') format('woff')`,
      fontFamily: 'termina-m',
      fontStyle: 'normal',
      fontWeight: '500',
    },
    {
      src: `url('/fonts/Termina-Demi.woff2') format('woff2'),
        url('/fonts/Termina-Demi.woff') format('woff')`,
      fontFamily: 'termina-m',
      fontStyle: 'normal',
      fontWeight: '600',
    },
    {
      src: `url('/fonts/AmalfiCoast.otf') format('opentype')`,
      fontFamily: 'amalfi',
      fontStyle: 'normal',
      fontWeight: '400',
    },
  ],
}

export const globalStyles = globalCss({
  ...reset,
  ...projectDefaults,
  ...fonts,
  ...utils,
})

/*@font-face {*/
/*  src: url('fonts/FormaDJRVariable-VF[StarterPack].woff2') format("woff2"),*/
/*  url('fonts/FormaDJRVariable-VF[StarterPack].woff') format("woff");*/
/*  font-family: 'FormaDJRVariable[StarterPack]';*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRVariable-Italic-VF[StarterPack].woff2') format("woff2"),*/
/*  url('fonts/FormaDJRVariable-Italic-VF[StarterPack].woff') format("woff");*/
/*  font-family: 'FormaDJRVariable[StarterPack]';*/
/*  font-style: italic;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-Regular.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-Regular.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-Italic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-Italic.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: italic;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-Medium.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-Medium.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-MediumItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-MediumItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: italic;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-Bold.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-Bold.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: normal;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-BoldItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-BoldItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: italic;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRText-Regular.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRText-Regular.woff') format("woff");*/
/*  font-family: 'FormaDJRText';*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRText-Italic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRText-Italic.woff') format("woff");*/
/*  font-family: 'FormaDJRText';*/
/*  font-style: italic;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRText-Medium.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRText-Medium.woff') format("woff");*/
/*  font-family: 'FormaDJRText';*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRText-MediumItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRText-MediumItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRText';*/
/*  font-style: italic;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRText-Bold.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRText-Bold.woff') format("woff");*/
/*  font-family: 'FormaDJRText';*/
/*  font-style: normal;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRText-BoldItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRText-BoldItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRText';*/
/*  font-style: italic;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRDeck-Regular.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRDeck-Regular.woff') format("woff");*/
/*  font-family: 'FormaDJRDeck';*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRDeck-Italic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRDeck-Italic.woff') format("woff");*/
/*  font-family: 'FormaDJRDeck';*/
/*  font-style: italic;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRDeck-Medium.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRDeck-Medium.woff') format("woff");*/
/*  font-family: 'FormaDJRDeck';*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRDeck-MediumItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRDeck-MediumItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRDeck';*/
/*  font-style: italic;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRDeck-Bold.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRDeck-Bold.woff') format("woff");*/
/*  font-family: 'FormaDJRDeck';*/
/*  font-style: normal;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRDeck-BoldItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRDeck-BoldItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRDeck';*/
/*  font-style: italic;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-Regular.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-Regular.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-Italic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-Italic.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: italic;*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-Medium.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-Medium.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-MediumItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-MediumItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: italic;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-Bold.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-Bold.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: normal;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRMicro-BoldItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRMicro-BoldItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRMicro';*/
/*  font-style: italic;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRBanner-Medium.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRBanner-Medium.woff') format("woff");*/
/*  font-family: 'FormaDJRBanner';*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRBanner-MediumItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRBanner-MediumItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRBanner';*/
/*  font-style: italic;*/
/*  font-weight: 500;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRBanner-Bold.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRBanner-Bold.woff') format("woff");*/
/*  font-family: 'FormaDJRBanner';*/
/*  font-style: normal;*/
/*  font-weight: bold;*/
/*}*/

/*@font-face {*/
/*  src: url('fonts/FormaDJRBanner-BoldItalic.woff2') format("woff2"),*/
/*  url('fonts/FormaDJRBanner-BoldItalic.woff') format("woff");*/
/*  font-family: 'FormaDJRBanner';*/
/*  font-style: italic;*/
/*  font-weight: bold;*/
/*}*/
