import React, { FC } from 'react'

type Props = {
  fillColor?: 'white' | 'green'
  size?: 'default' | 'small'
}

export const Star: FC<Props> = ({ fillColor = 'white', size = 'default' }) => {
  const [width, height] = (() => {
    switch (size) {
      case 'small':
        return [12, 12]
      default:
        return [26, 25]
    }
  })()

  const fill = (() => {
    switch (fillColor) {
      case 'green':
        return '#565C54'
      default:
        return '#FFFCF9'
    }
  })()

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill={`${fill}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.7754 9.55344H16.2242L13.2841 0L10.3265 9.55344L0.775391 9.53514L8.49982 15.4466L5.54227 25L13.2667 19.0886L20.9911 25L18.051 15.4466L25.7754 9.55344Z" />
    </svg>
  )
}
