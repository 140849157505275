import {
  ShopifyCollectionSortKey,
  ShopifyProduct,
  ShopifySearchRes,
} from '@magal/models'
import { gql } from 'graphql-request'
import { PRODUCT_BASIC_FRAGMENT } from './fragments/productFragment'
import { createShopifyClient } from './createShopifyClient'
import { formatProduct } from './helpers/formatProduct'
import { filterProducts } from './helpers/filterProducts'
import { SEARCH_LOAD_MORE_PRODUCTS_COUNT } from '@magal/configs'
import { captureException, withScope } from '@sentry/nextjs'

const QUERY = gql`
  ${PRODUCT_BASIC_FRAGMENT}
  query (
    $regionId: CountryCode
    $primaryQuery: String!
    $afterCursor: String
    $beforeCursor: String
    $sortKey: ProductSortKeys
    $reverse: Boolean
    $count: Int
  ) @inContext(country: $regionId) {
    primary: products(
      query: $primaryQuery
      first: $count
      after: $afterCursor
      before: $beforeCursor
      sortKey: $sortKey
      reverse: $reverse
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...ProductBasicFragment
      }
    }
  }
`
export const getShopifySearchResults: (
  productInfo: {
    query?: string | string[]
    afterCursor?: string
    beforeCursor?: string
    sortKey?: ShopifyCollectionSortKey
    reverse?: boolean
  },
  regionId: string,
  localeId: string,
) => Promise<{
  products: ShopifyProduct[]
  pageInfo: {
    hasNextPage: boolean
    endCursor: string
  }
} | null> = async (productInfo, regionId, localeId) => {
  try {
    const shopifyClient = createShopifyClient(localeId)

    const res = await shopifyClient.request<ShopifySearchRes>(QUERY, {
      ...productInfo,
      primaryQuery: `(title:"${productInfo.query}") OR ${productInfo.query}`,
      count: SEARCH_LOAD_MORE_PRODUCTS_COUNT,
      regionId,
    })
    if (!res) return null

    return {
      products: filterProducts(res.primary.nodes.map(formatProduct)),
      pageInfo: res.primary.pageInfo,
    }
  } catch (e) {
    withScope(function (scope) {
      scope.setTransactionName(
        '[getShopifySearchResults] Cannot load search data',
      )
      captureException(e)
    })
    return null
  }
}
