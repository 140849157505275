import React, { FC } from 'react'
import { styled } from '@magal/styles'
import { SiteConfiguration } from '@magal/models'
import { BlockContent } from '@magal/components'
import { NewsletterForm } from '@magal/newsletter'

const Wrap = styled('div', {
  display: 'grid',
  gap: '$24',
  color: '$green_09',
  maxWidth: '$maxWidthS',
})

const Heading = styled('div', {
  projectFont: 'caps07',
  color: '$green_09',
})

const Description = styled('div', {
  projectFont: 'body01',
})

export interface FooterNewsletterProps {
  newsletter: SiteConfiguration['newsletter']['footer']
}

export const FooterNewsletter: FC<FooterNewsletterProps> = ({ newsletter }) => {
  return (
    <Wrap>
      {newsletter?.title && <Heading>{newsletter.title}</Heading>}
      {newsletter?.description && (
        <Description>
          <BlockContent body={newsletter.description} />
        </Description>
      )}
      <NewsletterForm
        buttonAppearance={'outlineGreen'}
        appearance={'onWhite'}
      />
    </Wrap>
  )
}

export default FooterNewsletter
