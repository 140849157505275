import { AttributeType } from '@magal/models'

export const equalAttributes: (
  a: AttributeType[] | undefined,
  b: AttributeType[] | undefined,
) => boolean = (a, b) => {
  if (!a && b?.length === 0) {
    return true
  }
  if (!b && a?.length === 0) {
    return true
  }
  if (!a && !b) return true
  return !!(
    a &&
    b &&
    a.length === b.length &&
    a.every((a1) => b.find((a2) => a2.key === a1.key)?.value === a1.value)
  )
}
