import { CSSProperties, RefObject, useEffect, useRef, useState } from 'react'

type Props = { isActive: boolean; duration?: number }

export function useHeightAnimation<T extends HTMLElement>({
  isActive,
  duration = 300,
}: Props): [RefObject<T>, CSSProperties] {
  const elRef = useRef<T>(null)
  const [height, setHeight] = useState(isActive ? 'auto' : '0px')
  const [isVisible, setVisibility] = useState(isActive)
  const [timeoutID, setTimeoutID] = useState(0)

  useEffect(() => {
    if (isActive) {
      setHeight(elRef?.current?.scrollHeight + 'px')
      setTimeoutID(
        window.setTimeout(() => {
          setHeight('auto')
        }, duration),
      )
    } else {
      clearTimeout(timeoutID)
      requestAnimationFrame(function () {
        setHeight(elRef?.current?.scrollHeight + 'px')
        requestAnimationFrame(function () {
          setHeight(0 + 'px')
        })
      })
    }
  }, [isActive])

  // set item visibility after/before animation
  useEffect(() => {
    if (!isVisible && isActive) {
      return setVisibility(isActive)
    }
    setTimeout(() => {
      setVisibility(isActive)
    }, duration)

    return () => clearTimeout(duration)
  }, [isActive, duration, isVisible])

  const visibility: 'visible' | 'hidden' = isVisible ? 'visible' : 'hidden'
  const opacity = isActive ? 1 : 0

  const css = {
    height,
    overflow: 'hidden',
    transition: `${duration}ms cubic-bezier(.2,.4,.2,1)`,
    opacity,
    visibility,
  }

  return [elRef, css]
}
