import {
  ServiceResponse,
  ShopifyProductExtended,
  ShopifyProductExtendedRes,
} from '@magal/models'
import { getLocaleRegionIdFromPath } from '@magal/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from './createShopifyClient'
import { formatExtendedProduct } from './helpers/formatProduct'
import { PRODUCT_EXTENDED_FRAGMENT } from './fragments/productFragment'

const QUERY = gql`
  ${PRODUCT_EXTENDED_FRAGMENT}
  query ($regionId: CountryCode!, $productHandle: String!)
  @inContext(country: $regionId) {
    product(handle: $productHandle) {
      ...ProductExtendedFragment
    }
  }
`
export const getShopifyProductExtended = async (
  productHandle: string,
  locale: string,
): Promise<ServiceResponse<ShopifyProductExtended>> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)

  return await shopifyClient
    .rawRequest<{
      product: ShopifyProductExtendedRes
    }>(QUERY, { productHandle, regionId })
    .then((res) => {
      const incomingProduct = res.data.product
      return {
        status: 'OK',
        data: incomingProduct ? formatExtendedProduct(incomingProduct) : null,
      } as ServiceResponse<ShopifyProductExtended>
    })
    .catch((e) => {
      const err = new Error(e)
      return { status: 'ERROR', errors: [err] }
    })
}
