import {
  DEFAULT_LOCALE_ID,
  LocaleId,
  ROUTE_BASE_COLLECTIONS,
} from '@magal/configs'
import { groq } from 'next-sanity'

export const createLinkProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  return groq`{
    linkType,
    linkType == 'internal' && defined(internal) => {
      "href": select(
        defined(query) => internal->{path}.path.current + query,
        internal->{path}.path.current,
      )
    },
    linkType == 'external' => {
      "href": coalesce(external.${localeId}, external.${defaultLocaleId})
    },
    linkType == 'collection' => {
      "href": "${
        localeId !== defaultLocaleId ? `/${localeId}` : ''
      }" + "${ROUTE_BASE_COLLECTIONS}/" + collection->store.slug.current
    },
    linkType == 'phone' => {
      "href": "tel:" + coalesce(phone.${localeId}, phone.${defaultLocaleId})
    },
    linkType == 'email' => {
      "href": "mailto:" + coalesce(email.${localeId}, email.${defaultLocaleId})
    },
    linkType == 'file' => {
      "href": file.asset->url + "?dl=",
      "download": true,
    },
    linkType == 'linkAction' => {
      "action": {
        "type": linkAction.linkActionType,
        "payload": {
          linkAction.linkActionType == "storyModal" => {
            "storyId": linkAction.storyModal.story._ref
          },
          linkAction.linkActionType == "youtubeModal" => {
            "youtubeUrl": linkAction.youtubeModal.youtubeUrl
          },
        }
      }
    },
  }`
}
