export const ChevronLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.6648 12.0062C8.67905 11.9129 8.71163 11.7823 8.80257 11.683L13.8026 6.22849C13.9372 6.08161 14.1143 6 14.3 6C14.4857 6 14.6628 6.08161 14.7974 6.22849C15.0673 6.52286 15.0675 6.96692 14.7982 7.26157L10.32 12.2531L14.7974 17.1376C15.0675 17.4322 15.0675 17.8769 14.7974 18.1715C14.6628 18.3184 14.4857 18.4 14.3 18.4C14.1143 18.4 13.9372 18.3184 13.8026 18.1715L8.84003 12.7578C8.78628 12.7326 8.74761 12.6915 8.72332 12.6532C8.68886 12.5989 8.67373 12.5385 8.66578 12.4952C8.64995 12.4088 8.64998 12.3118 8.65 12.2596L8.65 12.2515C8.65 12.1974 8.64999 12.1031 8.6648 12.0062Z" />
  </svg>
)
