import { gql } from 'graphql-request'

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on Image {
    url
    width
    height
    altText
    id
  }
`
