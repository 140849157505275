import { ShopifyProduct, ShopifyProductRes } from '@magal/models'
import Cookies from 'js-cookie'
import { getLocaleRegionIdFromPath } from '@magal/utils'
import { createShopifyClient } from '../createShopifyClient'
import { gql } from 'graphql-request'
import { PRODUCT_BASIC_FRAGMENT } from '../fragments/productFragment'
import { formatProduct } from '../helpers/formatProduct'

const RECENTLY_VIEWED_COOKIES_KEY = 'recently-viewed'

const getRecentlyViewedProductIds: () => string[] | undefined = () => {
  const rawRecentlyViewedProducts = Cookies.get(RECENTLY_VIEWED_COOKIES_KEY)
  return rawRecentlyViewedProducts
    ? JSON.parse(rawRecentlyViewedProducts)
    : undefined
}

export const pushProductToRecentlyViewed = (productId: string) => {
  const recentlyViewedProducts = getRecentlyViewedProductIds()
  if (recentlyViewedProducts) {
    let previousHandles
    if (recentlyViewedProducts.includes(productId)) {
      previousHandles = [
        productId,
        ...recentlyViewedProducts.filter((id: string) => id !== productId),
      ].slice(0, 4)
    } else {
      previousHandles = [productId, ...recentlyViewedProducts].slice(0, 4)
    }
    Cookies.set(RECENTLY_VIEWED_COOKIES_KEY, JSON.stringify(previousHandles))
  } else {
    Cookies.set(RECENTLY_VIEWED_COOKIES_KEY, JSON.stringify([productId]))
  }
}

const QUERY = gql`
  ${PRODUCT_BASIC_FRAGMENT}
  query ($regionId: CountryCode!, $ids: [ID!]!) @inContext(country: $regionId) {
    nodes(ids: $ids) {
      ...ProductBasicFragment
    }
  }
`
export const getRecentlyViewedProducts: (
  locale?: string,
) => Promise<ShopifyProduct[] | undefined> = async (locale) => {
  const ids = getRecentlyViewedProductIds()
  if (!ids) return undefined

  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)

  if (ids.length === 0) {
    return []
  }
  // todo reuse Karol's fn

  const products = await shopifyClient
    .request<{
      nodes: ShopifyProductRes[]
    }>(QUERY, {
      ids,
      regionId,
    })
    .then((res) => {
      return res.nodes
    })

  return products.filter((p) => p !== null).map(formatProduct)
}
