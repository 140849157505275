import {
  MetafieldRes,
  ShopifyProductBasic,
  ShopifyProductExtended,
  ShopifyProductExtendedRes,
  ShopifyProductRes,
  ShopifyProductVariant,
  ShopifyProductVariantRes,
} from '@magal/models'
import { mapImage, mapImages } from './mapImage'
import { getProductRootTitle } from './getProductRootTitle'
import { mapMedia } from './mapMedia'

const formatProductMetafields: (metafields: MetafieldRes[]) => any = (
  metafields,
) => {
  return metafields.reduce((acc, val) => {
    if (val?.key === 'materials') {
      val.value = JSON.parse(val.value)
    }
    return val ? { ...acc, [val.key]: val.value } : acc
  }, {})
}

export const formatProduct = (res: ShopifyProductRes): ShopifyProductBasic => {
  const metafields = formatProductMetafields(res.metafields)

  return {
    ...res,
    featuredImage: mapImage(res.featuredImage),
    images: [],
    title: getProductRootTitle(res.title),
    fullTitle: res.title,
    ...metafields,
  }
}
export const formatExtendedProduct = (
  res: ShopifyProductExtendedRes,
): ShopifyProductExtended => {
  const {
    variants,
    featuredImage,
    images,
    reviewsSummary,
    ...restOfProductNode
  } = res
  const metafields = formatProductMetafields(res.metafields)

  return {
    ...restOfProductNode,
    featuredImage: mapImage(featuredImage),
    images: mapImages(images),
    variants: variants.nodes.map(formatVariant),
    title: getProductRootTitle(res.title),
    fullTitle: res.title,
    media: mapMedia(res.media),
    reviewsSummary: reviewsSummary?.value
      ? JSON.parse(reviewsSummary.value)
      : null,
    ...metafields,
  }
}

export const formatVariant = (
  res: ShopifyProductVariantRes,
): ShopifyProductVariant => {
  const { image, ...restOfVariantNode } = res
  const metafields = formatProductMetafields(res.product.metafields)
  return {
    ...restOfVariantNode,
    image: mapImage(image),
    product: { ...res.product, ...metafields },
  }
}
