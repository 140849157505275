import { styled } from '@magal/styles'
import { FocusEvent, forwardRef, useEffect, useState } from 'react'
import { TextFieldPropsType } from './TextFieldPropsType'

const FieldContainer = styled('div', {
  width: '100%',
  color: 'currentColor',
  variants: {
    isInvalid: {
      true: {
        color: '$red500',
      },
    },
    appearance: {
      onWhite: {},
      onRed: {},
    },
  },
  compoundVariants: [
    {
      isInvalid: true,
      appearance: 'onRed',
      css: {
        color: '$white',
      },
    },
  ],
})

const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  projectFont: 'body01',
})

const Input = styled('input', {
  projectFont: 'body01',
  display: 'block',
  border: 'none',
  borderBottom: '1px solid currentColor',
  backgroundColor: 'transparent',
  appearance: 'none',
  color: 'currentColor',
  borderRadius: 0,
  '&::placeholder': {
    color: 'currentColor',
  },
  '&:focus-visible': {
    boxShadow: '0 1px 0 0 currentColor',
  },
  variants: {
    appearance: {
      onWhite: {},
      onRed: {},
    },
    multiline: {
      true: {
        resize: 'none',
      },
    },
    isActive: {
      true: {
        '&::placeholder': {
          opacity: 1,
          transition: 'opacity 200ms cubic-bezier(0.6, 0.04, 0.98, 0.335) 10ms',
        },
      },
      false: {
        '&::placeholder': {
          opacity: 0,
          transition: 'none',
        },
      },
    },
    isInvalid: {
      true: {
        borderColor: '$red500',
        color: '$red500',
        '&:focus-visible': {
          boxShadow: '0 1px 0 0 $colors$red500',
        },
      },
    },
    isDisabled: {
      true: {},
      false: {},
    },
  },
})

const Label = styled('label', {
  zIndex: 2,
  margin: 0,
  border: 0,
  top: 0,
  left: 0,
  transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  transformOrigin: 'left top',
  cursor: 'text',
  pointerEvents: 'none',
  position: 'absolute',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  variants: {
    isActive: {
      true: {
        transform: 'translate(0px, -16px) scale(0.80)',
      },
      false: {},
    },
    isInvalid: {
      true: {
        color: '$red500',
      },
    },
  },
})

const HelpText = styled('div', {
  position: 'relative',
  projectFont: 'body03',
  display: 'flex',
  alignItems: 'center',
  color: 'currentColor',
})

type FormProps = TextFieldPropsType & {
  appearance?: 'onRed'
}

export const TextField = forwardRef<HTMLInputElement, FormProps>(
  (props, ref) => {
    const {
      autoComplete,
      disabled,
      id,
      children,
      invalid,
      helpText,
      label,
      readOnly,
      onBlur,
      onFocus,
      value,
      type = 'text',
      style,
      className,
      placeholder,
      multiline,
      appearance,
      ...rest
    } = props

    const isValueSet = (value?: string): boolean =>
      typeof value === 'string' && value.length > 0

    const helpId = helpText ? `${id}__hint` : undefined

    const [isActive, setIsActive] = useState<boolean>(() => isValueSet(value))

    const handleOnBlur = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setIsActive(isValueSet(event.target.value))
      if (typeof onBlur === 'function') {
        onBlur(event)
      }
    }
    const handleOnFocus = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setIsActive(true)
      if (typeof onBlur === 'function') {
        onBlur(event)
      }
    }

    useEffect(() => {
      if (isValueSet(value)) {
        setIsActive(true)
      }
    }, [value])

    const htmlTag = multiline ? 'textarea' : 'input'

    return (
      <FieldContainer
        className={className}
        isInvalid={invalid}
        appearance={appearance}
      >
        <InputContainer>
          {label && (
            <Label htmlFor={id} isActive={isActive}>
              {label}
            </Label>
          )}

          <Input
            {...rest}
            aria-describedby={helpId}
            aria-errormessage={invalid && helpId ? helpId : undefined}
            aria-invalid={invalid}
            disabled={disabled}
            id={id}
            readOnly={readOnly}
            ref={ref}
            type={type}
            autoComplete={autoComplete}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            isDisabled={disabled}
            isActive={isActive}
            value={value}
            placeholder={placeholder}
            multiline={multiline}
            as={htmlTag}
          />
        </InputContainer>
        {helpText && <HelpText id={helpId}>{helpText}</HelpText>}
      </FieldContainer>
    )
  },
)
