import { styled } from '@magal/styles'

export const Form = styled('form', {
  display: 'grid',
  gap: '$32',
  gridAutoFlow: 'row',

  variants: {
    extraSubmitButtonPadding: {
      true: {
        '&:last-child button': {
          marginTop: '$32',
        },
      },
    },
  },
})
