import { gql } from 'graphql-request'
import { CART_FRAGMENT } from './cart'

// QUERIES

export const QUERY_FETCH_CART = gql`
  ${CART_FRAGMENT}
  query ($cartId: ID!, $countryCode: CountryCode)
  @inContext(country: $countryCode) {
    cart(id: $cartId) {
      ...CartFragment
    }
  }
`
// MUTATIONS

export const MUTATION_CREATE_CART = gql`
  ${CART_FRAGMENT}
  mutation cartCreate($cartInput: CartInput, $countryCode: CountryCode)
  @inContext(country: $countryCode) {
    cartCreate(input: $cartInput) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`
export const MUTATION_UPDATE_NOTE = gql`
  ${CART_FRAGMENT}
  mutation cartNoteUpdate(
    $cartId: ID!
    $note: String
    $countryCode: CountryCode
  ) @inContext(country: $countryCode) {
    cartNoteUpdate(cartId: $cartId, note: $note) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`
export const MUTATION_UPDATE_BUYER = gql`
  ${CART_FRAGMENT}
  mutation cartBuyerIdentityUpdate(
    $cartId: ID!
    $buyerIdentity: CartBuyerIdentityInput!
    $countryCode: CountryCode
  ) @inContext(country: $countryCode) {
    cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export const MUTATION_ADD_CART_LINES = gql`
  ${CART_FRAGMENT}
  mutation cartLinesAdd(
    $lines: [CartLineInput!]!
    $cartId: ID!
    $countryCode: CountryCode
  ) @inContext(country: $countryCode) {
    cartLinesAdd(lines: $lines, cartId: $cartId) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export const MUTATION_UPDATE_CART_LINES = gql`
  ${CART_FRAGMENT}
  mutation cartLinesUpdate(
    $cartId: ID!
    $lines: [CartLineUpdateInput!]!
    $countryCode: CountryCode
  ) @inContext(country: $countryCode) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`
export const MUTATION_REMOVE_CART_LINES = gql`
  ${CART_FRAGMENT}
  mutation cartLinesRemove(
    $cartId: ID!
    $lineIds: [ID!]!
    $countryCode: CountryCode
  ) @inContext(country: $countryCode) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`
