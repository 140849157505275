import { FC } from 'react'
import { SanityImage } from './components/SanityImage'
import { MuxVideo } from './components/MuxVideo'
import { styled } from '@magal/styles'
import { MediaProps } from './models/MediaProps'
import { ShopifyImage } from './components/ShopifyImage'
// import { useMediaSizesDebugger } from '@magal/utils'
import { ShopifyVideo } from './components/ShopifyVideo'
import { ShopifyExternalVideo } from './components/ShopifyExternalVideo'

const MediaFillWrap = styled('div', {
  position: 'relative',
  variants: {
    fill: {
      true: {
        height: '100%',
        width: '100%',
      },
    },
  },
})
// const Debugger = styled('pre', {
//   background: 'black',
//   p: '$5',
//   color: 'white',
//   fontFamily: 'monospace',
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   zIndex: 99999,
//   variants: {
//     warn: {
//       true: {
//         background: 'red',
//       },
//     },
//   },
// })
// const DEBUG_ENABLED = true
export const Media: FC<MediaProps> = ({
  layout = 'responsive',
  objectFit = 'cover',
  sizes,
  priority,
  mediaPayload,
  alt,
  loop,
  hardcropRatio,
}) => {
  // const [ref, wrapWidth, downloadedWidth, devicePixelRatio] =
  //   useMediaSizesDebugger(DEBUG_ENABLED)

  return (
    <MediaFillWrap fill={layout === 'fill'}>
      {(() => {
        switch (mediaPayload?.type) {
          case 'sanity-image':
            return (
              <SanityImage
                alt={alt}
                mediaPayload={mediaPayload}
                sizes={sizes}
                objectFit={objectFit}
                priority={priority}
                layout={layout}
                // will hardcrop cosidering focal point
                hardcropRatio={hardcropRatio}
              />
            )
          case 'mux-video':
            return (
              <MuxVideo
                alt={alt}
                mediaPayload={mediaPayload}
                layout={layout}
                priority={priority}
                loop={loop}
              />
            )
          case 'shopify-image':
            return (
              <ShopifyImage
                alt={alt}
                sizes={sizes}
                objectFit={objectFit}
                mediaPayload={mediaPayload}
                layout={layout}
                priority={priority}
                loop={loop}
                // will hardcrop always to the center
                hardcropRatio={hardcropRatio}
              />
            )
          case 'shopify-media-video': {
            return (
              <ShopifyVideo
                mediaPayload={mediaPayload.video}
                layout={layout}
                priority={priority}
                loop={loop}
              />
            )
          }
          case 'shopify-media-external-video': {
            return (
              <ShopifyExternalVideo
                mediaPayload={mediaPayload.video}
                layout={layout}
                priority={priority}
                loop={loop}
              />
            )
          }
          default:
            return <div>Unsupported media source</div>
        }
      })()}
      {/*{DEBUG_ENABLED && (*/}
      {/*  <Debugger warn={downloadedWidth / wrapWidth > 1.5}>*/}
      {/*    {wrapWidth}px container*/}
      {/*    <br />*/}
      {/*    {downloadedWidth * devicePixelRatio}*/}
      {/*    px downloaded*/}
      {/*    <br />*/}
      {/*    {downloadedWidth / wrapWidth} efficiency*/}
      {/*  </Debugger>*/}
      {/*)}*/}
    </MediaFillWrap>
  )
}
