import { FC, useState } from 'react'
import { styled } from '@magal/styles'
import { Button } from '@magal/components'
import { useRouter } from 'next/router'
import { useCustomerContext } from '../../../customerContext'
import { createCustomerClient } from '../../../customerService'
import { useTranslation } from 'react-i18next'
import { AccountCardWrapper } from '../components/AccountCardWrapper'
import { AddAddress } from './AddAddress'
import { AddressItemCard } from '../components/AddressItemCard'

const Root = styled('div', {
  display: 'grid',
  gap: '$32',
  '@lg': {
    maxWidth: '$maxWidthS',
  },
})

const Card = styled('div', {
  display: 'grid',
  gap: '$32',
})

const AddCard = styled(Card, {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  projectFont: 'caps04',
})

export const AddressesList: FC = () => {
  const { customer, accessToken, updateCustomer } = useCustomerContext()
  const { locale } = useRouter()
  const customerClient = createCustomerClient({ locale })
  const { t } = useTranslation('account')

  const customerAccessToken = accessToken || ''

  const defaultAddressId = customer?.defaultAddress?.id || ''
  const addresses = customer?.addresses

  const defaultAddress =
    addresses?.nodes?.filter((node) => node?.id === defaultAddressId)[0] || null

  const otherAddresses =
    addresses?.nodes?.filter((node) => node?.id !== defaultAddressId) || []

  const addressCollection = [
    ...(defaultAddress ? [defaultAddress] : []),
    ...otherAddresses,
  ]

  const [isAddAddressVisible, setIsAddAddressVisible] = useState(false)

  const handleAddressDelete = async (id?: string) => {
    if (window.confirm(t('accountPage.addressListView.deleteButtonConfirm'))) {
      await customerClient.deleteAddress({ customerAccessToken, id })
      await updateCustomer()
    }
  }

  return (
    <Root>
      {addressCollection.length === 0 ? (
        <AccountCardWrapper>
          <AddCard>
            <span>{t('accountPage.addressListView.noAddressesText')}</span>
          </AddCard>
        </AccountCardWrapper>
      ) : (
        addressCollection.map((node, index) => (
          <AddressItemCard
            key={`addressCard-${index}`}
            node={node}
            defaultAddressId={defaultAddressId}
            handleAddressDelete={() => handleAddressDelete(node.id)}
            isDeletingPossible={addressCollection.length !== 1}
          />
        ))
      )}
      {isAddAddressVisible ? (
        <AddAddress onClick={setIsAddAddressVisible} />
      ) : (
        <Button
          appearance={'solidGreen'}
          onClick={() => setIsAddAddressVisible(true)}
        >
          {t('accountPage.addressListView.addButtonLabel')}
        </Button>
      )}
    </Root>
  )
}
