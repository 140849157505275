import { ShopifyCollectionSortKey } from '@magal/models'

export const DEFAULT_PROJECT_SORT_KEY = 'relevance'
export const PROJECT_SORT_KEY_MAP: Record<
  string,
  { sortKey?: ShopifyCollectionSortKey; reverse?: boolean }
> = {
  relevance: { sortKey: 'RELEVANCE', reverse: true },
  // bestSellingAsc: { sortKey: 'BEST_SELLING', reverse: false },
  // bestSellingDesc: { sortKey: 'BEST_SELLING', reverse: true },
  // createdAsc: { sortKey: 'CREATED', reverse: false },
  createdDesc: { sortKey: 'CREATED_AT', reverse: true },
  // manualAsc: { sortKey: 'MANUAL', reverse: false },
  // manualDesc: { sortKey: 'MANUAL', reverse: true },
  priceAsc: { sortKey: 'PRICE', reverse: false },
  priceDesc: { sortKey: 'PRICE', reverse: true },
  // titleAsc: { sortKey: 'TITLE', reverse: false },
  // titleDesc: { sortKey: 'TITLE', reverse: true },
}

export const SEARCH_LOAD_MORE_PRODUCTS_COUNT = 16
export const COLLECTION_INITIAL_PRODUCTS_COUNT = 16
export const COLLECTION_LOAD_MORE_PRODUCTS_COUNT = 16
export const PRODUCT_CARD_IMAGE_RATIO = 0.8
export const PRODUCT_CARD_FEATURED_IMAGE_RATIO = 1.6
