import { ShopifyProduct, ShopifyProductRes } from '@magal/models'
import { getLocaleRegionIdFromPath } from '@magal/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from '..'
import { formatProduct } from './helpers/formatProduct'
import { PRODUCT_BASIC_FRAGMENT } from './fragments/productFragment'
import { captureException, withScope } from '@sentry/nextjs'

const QUERY = gql`
  ${PRODUCT_BASIC_FRAGMENT}
  query ($regionId: CountryCode!, $id: ID!) @inContext(country: $regionId) {
    productRecommendations(productId: $id) {
      ...ProductBasicFragment
    }
  }
`
const getProductRecommendations = async (
  locale: string,
  id: string,
): Promise<ShopifyProductRes[] | null> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  try {
    const shopifyClient = createShopifyClient(localeId)

    if (!id) {
      return []
    }

    return await shopifyClient
      .request<{
        productRecommendations: ShopifyProductRes[]
      }>(QUERY, {
        regionId,
        id,
      })
      .then((res) => {
        return res.productRecommendations
      })
  } catch (e) {
    withScope(function (scope) {
      scope.setTransactionName(
        '[getProductRecommendations] Cannot load product recommendations data',
      )
      captureException(e)
    })
    return null
  }
}

export const getShopifyProductRecommendations = async (
  locale: string,
  productId: string,
): Promise<{
  products: ShopifyProduct[]
}> => {
  const productRecommendations = await getProductRecommendations(
    locale,
    productId,
  )
  return {
    products: productRecommendations
      ? productRecommendations.map((product) => formatProduct(product))
      : [],
  }
}
