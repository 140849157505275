import { DEFAULT_REGION_ID, LocaleId, RegionId } from '@magal/configs'
import { groq } from 'next-sanity'
import { createSEOProjection } from './createSEOProjection'
import { createPageBuilderProjection } from './createPageBuilderProjection'

export const createDefaultPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  return groq`coalesce(page_${regionId}, page_${DEFAULT_REGION_ID})->{
      ...,
      showIcons,
      "disallowRobots":^.disallowRobots,
      pageBuilder[]${PAGE_BUILDER_PROJECTION},
      seo${createSEOProjection(localeId)},
    }`
}
