import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  validateRequired,
  useFormSubmitState,
  FormCheckboxField,
  SubmitButton,
  FormTextField,
  FormSelectField,
} from '@magal/forms'
import { useRouter } from 'next/router'
import { createCustomerClient, ErrorResponseType } from '../customerService'
import { removeEmptyProperties } from '../helpers/removeEmptyProperties'
import { ClientError } from 'graphql-request'
import { useCustomerContext } from '../customerContext'
import { Button } from '@magal/components'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { shopifyCountries } from '../helpers/shopifyCountries'
import { Form } from '../components/Form'
import { styled } from '@magal/styles'

type FormValues = {
  lastName?: string
  firstName?: string
  address1?: string
  address2?: string
  province?: string
  country?: string
  company?: string
  phone?: string
  zip?: string
  city?: string
  isDefault?: boolean
  id?: string
}

interface UpdateFormProps {
  className?: string
  defaultValues?: FormValues
  onCancelClick?: () => void
  onSubmitSuccess?: () => void
  isDefault?: boolean
}

const initialDefaultValues: FormValues = {
  lastName: '',
  firstName: '',
  address1: '',
  address2: '',
  province: '',
  country: '',
  company: '',
  phone: '',
  zip: '',
  city: '',
  isDefault: false,
  id: '',
}

const StyledFormTextField = styled(FormTextField, {
  maxWidth: '$416',
})

const StyledSelectField = styled(FormSelectField, {
  maxWidth: '100%',
})

export const UpdateAddressForm: FC<UpdateFormProps> = ({
  className,
  defaultValues = initialDefaultValues,
  onSubmitSuccess,
  onCancelClick,
  isDefault,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({
    form,
    defaultValues,
    keepValuesOnSuccess: true,
  })
  const { accessToken } = useCustomerContext()
  const customerAccessToken = accessToken || ''

  const handleResponseErrors = (errors: ErrorResponseType[]) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const { isDefault, id, ...restOfAddress } = data

      const response = await customerClient.updateAddress({
        address: removeEmptyProperties({ ...restOfAddress }),
        customerAccessToken,
        id,
      })
      const errors = response?.customerAddressUpdate?.customerUserErrors || []

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      if (!response?.customerAddressUpdate?.customerAddress) {
        return setError()
      }

      // set address as default if requested
      if (!initialDefaultValues.isDefault && isDefault) {
        const addressId = response?.customerAddressUpdate?.customerAddress?.id

        const defaultAddressResponse =
          await customerClient.updateDefaultAddress({
            addressId,
            customerAccessToken,
          })

        const defaultFieldErrors =
          defaultAddressResponse?.customerDefaultAddressUpdate
            ?.customerUserErrors || []

        if (defaultFieldErrors?.length) {
          return handleResponseErrors(defaultFieldErrors)
        }
      }

      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="add-address-form"
    >
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="name"
        name="firstName"
        label={t('updateAddressForm.firstNameFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="family-name"
        name="lastName"
        label={t('updateAddressForm.lastNameFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="address-line1"
        name="address1"
        label={t('updateAddressForm.address1FieldLabel')}
      />
      <StyledFormTextField
        control={control}
        autoComplete="address-line2"
        name="address2"
        label={t('updateAddressForm.address2FieldLabel')}
      />
      <StyledFormTextField
        control={control}
        autoComplete="phone"
        name="phone"
        label={t('updateAddressForm.phoneFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="city"
        name="city"
        label={t('updateAddressForm.cityFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="postal-code"
        name="zip"
        label={t('updateAddressForm.zipFieldLabel')}
      />
      <StyledFormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="address-level1"
        name="province"
        label={t('updateAddressForm.provinceFieldLabel')}
      />
      <StyledSelectField
        control={control}
        rules={validateRequired(t)}
        autoComplete="country"
        name="country"
      >
        {shopifyCountries.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </StyledSelectField>
      <FormCheckboxField
        disabled={isDefault}
        control={control}
        name="isDefault"
        label={t(
          isDefault
            ? 'updateAddressForm.isCurrentDefaultAddressFieldLabel'
            : 'updateAddressForm.setDefaultAddressFieldLabel',
        )}
      />
      <SubmitButton
        text={t('updateAddressForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
        appearance={'solidGreen'}
      />
      {onCancelClick && (
        <Button onClick={onCancelClick} appearance="outlineGreen">
          {t('updateAddressForm.cancelButtonLabel')}
        </Button>
      )}
    </Form>
  )
}
