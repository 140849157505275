import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createLinkProjection } from '../projections/createLinkProjection'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionCardsSliderProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
    _key,
    _type,
    ${coalesceLocaleField('title', localeId)},
    variant,
    cards[] {
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('subtitle', localeId)},
      link${LINK_PROJECTION},
      link_fr${LINK_PROJECTION},
      link_de${LINK_PROJECTION},
      image${MEDIA_PROJECTION}
    }
  }
`
}
