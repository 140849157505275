import React, { FC } from 'react'
import ReactModal from 'react-modal'
import { Overlay } from './Overlay'
import { Content } from './Content'

export type ModalOverlayStyle = 'transparent' | 'blur' | 'light' | 'dark'

export type ModalContentPosition =
  | 'center'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
export type ModalContentVariant = 'default' | 'white-rounded' | 'rounded'
export type ModalContentSize =
  | 'intrinsic'
  | 'stretch'
  | 'stretch-x'
  | 'stretch-y'

export type ModalTransition = 'fade' | 'slide-from-right' | 'slide-from-top'

export type ModalProps = ReactModal.Props & {
  // Content
  variant?: ModalContentVariant
  position?: ModalContentPosition
  size?: ModalContentSize
  // Overlay
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  // Animations
  transition?: ModalTransition

  // General props - from 'react-modal'
  //
  // isOpen: boolean
  // onBeforeOpen: () => void
  // onAfterOpen: () => void
  // onBeforeClose: () => void
  // onAfterClose: () => void
  // preventScroll: boolean
  // onRequestClose: () => void
}

export const MODAL_CLASSES = {
  base: 'base',
  afterOpen: 'after-open',
  beforeClose: 'before-close',
}

export const Modal: FC<ModalProps> = ({
  variant = 'default',
  position = 'center',
  size = 'intrinsic',
  overlayCloseButton,
  overlayCloseButtonLabel,
  overlayVariant = 'dark',
  transition = 'fade',
  isOpen,
  onRequestClose,
  children,
  ...reactModalProps
}) => {
  const openTime = 400
  const closeTime = 200

  const contentProps = {
    variant,
    position,
    size,
    transition,
    closeTime,
    openTime,
    onRequestClose,
  }
  const overlayProps = {
    overlayCloseButton,
    overlayCloseButtonLabel,
    overlayVariant,
    closeTime,
    openTime,
  }

  return (
    <ReactModal
      {...reactModalProps}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayElement={(props, contentElement) => (
        <Overlay
          {...props}
          {...overlayProps}
          hasPadding={position === 'center' && size === 'intrinsic'}
        >
          {contentElement}
        </Overlay>
      )}
      contentElement={(props, children) => (
        <Content {...props} {...contentProps}>
          {children}
        </Content>
      )}
      className={MODAL_CLASSES}
      overlayClassName={MODAL_CLASSES}
      closeTimeoutMS={closeTime}
    >
      {children}
    </ReactModal>
  )
}
