export const CirclePlus = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 0C4.24333 0 0 4.24333 0 9.5C0 14.7567 4.24333 19 9.5 19C14.7567 19 19 14.7567 19 9.5C19 4.24333 14.7567 0 9.5 0ZM9.5 18.1556C4.72889 18.1556 0.844445 14.2711 0.844445 9.5C0.844445 4.72889 4.72889 0.844445 9.5 0.844445C14.2711 0.844445 18.1556 4.72889 18.1556 9.5C18.1556 14.2711 14.2711 18.1556 9.5 18.1556ZM9.92218 9.07778H14.9889V9.92215H9.92218V14.9888H9.07782V9.92215H4.01115V9.07778H9.07782L9.07796 4.01111H9.92233L9.92218 9.07778Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
)
