import React, { FC } from 'react'
import { styled } from '@magal/styles'
import { SiteConfiguration } from '@magal/models'
import {
  AccordionHeaderProps,
  AccordionStateful,
  Button,
  Container,
  Link,
  LogoLink,
  Media,
  Rule,
  TrustBox,
} from '@magal/components'
import { ChevronDown, ChevronUp, Facebook, Instagram } from '@magal/icons'
import { useTranslation } from 'react-i18next'
import { FooterNewsletter } from './FooterNewsletter'
import { CreditsModal } from './CreditsModal'

const TopWrap = styled(Container, {
  display: 'grid',
  padding: '$48 0 $32',
  gridGap: '$32',
})
const Heading = styled('div', {
  projectFont: 'caps07',
  color: '$green_09',
  marginBottom: '$24',
})
const MainWrap = styled('div', {
  display: 'grid',
  gridGap: '$28',
  '@lg': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})
const LinkCols = styled('div', {
  display: 'none',
  '@md': {
    display: 'grid',
    gridGap: '$28',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  '@lg': {
    gridColumn: 'span 2',
  },
})
const GroupLinks = styled('div', {
  marginBottom: '$16',
  display: 'grid',
  gridGap: '$12',
  alignContent: 'start',
  '@lg': {
    marginBottom: '0',
  },
})
const FooterLink = styled(Link, {
  projectFont: 'body01',
  color: '$green_09',
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginRight: '$8',
  },
  '&:hover': {
    textDecoration: 'underline',
  },
})
const LogoWrap = styled('div', {
  '@lg': {
    gridRow: '2',
  },
})

const Colophon = styled(Container, {
  display: 'grid',
  gridGap: '$28',
  padding: '$32 0',
  justifyItems: 'start',
  '@lg': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})
const ColophonLinks = styled('div', {
  display: 'inline-grid',
  '@md': {
    gridAutoColumns: 'auto',
    gridAutoFlow: 'column',
    gap: '$28',
    gridColumn: 'span 2',
  },
})
const PaymentMethods = styled('div', {
  display: 'grid',
  gridAutoColumns: '56px',
  gridAutoFlow: 'column',
  gridTemplateRows: '$32',
  gap: '$12',
})

const AccordionWrap = styled('div', {
  display: 'grid',
  '@md': {
    display: 'none',
  },
})

const AccordionHeadingStyled = styled(Button, {
  display: 'grid',
  gridTemplateColumns: '1fr',
  projectFont: 'body01',
  color: '$green_09',
  height: '$buttonHeightL',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
})

const AccordionHeadingContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  justifyContent: 'space-between',
})

const AccordionHeading: FC<AccordionHeaderProps & { title?: string }> = ({
  title,
  isOpen,
  ...restProps
}) => {
  return (
    <AccordionHeadingStyled {...restProps}>
      <AccordionHeadingContainer>
        <span>{title}</span>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </AccordionHeadingContainer>
    </AccordionHeadingStyled>
  )
}

export interface FooterProps {
  footer: SiteConfiguration['footer']
  newsletter: SiteConfiguration['newsletter']['footer']
}

export const Footer: FC<FooterProps> = ({ footer, newsletter }) => {
  const { t } = useTranslation('footer')
  const socialLinks = footer.socials?.map((social) => {
    switch (social.platform) {
      case 'instagram':
        return (
          <FooterLink href={social.url} key={social.platform} target={'_blank'}>
            <Instagram />
            <span>Instagram</span>
          </FooterLink>
        )
      case 'facebook':
        return (
          <FooterLink href={social.url} key={social.platform} target={'_blank'}>
            <Facebook />
            <span>Facebook</span>
          </FooterLink>
        )
      default:
        return null
    }
  })
  return (
    <>
      <Rule />
      <TopWrap>
        <LogoWrap>
          <LogoLink type={'footer'} />
        </LogoWrap>
        <MainWrap>
          <LinkCols>
            {footer.linkGroups?.map((group) => (
              <div key={group.title}>
                <Heading>{group.title}</Heading>
                <GroupLinks>
                  {group.links?.map((item) => (
                    <FooterLink {...item.link} key={item.title}>
                      {item.title}
                    </FooterLink>
                  ))}
                </GroupLinks>
              </div>
            ))}
            {socialLinks && (
              <div>
                <Heading>{t('followUs')}</Heading>
                <GroupLinks>{socialLinks}</GroupLinks>
              </div>
            )}
          </LinkCols>
          <FooterNewsletter newsletter={newsletter} />
          <AccordionWrap>
            {footer.linkGroups?.map((group, i) => (
              <AccordionStateful
                accordionId={`footerNav-${i}`}
                key={`footerNav-${i}`}
                header={(headerElementProps) => (
                  <AccordionHeading
                    {...headerElementProps}
                    title={group.title}
                  />
                )}
              >
                <GroupLinks>
                  {group.links?.map((item) => (
                    <FooterLink {...item.link} key={item.title}>
                      {item.title}
                    </FooterLink>
                  ))}
                </GroupLinks>
              </AccordionStateful>
            ))}
            {socialLinks && (
              <AccordionStateful
                accordionId={`footerNav-socials`}
                header={(headerElementProps) => (
                  <AccordionHeading
                    {...headerElementProps}
                    title={t('followUs')}
                  />
                )}
              >
                <GroupLinks>{socialLinks}</GroupLinks>
              </AccordionStateful>
            )}
          </AccordionWrap>
        </MainWrap>
        <TrustBox location="footer" />
      </TopWrap>
      <Rule />
      <Colophon>
        <ColophonLinks>
          {footer.bottomLinks?.map((item) => (
            <FooterLink {...item.link} key={item.title}>
              {item.title}
            </FooterLink>
          ))}
          <CreditsModal />
        </ColophonLinks>
        <PaymentMethods>
          {footer.paymentMethods?.map(({ title, icon }) =>
            icon ? (
              <Media
                {...icon}
                key={title}
                layout={'fill'}
                objectFit={'contain'}
              />
            ) : null,
          )}
        </PaymentMethods>
      </Colophon>
    </>
  )
}

export default Footer
