import React, { FC } from 'react'
import NextLink, { LinkProps } from 'next/link'
import {
  StyledClickable,
  StyledClickableProps,
} from '../StyledClickable/StyledClickable'
import { LinkAction } from './LinkAction'
import { Action } from '@magal/models'

type ProjectLinkProps = {
  action?: Action
  href?: LinkProps['href']
  shallow?: boolean
  scroll?: boolean
} & StyledClickableProps & {
    target?: '_blank'
    className?: string
    download?: boolean
  }

export const Link: FC<ProjectLinkProps> = ({
  action,
  children,
  download,
  size,
  appearance,
  href,
  tabIndex,
  target,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  className,
  scroll,
}) => {
  const elementProps = {
    download,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    tabIndex,
    target,
  }

  const content = (
    <StyledClickable
      as={'a'}
      className={className}
      appearance={appearance}
      size={size}
      {...elementProps}
    >
      {children}
    </StyledClickable>
  )

  if (href) {
    return (
      <NextLink href={href} passHref scroll={scroll}>
        {content}
      </NextLink>
    )
  }

  if (action) {
    return (
      <LinkAction action={action} className={className}>
        {content}
      </LinkAction>
    )
  }

  return null
}
