import { LocaleId } from '@magal/configs'
import { groq } from 'next-sanity'
import { createMediaProjection } from './createMediaProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createLinkProjection } from './createLinkProjection'

export const createMediaWithCaptionProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
      image${MEDIA_PROJECTION},
      link${LINK_PROJECTION},
      ${coalesceLocaleField('caption', localeId)},
  }`
}
