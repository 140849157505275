import { AppProps } from 'next/app'
import Head from 'next/head'
import { I18nextProvider } from 'react-i18next'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import getI18n from '../translations/i18n'
import { PageBuilderSection, PageResponse } from '@magal/models'
import {
  KlaviyoPopupsScript,
  seoImageLoader,
  Toaster,
  ReamazeChatWidget,
  CountryPicker,
} from '@magal/components'
import { CartProvider, MiniCartModal } from '@magal/cart'
import React, { useEffect } from 'react'
import { Header, StoryIcons, PromoBar, PromoBanner } from '@magal/header'
import { setAppElement } from 'react-modal'
import { Footer } from '@magal/footer'
import { CustomerProvider, useCustomerContext } from '@magal/account'
import { ProductsSettingsProvider } from '@magal/product'
import { styled } from '@magal/styles'
import { setTag } from '@sentry/nextjs'
import Script from 'next/script'
import { handlePopUpTrigger } from '@magal/services/gtm-service'

import 'mapbox-gl/dist/mapbox-gl.css'
import './styles.css'

type ProjectAppProps = AppProps<PageResponse<PageBuilderSection[]>>

const StickyHeaderContainer = styled('div', {
  zIndex: '$zHeader',
  top: '0',
  position: 'sticky',
})

const MyApp = ({ Component, pageProps }: ProjectAppProps) => {
  const { locale, events } = useRouter()

  if (!locale) throw new Error('Locale has not been set')

  const { customer } = useCustomerContext()

  useEffect(() => {
    setAppElement('#__modals__')
    window.Trustpilot = pageProps.siteConfiguration.trustpilot
    window.discountPercentage = pageProps.siteConfiguration.discount
  }, [])

  useEffect(() => {
    const handleRouteChange = (path: string) => {
      setTag('page_path', path)
    }
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    setTag('page_locale', locale)
    setTimeout(() => handlePopUpTrigger(locale), 1000)
  }, [locale])

  const tripleWhaleConflictStr = '"!nC`'

  const collectionData = pageProps?.pageBuilder?.filter(
    (i: any) => i['_type'] === 'sectionCollection',
  )
  const isCollection: boolean = collectionData && collectionData.length > 0
  const promoBannerData =
    isCollection && collectionData[0]?.promoBanner
      ? collectionData[0].promoBanner
      : pageProps.siteConfiguration?.header?.promoBanner

  // console.log('pageProps', collectionData[0].promoBanner, promoBannerData, isCollection)

  return (
    <I18nextProvider i18n={getI18n(locale)}>
      {pageProps.siteConfiguration?.countrySelector?.enabled && (
        <CountryPicker
          props={pageProps?.siteConfiguration?.countrySelector}
          localeProps={pageProps?.locale}
          country={pageProps.userCountryName}
        />
      )}

      <CustomerProvider>
        <ProductsSettingsProvider
          colorsConfig={pageProps.siteConfiguration?.product?.colorsConfig}
          colorOrder={pageProps.siteConfiguration?.product?.colorOrder}
          productCardBackgroundConfig={
            pageProps.siteConfiguration?.product?.backgroundColor
          }
          tagsConfig={pageProps.siteConfiguration?.product?.tags}
          stringColorsConfig={
            pageProps.siteConfiguration?.product?.stringColors
          }
          upsells={pageProps.siteConfiguration?.product?.upsells}
        >
          <KlaviyoPopupsScript />
          <ReamazeChatWidget />
          <CartProvider>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />

              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
              />
              <link rel="manifest" href="/site.webmanifest" />
              <link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color="#5a7162"
              />
              <meta name="msapplication-TileColor" content="#5a7162" />
              <meta name="theme-color" content="#ffffff" />

              <link
                rel="preconnect dns-prefetch"
                href="https://api.config-security.com/"
                crossOrigin="crossorigin"
              />
              <link
                rel="preconnect dns-prefetch"
                href="https://conf.config-security.com/"
                crossOrigin="crossorigin"
              />
              <link
                rel="preconnect dns-prefetch"
                href="https://whale.camera/"
                crossOrigin="crossorigin"
              />
            </Head>
            {pageProps.siteConfiguration?.seo &&
              (() => {
                const {
                  siteConfiguration: { seo },
                } = pageProps

                const seoImage = seoImageLoader(seo.ogImage, seo.ogAltImage)

                return (
                  <DefaultSeo
                    title={seo.metaTitle}
                    description={seo.metaDescription}
                    openGraph={{
                      title: seo.ogTitle,
                      description: seo.ogDescription,
                      images: [...(seoImage ? [seoImage] : [])],
                    }}
                    twitter={{
                      cardType: 'summary_large_image',
                    }}
                  />
                )
              })()}
            <StickyHeaderContainer>
              {pageProps.siteConfiguration?.header?.promoBar?.enabled && (
                <PromoBar
                  promoBar={pageProps.siteConfiguration.header.promoBar}
                />
              )}
              {pageProps.siteConfiguration?.header && (
                <Header
                  headerConfig={pageProps.siteConfiguration.header}
                  searchConfig={pageProps.siteConfiguration.search}
                />
              )}
            </StickyHeaderContainer>

            {pageProps.siteConfiguration?.storyIcons && pageProps.showIcons && (
              <StoryIcons
                storyIconsConfig={pageProps.siteConfiguration.storyIcons}
              />
            )}

            {promoBannerData &&
              pageProps.siteConfiguration?.header?.promoBanner?.enabled && (
                <PromoBanner promoBanner={promoBannerData} />
              )}

            <Component {...pageProps} />

            {pageProps.siteConfiguration?.cart && (
              <MiniCartModal cartConfig={pageProps.siteConfiguration.cart} />
            )}

            {pageProps.siteConfiguration?.footer && (
              <Footer
                footer={pageProps.siteConfiguration.footer}
                newsletter={pageProps.siteConfiguration.newsletter.footer}
              />
            )}

            {/*{pageProps.siteConfiguration && (*/}
            {/*  <ModalNewsletter*/}
            {/*    newsletter={pageProps.siteConfiguration.newsletter.subscribe}*/}
            {/*  />*/}
            {/*)}*/}
            <div id={'__modals__'} />
            <Toaster />
            <Script
              strategy="afterInteractive"
              id="gtm"
              dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-NL47RSW');
                `,
              }}
            />

            <Script
              strategy="afterInteractive"
              id="snapchat"
              dangerouslySetInnerHTML={{
                __html: `
                (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                r.src=n;var u=t.getElementsByTagName(s)[0];
                u.parentNode.insertBefore(r,u);})(window,document,
                'https://sc-static.net/scevent.min.js');

                  snaptr('init', '6775948f-24a1-4f60-b82d-d2cf14f1b42d', {
                    'user_email': '${customer?.email || ''}',
                  });

                  snaptr('track', 'PAGE_VIEW');
                `,
              }}
            />

            <Script
              strategy="afterInteractive"
              id="tripleWhale"
              dangerouslySetInnerHTML={{
                __html: `
                (function() {window.TriplePixelData={TripleName:"magaljewelrynew.myshopify.com",ver:"2.12",plat:"SHOPIFY",isHeadless:true},function(W,H,A,L,E,_,B,N){function O(U,T,P,H,R){void 0===R&&(R=!1),H=new XMLHttpRequest,P?(H.open("POST",U,!0),H.setRequestHeader("Content-Type","text/plain")):H.open("GET",U,!0),H.send(JSON.stringify(P||{})),H.onreadystatechange=function(){4===H.readyState&&200===H.status?(R=H.responseText,U.includes(".txt")?eval(R):P||(N[B]=R)):(299<H.status||H.status<200)&&T&&!R&&(R=!0,O(U,T-1,P))}}if(N=window,!N[H+"sn"]){N[H+"sn"]=1,L=function(){return Date.now().toString(36)+"_"+Math.random().toString(36)};try{A.setItem(H,1+(0|A.getItem(H)||0)),(E=JSON.parse(A.getItem(H+"U")||"[]")).push({u:location.href,r:document.referrer,t:Date.now(),id:L()}),A.setItem(H+"U",JSON.stringify(E))}catch(e){}var i,m,p;A.getItem('${tripleWhaleConflictStr}')||(_=A,A=N,A[H]||(E=A[H]=function(t,e,a){return void 0===a&&(a=[]),"State"==t?E.s:(W=L(),(E._q=E._q||[]).push([W,t,e].concat(a)),W)},E.s="Installed",E._q=[],E.ch=W,B="configSecurityConfModel",N[B]=1,O("https://conf.config-security.com/model",5),i=L(),m=A[atob("c2NyZWVu")],_.setItem("di_pmt_wt",i),p={id:i,action:"profile",avatar:_.getItem("auth-security_rand_salt_"),time:m[atob("d2lkdGg=")]+":"+m[atob("aGVpZ2h0")],host:A.TriplePixelData.TripleName,plat:A.TriplePixelData.plat,url:window.location.href,ref:document.referrer,ver:A.TriplePixelData.ver},O("https://api.config-security.com/event",5,p),O("https://whale.camera/live/dot.txt",5)))}}("","TriplePixel",localStorage)})()
                `,
              }}
            />
            <Script
              strategy="afterInteractive"
              id="hotjar"
              dangerouslySetInnerHTML={{
                __html: `
                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:5055005,hjsv:6};
                  a=o.getElementsByTagName("head")[0];
                  r=o.createElement("script");r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,"https://static.hotjar.com/c/hotjar-",".js?sv=");
                `,
              }}
            />
          </CartProvider>
        </ProductsSettingsProvider>
      </CustomerProvider>
    </I18nextProvider>
  )
}
export default MyApp
