import { FC } from 'react'
import { styled } from '@magal/styles'
import { Button } from '../Button/Button'

const IndicatorsWrap = styled('div', {
  pointerEvents: 'all',
  display: 'grid',
  gap: '$4',
  gridAutoColumns: '1fr',
  gridAutoFlow: 'column',
})

const IndicatorItem = styled(Button, {
  height: '$16',
  display: 'grid',
  alignContent: 'center',
  '&:after': {
    content: '',
    height: '$2',
    backgroundColor: '$gray500',
  },
  variants: {
    isActive: {
      true: {
        '&:after': {
          backgroundColor: '$green',
        },
      },
    },
  },
})

type SliderItemMobileProps = {
  numberOfItems: number
  activeIndex: number
  onItemClick: (number: number) => void
}

export const Indicators: FC<SliderItemMobileProps> = ({
  numberOfItems,
  activeIndex,
  onItemClick,
}) => {
  return (
    <IndicatorsWrap>
      {Array.from({ length: numberOfItems }).map((c, i) => (
        <IndicatorItem
          key={i}
          isActive={activeIndex === i}
          onClick={() => onItemClick(i)}
        />
      ))}
    </IndicatorsWrap>
  )
}
