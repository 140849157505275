import { styled } from '@magal/styles'
import React from 'react'

const Wrap = styled('div', {
  marginLeft: '$containerMarginMobile',
  marginRight: '$containerMarginMobile',
  '@md': {
    marginLeft: '$containerMarginDesktop',
    marginRight: '$containerMarginDesktop',
  },
})

export const Container: React.FC<{
  className?: string
  as?: keyof HTMLElementTagNameMap
}> = ({ children, className, as = 'div' }) => {
  return (
    <Wrap className={className} as={as}>
      {children}
    </Wrap>
  )
}
