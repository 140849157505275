import { FC, useEffect, useState, useRef } from 'react'
import { SiteConfiguration } from '@magal/models'
import { useRouter } from 'next/router'
import { styled } from '@magal/styles'
import { ToolTip, TrustBox } from '@magal/components'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { CountDownRenderer } from './components/CountDownRenderer'
import { handlePopUpTrigger } from '@magal/services/gtm-service'

export type PromoBannerProps = {
  promoBanner: SiteConfiguration['header']['promoBanner']
}

const Root = styled('div', {
  position: 'relative',
  height: '$promoBannerHeightMobile',
  background: '$green',
  display: 'grid',
  color: 'white',
  '@lg': {
    height: '$promoBannerHeightDesktop',
  },
})
const Inner = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'center',
  alignContent: 'center',
  alignItems: 'center',
  projectFont: 'caps09',
  fontSize: '12px',
  padding: '0 10px',
  lineHeight: 1.8,
  '@lg': {
    lineHeight: 1.2,
    textAlign: 'left',
    gridTemplateColumns: '1fr auto',
    padding: '5px $containerMarginDesktop',
  },
})
const Captions = styled('div', {
  display: 'grid',
  textAlign: 'center',
  width: '62%',
  '@lg': {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  variants: {
    noTimer: {
      true: {
        width: '100%',
        justifyContent: 'center',
      },
    },
  },
})
const Caption = styled('div', {
  gridArea: '1 / 1',
  transition: 'opacity 300ms ease 300ms, visibility 0ms',
  lineHeight: 1.65,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 9,
  variants: {
    active: {
      true: {},
      false: {
        transition: 'opacity 300ms ease 0ms, visibility 300ms',
        visibility: 'hidden',
        opacity: 0,
      },
    },
    absolute: {
      true: {
        position: 'absolute',
        display: 'none',
      },
    },
    noTimer: {
      true: {
        fontSize: 11,
        '@lg': {
          fontSize: 12,
        },
      },
    },
  },
  '@lg': {
    fontSize: 12,
  },
  '& a': {
    borderBottom: '1px solid',
    paddingBottom: '2px',
  },
})
const CountdownWrap = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '$24',
  right: 'var(--space-containerMarginDesktop)',
  top: 'calc(50% - 14px)',
  borderLeft: '1px solid #fff',
  width: '40%',
  justifyContent: 'center',
  '@lg': {
    width: '50%',
    justifyContent: 'flex-start',
    marginLeft: '40px',
    paddingLeft: '30px',
  },
})
const CampaignTrigger = styled('span', {
  textDecoration: 'underline',
  cursor: 'pointer',
})

type CountDownR = {
  days: number
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}

const add24Hour = (date: Date) =>
  new Date(date.getTime() + 1 * 24 * 60 * 60 * 1000) // 24 HOURS (1 day) FROM NOW
const DEFAULT_DATE = add24Hour(new Date())

export const PromoBanner: FC<PromoBannerProps> = ({ promoBanner }) => {
  const {
    dateItFinishes,
    captions,
    captionsTool,
    showTrustpilot,
    enabledTimer,
  } = promoBanner

  const PROMO_SLIDE_INTERVAL = 14000
  const parsedFinishDate = dateItFinishes ?? DEFAULT_DATE
  const [activeCaptionIndex, setActiveCaptionIndex] = useState<number>(0)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [countdownKey, setCountdownKey] = useState<number>(0)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [correctFinishTime, setCorrectFinishTime] = useState<Date>()
  const [timerEnded, setTimerEnded] = useState<boolean>(false)

  const { t } = useTranslation('header')
  const { locale } = useRouter()

  const countDownRenderer: FC<CountDownR> = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => (
    <CountDownRenderer
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      completed={completed}
    />
  )

  const setNewDate = (theDate: Date) => {
    const newDate = new Date(theDate)
    setCorrectFinishTime(newDate)
  }

  useEffect(() => {
    if (dateItFinishes) {
      setNewDate(parsedFinishDate)

      const date = new Date(dateItFinishes)
      const datePlus24Hours = add24Hour(date)

      if (date.getTime() < Date.now()) {
        if (datePlus24Hours.getTime() > Date.now()) {
          setNewDate(datePlus24Hours)
          setTimerEnded(false)
        } else {
          setTimerEnded(true)
        }
      }
    } else {
      setTimerEnded(true)
    }
  }, [parsedFinishDate, dateItFinishes])

  const resetTimer = () => {
    setCountdownKey((prev) => prev + 1)
    setNewDate(parsedFinishDate)
  }
  const captionsLength =
    (captions || []).length +
    (captionsTool || []).length +
    (showTrustpilot ? 1 : 0)

  useEffect(() => {
    setLoaded(true)
    setIsMobile(window.innerWidth < 1024)

    const intervalID = setInterval(() => {
      setActiveCaptionIndex((prevIndex) => {
        return prevIndex + 1 < captionsLength ? prevIndex + 1 : 0
      })
    }, PROMO_SLIDE_INTERVAL)
    return () => clearInterval(intervalID)
  }, [captionsLength])

  const ToolTipRef = useRef(null)
  const [showToolTip, setShowToolTip] = useState(false)

  const handleMouseOver = (state: boolean) => {
    setShowToolTip(state)
  }

  if (captionsLength < 1) return null
  type promoCaption = {
    caption: string
  }

  return (
    <Root
      css={{
        backgroundColor: promoBanner.backgroundColor?.hex,
        color: promoBanner.textColor?.hex,
      }}
    >
      <Inner>
        <Captions noTimer={timerEnded || !enabledTimer}>
          {captions?.map((promo: promoCaption, i: number) => (
            <Caption
              key={i}
              active={activeCaptionIndex === i}
              noTimer={timerEnded || !enabledTimer}
            >
              <div>
                {promo.caption.includes('[attentive]') ? (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: promo.caption.split('[attentive]')[0],
                      }}
                    />
                    {promo.caption.includes('[attentive]') && (
                      <CampaignTrigger
                        role="button"
                        onClick={() => handlePopUpTrigger(locale)}
                      >
                        {t('signUp')}
                      </CampaignTrigger>
                    )}
                    {promo.caption.split('[attentive]')[1] && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: promo.caption.split('[attentive]')[1],
                        }}
                      />
                    )}
                  </>
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: promo.caption.replace('[attentive]', ''),
                    }}
                  />
                )}
              </div>
            </Caption>
          ))}

          {captionsTool?.map((promo, i) => {
            const j = i + (captions || []).length
            return (
              <Caption key={j} active={!enabledTimer}>
                <span
                  onMouseEnter={() => handleMouseOver(true)}
                  onMouseLeave={() => handleMouseOver(false)}
                  ref={ToolTipRef}
                  dangerouslySetInnerHTML={{ __html: promo.caption }}
                ></span>
                {showToolTip && activeCaptionIndex === j && (
                  <ToolTip parentRef={ToolTipRef} promo={promo}></ToolTip>
                )}
              </Caption>
            )
          })}
          {showTrustpilot && loaded ? (
            <Caption
              absolute={activeCaptionIndex !== captionsLength - 1}
              active={activeCaptionIndex === captionsLength - 1}
            >
              <TrustBox
                theme={'dark'}
                height={isMobile ? '45px' : '20px'}
                location="promobar"
              />
            </Caption>
          ) : null}
        </Captions>

        {enabledTimer && !timerEnded && loaded && (
          <CountdownWrap>
            <Countdown
              key={`countdown-${countdownKey}`}
              date={correctFinishTime}
              renderer={countDownRenderer}
              onComplete={resetTimer}
            />
          </CountdownWrap>
        )}
      </Inner>
    </Root>
  )
}
