export const Plus = () => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="#424A41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.4118 4.006H6.00178V0.0459994H4.70578V4.006H0.277781V5.23H4.70578V9.19H6.00178V5.23H10.4118V4.006Z" />
  </svg>
)
