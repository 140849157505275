import { LocaleId } from '@magal/configs'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createBlockContentProjection } from './createBlockContentProjection'
import { createMediaWithCaptionProjection } from './createMediaWithCaptionProjection'
import { createMediaProjection } from './createMediaProjection'

export const createJournalPostProjection = (localeId: LocaleId) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const BLOCK_CONTENT_PROJECTION = createBlockContentProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const MEDIA_WITH_CAPTION_PROJECTION =
    createMediaWithCaptionProjection(localeId)

  return groq`{
      ...,
      "pageBuilder":
        [
          {
            "_type": "sectionJournalHero",
            "_key": "sectionJournalHero",
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField(
              'description',
              localeId,
            )}[]${BLOCK_CONTENT_PROJECTION},
            mediaWithCaption${MEDIA_WITH_CAPTION_PROJECTION},
          },
          ...pageBuilder[]${PAGE_BUILDER_PROJECTION},
          {
            "_type": "sectionJournalPostsAuthor",
            "_key": "sectionJournalPostsAuthor",
            author,
            publishDate
          },
          {
            "_type": "sectionJournalRelatedProducts",
            "_key": "sectionJournalRelatedProducts",
            "shopifyIds": relatedProducts[] -> {"id": store.gid, "type": "productBasic"},
          },
          ...*[ !(_id in path('drafts.**')) && _type == "journalLandingPage" ][0] {
            pageBuilder[]${PAGE_BUILDER_PROJECTION},
          }.pageBuilder
        ],
        "seo": {
          "metaTitle": {${coalesceLocaleField('title', localeId)}}.title,
          "metaDescription": {${coalesceLocaleField(
            'subtitle',
            localeId,
          )}}.subtitle,
          "ogImage": mediaWithCaption.image${MEDIA_PROJECTION},
        },
    }`
}
