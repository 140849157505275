import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createBlockContentProjection } from '../projections/createBlockContentProjection'

export const createSectionJournalQuoteProjection = (localeId: LocaleId) => {
  const BLOCK_CONTENT_PROJECTION = createBlockContentProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('quote', localeId)}[]${BLOCK_CONTENT_PROJECTION},
      }
  `
}
