import React, { FC } from 'react'
import { styled } from '@magal/styles'
import { Button } from '../Button/Button'
import { ChevronLeft, ChevronRight } from '@magal/icons'
import { useTranslation } from 'react-i18next'

const StyledButton = styled(Button, {
  transition: '400ms',
  position: 'relative',
  width: '$48',
  height: '$48',
  display: 'grid',
  placeContent: 'center',
  color: '$white',
  '&>div': {
    display: 'grid',
  },
  '&:hover': {
    opacity: 0.8,
  },
  '&:disabled': {
    opacity: 0.3,
  },
  '&:active': {
    opacity: 0.6,
  },
  variants: {
    visuallyHidden: {
      true: {
        opacity: '0 !important',
      },
    },
  },
})

export const ArrowButton: FC<{
  onClick: (event: React.MouseEvent | React.KeyboardEvent) => void
  disabled?: boolean
  type?: 'prev' | 'next'
  visuallyHidden?: boolean
}> = ({ onClick, disabled, type = 'prev', visuallyHidden = false }) => {
  const { t } = useTranslation('sectionCollectionSlider')

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      visuallyHidden={visuallyHidden}
    >
      {type === 'prev' ? (
        <>
          <div aria-hidden="true">
            <ChevronLeft />
          </div>
          <span className="sr-only">{t('previous')}</span>
        </>
      ) : (
        <>
          <div aria-hidden="true">
            <ChevronRight />
          </div>
          <span className="sr-only">{t('next')}</span>
        </>
      )}
    </StyledButton>
  )
}
