import { ShopifyExternalVideoType } from '@magal/models'
import { styled } from '@stitches/react'
import {
  YouTube,
  Vimeo,
  useEmbeddedVideoUrl,
} from '../helpers/externalVideoParameters'
import { MediaProps } from '../models/MediaProps'
import { Image } from './Image'

type ShopifyExternalVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  /**
   * An object with fields that correspond to the Storefront API's [ExternalVideo object](https://shopify.dev/api/storefront/reference/products/externalvideo).
   */
  mediaPayload: ShopifyExternalVideoType
  /** An object containing the options available for either
   * [YouTube](https://developers.google.com/youtube/player_parameters#Parameters) or
   * [Vimeo](https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Using-Player-Parameters).
   */
  options?: YouTube | Vimeo
}

type PropsWeControl = 'src'

const Container = styled('div', {
  position: 'relative',
  background: 'black',
  img: {
    opacity: 0.75,
    zIndex: 0,
  },
  iframe: {
    zIndex: 1,
  },
  variants: {
    layout: {
      fill: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
      },
      responsive: {},
      raw: {},
    },
  },
})

const IframeElement = styled('iframe', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
})

/**
 * The `ExternalVideo` component renders an embedded video for the Storefront
 * API's [ExternalVideo object](https://shopify.dev/api/storefront/reference/products/externalvideo).
 */
export function ShopifyExternalVideo(
  props: Omit<JSX.IntrinsicElements['iframe'], PropsWeControl> &
    ShopifyExternalVideoProps,
) {
  const {
    mediaPayload,
    options,
    id = mediaPayload.id,
    frameBorder = '0',
    allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
    allowFullScreen = true,
    loading = 'lazy',
    layout,
    priority,
    loop = true,
  } = props

  if (!mediaPayload.embedUrl) {
    throw new Error(`<ExternalVideo/> requires the 'embedUrl' property`)
  }

  // default ext video options
  const url = useEmbeddedVideoUrl(mediaPayload.embedUrl, {
    autoplay: 1,
    controls: 0,
    loop: loop ? 1 : 0,
    muted: 1,
    playsinline: 1,
    ...options,
  })

  const posterImage = mediaPayload.previewImage

  const height = posterImage?.height || 1
  const width = posterImage?.width || 1
  const ratio = (height / width) * 100

  const containerStyles = (() => {
    const paddingBottom = `${ratio}%`
    return layout === 'responsive' ? { paddingBottom } : undefined
  })()

  return (
    <Container layout={layout} style={containerStyles}>
      {posterImage?.url && (
        <Image src={posterImage?.url} priority={priority} layout={'fill'} />
      )}

      <IframeElement
        title={mediaPayload.alt}
        id={id ?? mediaPayload.embedUrl}
        frameBorder={frameBorder}
        allow={allow}
        allowFullScreen={allowFullScreen}
        src={url}
        loading={loading}
      ></IframeElement>
    </Container>
  )
}
