import { DEFAULT_LOCALE_ID, LocaleId } from '@magal/configs'
import { GraphQLClient } from 'graphql-request'
import { captureException, withScope } from '@sentry/nextjs'

export function createShopifyClient(language: LocaleId = DEFAULT_LOCALE_ID) {
  const STOREFRONT_API_VERSION = process.env.SHOPIFY_API_VERSION || '2024-04'
  const ACCESS_TOKEN = process.env.SHOPIFY_ACCESS_TOKEN
  const URL = `${process.env.SHOPIFY_DOMAIN}/api/${STOREFRONT_API_VERSION}/graphql.json`

  if (!(URL && ACCESS_TOKEN)) {
    const message = 'Please setup env variables for the project'

    withScope(function (scope) {
      scope.setTransactionName('Missing ENV variables')
      captureException(new Error('Please setup env variables for the project'))
    })
    throw new Error(message)
  }

  return new GraphQLClient(URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': ACCESS_TOKEN,
      'Accept-Language': language,
    },
  })
}
