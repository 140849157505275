import { ChevronLeft } from '@magal/icons'
import { FC } from 'react'
import { styled } from '@magal/styles'
import { StyledClickable } from '@magal/components'

const Root = styled(StyledClickable, {
  gap: '$8',
  display: 'grid',
  width: 'fit-content',
  gridAutoFlow: 'column',
  alignItems: 'center',
  projectFont: 'body01',
  color: '$green_09',
})

type Props = { onClick?: () => void }

export const BackButton: FC<Props> = ({ onClick, children }) => (
  <Root onClick={onClick}>
    <ChevronLeft />
    <span>{children}</span>
  </Root>
)
