import { FC } from 'react'
import { styled } from '@magal/styles'
import { Loader } from './Loader'
import { Container } from '../Container/Container'

const Root = styled(Container, {
  color: '$green_09',
})
const LoaderContainer = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$green_09',
  height: 'calc(100vh - $headerHeightMobile - $promoBarHeightMobile)',
  '@lg': {
    height: 'calc(100vh - $headerHeightDesktop - $promoBarHeightDesktop)',
  },
})

export const FullPageLoader: FC = () => {
  return (
    <Root>
      <LoaderContainer>
        <Loader size={'medium'} />
      </LoaderContainer>
    </Root>
  )
}
