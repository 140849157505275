export const Minus = () => (
  <svg
    width="9"
    height="3"
    viewBox="0 0 9 3"
    fill="#424A41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.45819 2.266V0.97H0.772188V2.266H8.45819Z" />
  </svg>
)
