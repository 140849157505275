export const ChevronUp = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#565C54"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9938 8.66479C12.0871 8.67905 12.2177 8.71163 12.317 8.80257L17.7715 13.8026C17.9184 13.9372 18 14.1143 18 14.3C18 14.4857 17.9184 14.6628 17.7715 14.7974C17.4771 15.0673 17.0331 15.0675 16.7384 14.7982L11.7469 10.32L6.86242 14.7974C6.56777 15.0675 6.12314 15.0675 5.82849 14.7974C5.68161 14.6628 5.6 14.4857 5.6 14.3C5.6 14.1143 5.68161 13.9372 5.82849 13.8026L11.2422 8.84003C11.2674 8.78628 11.3085 8.74761 11.3468 8.72332C11.4011 8.68886 11.4615 8.67373 11.5048 8.66578C11.5912 8.64995 11.6882 8.64998 11.7404 8.65L11.7485 8.65C11.8026 8.65 11.8969 8.64999 11.9938 8.66479Z"
    />
  </svg>
)
