import { LocaleId } from '@magal/configs'
import { getLocaleRegionIdFromPath } from '@magal/utils'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@magal/models'

export const createPageByDocumentIdQuery = (
  documentId: string,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  return groq`*[_id == "${documentId}"][0]{
    _type == 'page' => {
      initialHeaderStyle,
      title,
      pageBuilder[]${PAGE_BUILDER_PROJECTION}
    },
  }`
}

export const getPageByDocumentId = async (
  documentId: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)

  const query = createPageByDocumentIdQuery(documentId, localeId)

  const pageData = await getClient(preview).fetch(query)

  return pageData
}
