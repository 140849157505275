import { fluidRange } from 'polished'
import { CSSProperties } from 'react'
import { screenSizes } from './breakpoints'
import { sizes } from './sizes'
import { Styles } from 'polished/lib/types/style'
import { FluidRangeConfiguration } from 'polished/lib/types/fluidRangeConfiguration'
import { captureException } from '@sentry/nextjs'

const LIN_TOKEN_MAP = {
  CONTAINER_SPACE_S: ['$24', '$28'],
  CONTAINER_SPACE_M: ['$24', '$64'],
  CONTAINER_SPACE_L: ['$24', '$240'],
  CONTAINER_SPACE_XL: ['$24', '$416'],
} as const

type LinToken = keyof typeof LIN_TOKEN_MAP
type SizeType = `$${keyof typeof sizes}`
type FluidSize = SizeType | number | string

type LinArgs = [
  prop: keyof CSSProperties,
  fromSize: FluidSize,
  toSize: FluidSize,
]

const isSizesType = (value: unknown): value is SizeType =>
  typeof value === 'string' && value.startsWith('$')

const formatToSizesProp = (value: string) =>
  Number(value.replace('$', '')) as keyof typeof sizes

/**
 * Used to transform different types of size notations:
 * - theme sizes values i.e. $1 and numbers will be converted to pixels
 * - numbers to pixels
 * @param sizeNotation {FluidSize}
 */
const formatSizeValue = (sizeNotation: FluidSize): string => {
  if (isSizesType(sizeNotation)) {
    const value = sizes[formatToSizesProp(sizeNotation)]

    if (!value) {
      const availableSizes = Object.keys(sizes).map((v) => `$${v}`)
      const msg = `Incorrect theme size value "${sizeNotation}". Available options: ${availableSizes}`
      captureException(new Error(msg))
      return '0px'
    }

    return value
  }

  if (typeof sizeNotation === 'number') {
    return `${sizeNotation}px`
  }

  return sizeNotation
}

const getFluidRangeConfig = (...args: LinArgs): FluidRangeConfiguration => {
  const [prop, minSize, maxSize] = args
  const fromSize = formatSizeValue(minSize)
  const toSize = formatSizeValue(maxSize)
  return { prop, fromSize, toSize }
}

const getMinMaxScreen = () => {
  const { xs, xl } = screenSizes
  const minScreen = `${xs}px`
  const maxScreen = `${xl}px`
  return [minScreen, maxScreen]
}

/**
 * Returns a set of media queries that resizes set of properties between a provided minSize and maxSize.
 * Uses  minScreen = "xs" and maxScreen = "xl" to constrain the interpolation.
 *
 * Example
 *
 * usage:
 * multiLin([['width', 20, 100], ['height', 20, 100]])
 *
 * output:
 * "@media (min-width: 375px)":
 *    height: "calc(-12.43px + 8.65vw)"
 *    width: "calc(-12.43px + 8.65vw)"
 * "@media (min-width: 1300px)":
 *    height: "100px"
 *    width: "100px"
 * height: "20px"
 * width: "20px"
 *
 */
const multiLin = (linArgs: LinArgs[]) => {
  const fluidConfigs = linArgs.map((args) => getFluidRangeConfig(...args))
  const minMaxScreen = getMinMaxScreen()
  return fluidRange(fluidConfigs, ...minMaxScreen)
}

type LinUtilProps = Partial<
  Record<
    keyof CSSProperties,
    [fromSize: FluidSize, toSize: FluidSize] | LinToken
  >
>

type LinUtil = (linObject: LinUtilProps) => Styles

const linValuesTypes = Object.keys(LIN_TOKEN_MAP)

// TODO: find better way of validating css prop
function isCssPropertyName(key: string): key is keyof CSSProperties {
  return true
}

function isToken(token: string): token is LinToken {
  return linValuesTypes.includes(token)
}

export const lin: LinUtil = (props) => {
  const multilinProps = Object.entries(props).reduce<LinArgs[]>(
    (acc, [key, value]) => {
      if (!isCssPropertyName(key)) {
        return acc
      }

      if (typeof value === 'string' && isToken(value)) {
        return [...acc, [key, ...LIN_TOKEN_MAP[value]]]
      }

      if (Array.isArray(value) && value.length === 2) {
        return [...acc, [key, ...value]]
      }

      return acc
    },
    [],
  )

  return multiLin(multilinProps)
}
