import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionFourFeaturesProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  return groq`{
        _key,
        _type,
        panels[] {
          image${MEDIA_PROJECTION},
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${RICH_TEXT_PROJECTION},
        },
      }
  `
}
