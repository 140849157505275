import React, { FC } from 'react'
import {
  StyledClickable,
  StyledClickableProps,
} from '../StyledClickable/StyledClickable'

type ButtonProps = StyledClickableProps & {
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
}

export const Button: FC<ButtonProps> = ({
  children,
  size,
  appearance,
  type,
  ...restProps
}) => {
  return (
    <StyledClickable
      size={size}
      appearance={appearance}
      type={type}
      {...restProps}
    >
      {children}
    </StyledClickable>
  )
}
