import { FC } from 'react'
import { PricePrimitive } from './PricePrimitve'
import { ShopifyPriceRange } from '@magal/models'
import { useTranslation } from 'react-i18next'

type PriceRangeProps = {
  range: ShopifyPriceRange
  shouldComparedPrice: boolean | undefined
}

export const PriceRange: FC<PriceRangeProps> = ({
  range,
  shouldComparedPrice,
}) => {
  const { t } = useTranslation('productPrice')

  return range.maxVariantPrice.amount === range.minVariantPrice.amount ? (
    <PricePrimitive price={range.minVariantPrice} />
  ) : (
    <>
      {!shouldComparedPrice && (
        <span className={'prefix'}>{`${t('from')} `}</span>
      )}
      <PricePrimitive price={range.minVariantPrice} />
    </>
  )
}
