type CreateItem = {
  productTitle: string
  variantTitle: string
  priceAmount: string
  currency: string
  index: number
  quantity: number
  id: string
  image?: string
}

export const createItem = ({
  productTitle,
  currency,
  index,
  variantTitle,
  priceAmount,
  quantity,
  id,
  image,
}: CreateItem) => {
  return {
    item_id: id,
    item_name: productTitle,
    currency,
    index,
    item_variant: variantTitle,
    item_image: image,
    price: parseFloat(priceAmount),
    quantity,
  }
}
