export const Hamburger = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#565C54"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22 8H3V6.7H22V8Z" />
    <path d="M22 12H3V10.7H22V12Z" />
    <path d="M22 16H3V14.7H22V16Z" />
  </svg>
)
