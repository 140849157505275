import { FC } from 'react'
import { BlockContent } from '../BlockContent/BlockContent'
import { PortableText } from '@magal/models'
import { StandardImage, StandardImageProps } from './StandardImage'

export const EditorialBlockContent: FC<{
  content?: PortableText
}> = ({ content }) => {
  return (
    <BlockContent
      body={content}
      additionalSerializers={{
        types: {
          standardImage: (props: { node: StandardImageProps }) => (
            <StandardImage {...props.node} />
          ),
        },
      }}
    />
  )
}
