import { gql } from 'graphql-request'
import { PRODUCT_BASIC_FRAGMENT } from './productFragment'

export const SIMPLE_COLLECTION_FRAGMENT = gql`
  ${PRODUCT_BASIC_FRAGMENT}
  fragment SimpleCollectionFragment on Collection {
    id
    handle
    title
    description
    seo {
      title
      description
    }
    products(first: 6) {
      nodes {
        ...ProductBasicFragment
      }
    }
  }
`
