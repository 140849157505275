import { getLocaleRegionIdFromPath } from '@magal/utils'
import { createShopifyClient } from './createShopifyClient'
import { captureException, withScope } from '@sentry/nextjs'
import { gql } from 'graphql-request'
import { ROUTE_BASE_COLLECTIONS } from '@magal/configs'

type FetchAllCollectionsRes = {
  collections: {
    nodes: {
      handle: string
    }[]
    pageInfo: {
      hasNextPage: boolean
      endCursor: string
    }
  }
}

const QUERY = gql`
  fragment CollectionFragment on Collection {
    handle
  }
  query ($regionId: CountryCode!, $endCursor: String)
  @inContext(country: $regionId) {
    collections(first: 250, after: $endCursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...CollectionFragment
      }
    }
  }
`

const fetchAllCollectionHandles = async (
  prevProducts: { handle: string }[] = [],
  endCursor: string | undefined = undefined,
): Promise<string[]> => {
  try {
    const [regionId, localeId] = getLocaleRegionIdFromPath('')
    const shopifyClient = createShopifyClient(localeId)

    const allCollections = await shopifyClient.request<FetchAllCollectionsRes>(
      QUERY,
      {
        regionId,
        endCursor,
      },
    )

    const collections = [
      ...(allCollections?.collections?.nodes || []),
      ...prevProducts,
    ]

    const hasNextPage = allCollections.collections.pageInfo?.hasNextPage
    const newEndCursor = allCollections.collections.pageInfo?.endCursor

    if (hasNextPage) {
      return fetchAllCollectionHandles(collections, newEndCursor)
    }

    return collections.map((p) => p.handle)
  } catch (e) {
    withScope(function (scope) {
      scope.setTransactionName(
        '[getAllShopifyCollectionPaths] Cannot fetch collections',
      )
      captureException(e)
    })
    return []
  }
}

export const getAllShopifyCollectionPaths = async (): Promise<string[]> => {
  const allCollectionHandles = await fetchAllCollectionHandles()
  return allCollectionHandles.map((h) => `${ROUTE_BASE_COLLECTIONS}/${h}`)
}
