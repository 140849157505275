import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createMediaWithCaptionProjection } from '../projections/createMediaWithCaptionProjection'

export const createSectionJournalImageProjection = (localeId: LocaleId) => {
  const MEDIA_WITH_CAPTION_PROJECTION =
    createMediaWithCaptionProjection(localeId)
  return groq`{
        _key,
        _type,
        mediaWithCaption${MEDIA_WITH_CAPTION_PROJECTION},
      }
  `
}
