export const ROUTE_BASE_PRODUCTS = '/products'
export const ROUTE_BASE_COLLECTIONS = '/collections'
export const ROUTE_BASE_JOURNAL = '/journal'
export const ROUTE_BASE_UTILITY_PAGES = '/customer-service'
export const ROUTE_BASE_ACCOUNT = '/account'
export const ROUTE_ACCOUNT_LOGIN = `${ROUTE_BASE_ACCOUNT}/login`
export const ROUTE_ACCOUNT_FORGOT_PASSWORD = `${ROUTE_BASE_ACCOUNT}/forgot`
export const ROUTE_ACCOUNT_RESET_PASSWORD = `${ROUTE_BASE_ACCOUNT}/reset`
export const ROUTE_ACCOUNT_ACTIVATE = `${ROUTE_BASE_ACCOUNT}/activate`
export const ROUTE_SEARCH = '/search'
export const ROUTE_NEWSLETTER = '/newsletter'
export const ROUTE_CART = '/cart'
