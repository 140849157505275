import { FC } from 'react'
import { SiteConfiguration } from '@magal/models'
import { styled } from '@magal/styles'
import { Link, Media } from '@magal/components'
import { useRouter } from 'next/router'

const ICON_SIZE = '$56'

const Root = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'min-content',
  '--full-elements-to-show': 4,
  /**
   * Counting gap to always show x and a half elements in order to
   * notify user that there is possibility to scroll
   */
  gap: `min(max(calc((100vw - ($containerMarginMobile * 2) - ((var(--full-elements-to-show) + 0.7) * ${ICON_SIZE})) / var(--full-elements-to-show)), $6), $32)`,
  marginTop: '$12',
  marginBottom: '$12',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  '@sm': {
    '--full-elements-to-show': 5,
  },
  '@md': {
    '--full-elements-to-show': 9,
  },
  '@lg': {
    display: 'none',
  },
})

const IconContainer = styled(Link, {
  display: 'grid',
  gap: '$12',
  '&:first-child': {
    marginLeft: '$containerMarginMobile',
  },
  '&:last-child': {
    marginRight: '$containerMarginMobile',
  },
})

const Icon = styled('div', {
  boxSizing: 'border-box',
  width: ICON_SIZE,
  height: ICON_SIZE,
  padding: '$12',
  borderRadius: '$rMax',
  justifySelf: 'center',
  backgroundColor: '$gray600',
})

const Title = styled('h5', {
  projectFont: 'body04',
  color: '$green_09',
  whiteSpace: 'nowrap',
  textAlign: 'center',
})

export type StoryIconsProps = {
  storyIconsConfig: SiteConfiguration['storyIcons']
}

export const StoryIcons: FC<StoryIconsProps> = ({ storyIconsConfig }) => {
  const { locale } = useRouter()

  const linkByLocale = (links: any) => {
    if (locale?.includes('fr') && links.link_fr) return links.link_fr
    if (locale?.includes('de') && links.link_de) return links.link_de

    return links.link
  }

  return (
    <Root>
      {storyIconsConfig.icons.map((icon, index) => {
        return (
          <IconContainer key={index} href={linkByLocale(icon)?.href}>
            {icon.icon && (
              <Icon css={icon.color ? { backgroundColor: icon.color.hex } : {}}>
                <Media {...icon.icon} layout={'fill'} objectFit={'contain'} />
              </Icon>
            )}
            <Title>{icon.title}</Title>
          </IconContainer>
        )
      })}
    </Root>
  )
}
