import { FC } from 'react'

export const Logo: FC = () => {
  return (
    <svg viewBox="0 0 98 15" fill="#565C54" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M96.8507 4.55639C97.5076 4.55639 98.0003 4.07364 98.0003 3.45007C98.0003 2.82651 97.5076 2.34375 96.8507 2.34375C96.1938 2.34375 95.7012 2.82651 95.7012 3.45007C95.7012 4.07364 96.1938 4.55639 96.8507 4.55639Z" />
        <path d="M96.8507 10.9531C96.1938 10.9531 95.7012 11.4158 95.7012 12.0393C95.7012 12.6629 96.1733 13.1457 96.8507 13.1457C97.5281 13.1457 98.0003 12.683 98.0003 12.0393C98.0003 11.3957 97.5281 10.9531 96.8507 10.9531Z" />
        <path d="M14.8622 1.05664L8.1085 8.98193L1.39589 1.05664H0V14.3727H1.60117V3.81239L7.82111 11.1342H8.37537L14.5953 3.81239V14.3727H16.217V1.05664H14.8622Z" />
        <path d="M27.1585 3.16871L30.5251 8.98193H23.8124L27.1585 3.16871ZM26.5427 1.07676L18.8652 14.3727H20.7127L22.9092 10.5308H31.4078L33.6248 14.3727H35.4723L27.8154 1.05664H26.5632L26.5427 1.07676Z" />
        <path d="M43.929 8.82117H50.3953V10.2091C49.5331 12.0597 47.3572 13.166 44.5654 13.166C40.583 13.166 38.2223 11.1344 38.2223 7.71484C38.2223 4.2953 40.583 2.26369 44.5449 2.26369C46.9877 2.26369 48.7736 3.00795 49.8821 4.49645L50.19 4.89875L51.5243 3.97346L51.2164 3.55105C49.8411 1.70048 47.5419 0.714844 44.5449 0.714844C39.6387 0.714844 36.6006 3.39013 36.6006 7.71484C36.6006 12.0396 39.6592 14.7148 44.5654 14.7148C48.1167 14.7148 50.9701 13.1459 52.017 10.6114V7.27232H43.9701V8.82117H43.929Z" />
        <path d="M61.5218 3.16871L64.8883 8.98193H58.1757L61.5218 3.16871ZM60.9059 1.07676L53.2285 14.3727H55.076L57.2725 10.5308H65.771L67.988 14.3727H69.8356L62.1787 1.05664H60.9265L60.9059 1.07676Z" />
        <path d="M72.5039 1.05664V14.3727H84.2048V12.8239H74.1256V1.05664H72.5039Z" />
      </g>
    </svg>
  )
}
