import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const createSectionLandscapeImageProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        url${LINK_PROJECTION},
        image${MEDIA_PROJECTION}
      }
  `
}
