import { gql } from 'graphql-request'
import { PRODUCT_MEDIA_FRAGMENT } from './productMediaFragment'
import { MONEY_FRAGMENT } from './money'
import { IMAGE_FRAGMENT } from './image'

const METAFIELDS = `
    metafields(
      identifiers: [
        { namespace: "product", key: "featured" },
        { namespace: "product", key: "color" },
        { namespace: "product", key: "materials" },
        { namespace: "product", key: "advancedTemplate" }
      ]
    ) {
      key
      namespace
      value
    }
`

export const PRODUCT_PRICE_RANGE_FRAGMENT = gql`
  fragment ProductPriceRangeFragment on ProductPriceRange {
    maxVariantPrice {
      ...MoneyFragment
    }
    minVariantPrice {
      ...MoneyFragment
    }
  }
`

export const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment ProductVariantFragment on ProductVariant {
    id
    title
    sku
    quantityAvailable
    availableForSale
    currentlyNotInStock
    image {
      ...ImageFragment
    }
    priceV2 {
      ...MoneyFragment
    }
    compareAtPriceV2 {
      ...MoneyFragment
    }
    product {
      id
      title
      handle
      metafields(identifiers: [{ namespace: "product", key: "color" }]) {
        key
        namespace
        value
      }
    }
    selectedOptions {
      name
      value
    }
  }
`

export const PRODUCT_BASIC_FRAGMENT = gql`
  ${MONEY_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${PRODUCT_PRICE_RANGE_FRAGMENT}
  fragment ProductBasicFragment on Product {
    id
    title
    handle
    productType
    featuredImage {
      ...ImageFragment
    }
    compareAtPriceRange {
      ...ProductPriceRangeFragment
    }
    priceRange {
      ...ProductPriceRangeFragment
    }
    tags
    ${METAFIELDS}
  }
`
export const PRODUCT_EXTENDED_FRAGMENT = gql`
  ${MONEY_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${PRODUCT_PRICE_RANGE_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  ${PRODUCT_MEDIA_FRAGMENT}
  fragment ProductExtendedFragment on Product {
    id
    handle
    title
    description
    descriptionHtml
    tags
    handle
    productType
    featuredImage {
      ...ImageFragment
    }
    images(first: 20) {
      nodes {
        ...ImageFragment
      }
    }
    media(first: 20) {
      edges {
        node {
          ...ProductMediaFragment
        }
      }
    }
    options {
      name
      values
    }
    variants(first: 100) {
      nodes {
        ...ProductVariantFragment
      }
    }
    compareAtPriceRange {
      ...ProductPriceRangeFragment
    }
    priceRange {
      ...ProductPriceRangeFragment
    }
    collections(first: 10) {
      edges {
        node {
          title
          handle
        }
      }
    }
    ${METAFIELDS}
    seo {
      title
      description
    },
    reviewsSummary: metafield(namespace: "okendo", key: "summaryData") {
			value
		}
  }
`
