import { FC, useRef, useState } from 'react'
import { styled } from '@magal/styles'
import { useTranslation } from 'react-i18next'
import {
  Container,
  FullPageLoader,
  SectionWrap,
  ButtonWithLoader,
} from '@magal/components'

import { AddressesList } from './views/AddressesList'
import { OrdersList } from './views/OrdersList'
import { ViewOrder } from './views/ViewOrder'

import { useAuthRedirect } from '../../useAuthRedirect'
import { usePageContext, PageContextProvider } from './pageContext'
import { useCustomerContext } from '../../customerContext'
import { InView } from 'react-intersection-observer'
import { MenuItem } from './components/MenuItem'

const Root = styled(Container, {
  color: '$green_09',
})

const HeaderContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$16',
  justifyContent: 'space-between',
  '@lg': {
    gridAutoFlow: 'column',
  },
})

const Title = styled('h1', {
  projectFont: 'caps01',
})

const Description = styled('p', {
  projectFont: 'body01',
})

const RootWrap = styled('div', {
  color: '$green_09',
  display: 'grid',
  gap: '$40',
  '@lg': {
    gap: '$12',
    gridTemplateColumns: '1fr 4fr',
  },
  gridAutoColumns: '100%',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
})

const MenuContainerWrap = styled('div', {
  '@lg': {
    marginLeft: '$containerMarginDesktop',
    marginRight: '$containerMarginDesktop',
  },
})

const titleGaps = 16
//TODO: there needs to be a better way to do this, the same in the other slider

const MenuContainer = styled('div', {
  display: 'grid',
  gap: '$16',
  gridAutoFlow: 'column',
  gridAutoColumns: 'minmax(min-content, max-content)',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  padding: '$16 $containerMarginMobile',
  borderTop: '1px solid $green_09',
  borderBottom: '1px solid $green_09',
  '@md': {
    padding: '$16 $containerMarginDesktop',
  },
  '@lg': {
    border: 'none',
    gridAutoFlow: 'row',
    gridAutoRows: 'minmax(min-content, max-content)',
  },
})

const HeaderContentContainer = styled('div', {
  display: 'grid',
  gap: '$16',
})

const LogoutButtonContainerHeader = styled('div', {
  display: 'none',
  '@lg': {
    display: 'block',
  },
})

const LogoutButtonContainerBottom = styled(Container, {
  marginTop: '$24',
  display: 'grid',
  '@lg': {
    display: 'none',
  },
})

const PageContent: FC = () => {
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const { setActiveView, activeView } = usePageContext()
  const { t } = useTranslation('account')
  const { isLoggedIn, isVerified, removeToken, customer } = useCustomerContext()
  useAuthRedirect()

  const shouldDisplayAccount = isLoggedIn && isVerified
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const userName = customer ? `${customer?.firstName}` : ''

  if (!shouldDisplayAccount) {
    return <FullPageLoader />
  }

  const getCurrentSlideNumber = () => {
    if (!sliderRef.current) {
      return 0
    }
    return Math.round(
      sliderRef.current.scrollLeft / sliderRef.current.children[0].clientWidth,
    )
  }

  const scrollBySlides = (numberOfSlides: number) => {
    if (sliderRef.current) {
      let numberOfSlidesWidth = -titleGaps
      for (let i = 0; i < numberOfSlides; i++) {
        numberOfSlidesWidth += sliderRef.current
          ? sliderRef.current.children[i].clientWidth + titleGaps
          : 0
      }
      sliderRef.current?.scrollBy({
        left: numberOfSlidesWidth,
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const scrollTo = (slideNumber: number) => {
    if (sliderRef.current) {
      const currentIndex = getCurrentSlideNumber()
      scrollBySlides(slideNumber - currentIndex)
    }
  }

  function onClick() {
    removeToken()
    setIsLoggingOut(true)
  }

  return (
    <div>
      <SectionWrap type={'MARGINS_S'}>
        <Root>
          <HeaderContainer>
            <HeaderContentContainer>
              <Title>{t('accountPage.general.header')}</Title>
              <Description>
                {t('accountPage.general.description')} {userName}
              </Description>
            </HeaderContentContainer>
            <LogoutButtonContainerHeader>
              <ButtonWithLoader
                onClick={onClick}
                disabled={isLoggingOut}
                appearance="solidGreen"
                isLoading={isLoggingOut}
              >
                {t('common.logoutButtonLabel')}
              </ButtonWithLoader>
            </LogoutButtonContainerHeader>
          </HeaderContainer>
        </Root>
      </SectionWrap>
      <SectionWrap type={'MARGINS_M'}>
        <RootWrap>
          <MenuContainerWrap>
            <InView
              triggerOnce={true}
              threshold={0.5}
              onChange={(inView) => {
                if (inView) {
                  scrollBySlides(1)
                }
              }}
            >
              <MenuContainer ref={sliderRef}>
                <MenuItem
                  slider={sliderRef.current}
                  title={t('accountPage.general.ordersButtonLabel')}
                  onClick={() => {
                    setActiveView('orders-list')
                    scrollTo(0)
                  }}
                  isActive={activeView.startsWith('orders')}
                />
                <MenuItem
                  slider={sliderRef.current}
                  title={t('accountPage.general.addressButtonLabel')}
                  onClick={() => {
                    setActiveView('address-list')
                    scrollTo(1)
                  }}
                  isActive={activeView.startsWith('address')}
                />
              </MenuContainer>
            </InView>
          </MenuContainerWrap>
          <Root>
            {(() => {
              switch (activeView) {
                case 'address-list':
                  return <AddressesList />
                case 'orders-list':
                  return <OrdersList />
                case 'orders-view':
                  return <ViewOrder />
                default:
                  return null
              }
            })()}
          </Root>
        </RootWrap>
        <LogoutButtonContainerBottom>
          <ButtonWithLoader
            onClick={onClick}
            disabled={isLoggingOut}
            appearance="outlineGreen"
            isLoading={isLoggingOut}
          >
            {t('common.logoutButtonLabel')}
          </ButtonWithLoader>
          {/*  TODO: grid-area */}
        </LogoutButtonContainerBottom>
      </SectionWrap>
    </div>
  )
}

export const HomePage: FC = () => {
  return (
    <PageContextProvider>
      <PageContent />
    </PageContextProvider>
  )
}
