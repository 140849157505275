import { styled } from '@magal/styles'
import React from 'react'

const Wrap = styled('div', {
  projectFont: 'body01',
  'p + p': {
    marginTop: '$24',
  },
  variants: {
    theme: {
      default: {
        em: {
          fontStyle: 'italic',
        },
        i: {
          fontStyle: 'italic',
        },
        b: {
          projectFont: 'body01Medium',
        },
        strong: {
          projectFont: 'body01Medium',
        },
        a: {
          textDecoration: 'underline',
        },
        h2: {
          projectFont: 'heading02',
          margin: '$36 0 $24 0',
        },
        h3: {
          projectFont: 'heading03',
          margin: '$36 0 $24 0',
        },
        'ol, ul': {
          listStyle: 'outside',
          paddingLeft: '$24',

          li: {
            mt: '$4',
          },
        },
        'ul > li': {
          listStyleType: 'disc',
        },
      },
      editorialContent: {},
    },
  },
})

export const RichTextWrap: React.FC<{
  theme?: 'default' | 'editorialContent'
}> = ({ children, theme = 'default' }) => {
  return <Wrap theme={theme}>{children}</Wrap>
}
