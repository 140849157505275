import { styled } from '@magal/styles'
import { FC } from 'react'
import { PortableText } from '@magal/models'
import { Modal } from '../Modal/Modal'
import { Button } from '../Button/Button'
import { EditorialBlockContent } from '../EditorialBlockContent/EditorialBlockContent'
import { Close } from '@magal/icons'
import { useTranslation } from 'react-i18next'

const Wrapper = styled('div', {
  width: '90vw',
  maxWidth: '$maxWidthS',
  color: '$green_09',
})

const Title = styled('h2', {
  projectFont: 'caps02',
  padding: '$28 $28 $20 $28',
  textAlign: 'center',
})

const CloseButtonWrapper = styled(Button, {
  position: 'absolute',
  right: '$12',
  top: '$12',
  color: '$green_09',
})

const ModalContent = styled('div', {
  padding: '0 $28 $28',
})

type EditorialModalProps = {
  modalTitle: string
  modalContent: PortableText
  isOpen: boolean
  onRequestClose: () => void
}

export const EditorialModal: FC<EditorialModalProps> = ({
  modalTitle,
  modalContent,
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation('editorialModal')
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      variant="white-rounded"
    >
      <Wrapper>
        <Title>{modalTitle}</Title>
        <CloseButtonWrapper onClick={onRequestClose}>
          <Close />
          <span className="sr-only">{t('close')}</span>
        </CloseButtonWrapper>
        <ModalContent>
          <EditorialBlockContent content={modalContent} />
        </ModalContent>
      </Wrapper>
    </Modal>
  )
}
