import { FC } from 'react'
import { ImageLoader } from 'next/image'

import { ShopifyImageMediaPayload, ShopifyImageType } from '@magal/models'

import { MediaProps } from '../models/MediaProps'
import { getMediaSizeByRatio } from '../helpers/getMediaSizeByRatio'
import { Image } from './Image'
import { MediaRatiosType, ratios } from '../ratios'
import { shopifyImageLoader } from '../helpers/shopifyImageLoader'

type ShopifyImageProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: ShopifyImageMediaPayload
}

type ImageAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  image: ShopifyImageType
}

/**
 * Since the crop will fail if requested dimensions exceede original image dimensions,
 * we try to find the biggest
 */
const getDesiredWidthAndHeight = (
  width: number,
  originalWidth: number,
  originalHeight: number,
  ratioType?: MediaRatiosType,
): [number, number | undefined] => {
  const desiredWidth = width > originalWidth ? originalWidth : width

  const desiredHeight = (() => {
    // if there is no defined ratioType we want to use original image's derived height
    if (ratioType) {
      const [, height] = getMediaSizeByRatio({
        width: desiredWidth,
        ratioType,
        height: originalHeight,
      })

      return height
    }

    return undefined
  })()

  // if desired height exceedes original height, we try to use
  if (desiredHeight && desiredHeight > originalHeight && ratioType) {
    if (typeof ratioType !== 'string') {
      throw new Error(
        'Responsive hardcrop ratio is not supported by Shopify images',
      )
    }

    const hardCropValue = ratios[ratioType]
    const maxWidth = Math.floor(originalHeight / hardCropValue)

    const [, maxHeight] = getMediaSizeByRatio({
      width: maxWidth,
      ratioType,
      height: originalHeight,
    })

    return [maxWidth, maxHeight]
  }

  return [desiredWidth, desiredHeight]
}

const ImageAdapter: FC<ImageAdapterProps> = ({
  alt,
  hardcropRatio: ratioType,
  objectFit,
  priority,
  sizes,
  layout,
  image,
}) => {
  const {
    src,
    width: originalWidth,
    height: originalHeight,
    alt: shopifyAlt,
  } = image

  const loader: ImageLoader = ({ width: screenWidth, src }) => {
    const [width, height] = getDesiredWidthAndHeight(
      screenWidth,
      originalWidth,
      originalHeight,
      ratioType,
    )

    const url = shopifyImageLoader({
      width,
      height,
      src,
    })
    return url
  }

  const [, height] = getMediaSizeByRatio({
    width: originalWidth,
    ratioType,
    height: originalHeight,
  })

  return (
    <Image
      alt={alt || shopifyAlt || ''}
      objectFit={objectFit}
      priority={priority}
      sizes={sizes}
      layout={layout}
      src={src}
      width={originalWidth}
      height={height}
      loader={loader}
    />
  )
}

export const ShopifyImage: FC<ShopifyImageProps> = ({
  mediaPayload,
  ...restOfProps
}) => {
  const { image } = mediaPayload
  return <ImageAdapter image={image} {...restOfProps} />
}
