import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import TRANSLATIONS_EN_US from './locales/en-US.json'
import TRANSLATIONS_FR_FR from './locales/fr-FR.json'
import TRANSLATIONS_DE_DE from './locales/de-DE.json'

const getI18n = (locale: string) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: TRANSLATIONS_EN_US,
        fr: TRANSLATIONS_FR_FR,
        de: TRANSLATIONS_DE_DE,
      },
      fallbackLng: 'en',
      lng: locale,
    })
  return i18n
}

export default getI18n
