import React from 'react'

export const Cart = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.98 16.4102L17.08 8.12023C16.91 6.96023 15.95 6.15023 14.88 6.15023H14.38C14.32 5.14023 14.02 4.13023 13.42 3.35023C12.78 2.53023 11.82 1.99023 10.48 1.99023C9.14 1.99023 8.17 2.53023 7.55 3.35023C6.96 4.13023 6.69 5.14023 6.65 6.14023H6.15C5.02 6.14023 4.05 6.94023 3.95 8.11023L3 16.4102V16.7502C3 18.0102 3.96 18.9902 5.2 18.9902H15.97C17.21 18.8902 18.08 17.7302 18 16.5002V16.4002L17.98 16.4102ZM10.49 3.43023C11.29 3.43023 11.87 3.69023 12.29 4.22023C12.66 4.69023 12.91 5.40023 12.96 6.18023H8.09C8.11 5.39023 8.33 4.69023 8.71 4.20023C9.12 3.67023 9.7 3.43023 10.49 3.43023ZM15.99 17.5102H5.09C4.74 17.5102 4.47 17.2302 4.47 16.7902V16.6602L5.46 8.27023V8.20023C5.49 7.84023 5.8 7.68023 6.09 7.68023H14.99C15.24 7.68023 15.55 7.90023 15.62 8.29023L16.52 16.6702C16.55 17.0602 16.3 17.4502 15.99 17.5002V17.5102Z"
        fill="currentColor"
      />
    </svg>
  )
}
