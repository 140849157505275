import { errorDebugMessage, getLocaleRegionIdFromPath } from '@magal/utils'
import {
  QUERY_FETCH_CART,
  MUTATION_CREATE_CART,
  MUTATION_ADD_CART_LINES,
  MUTATION_REMOVE_CART_LINES,
  MUTATION_UPDATE_CART_LINES,
  MUTATION_UPDATE_BUYER,
  MUTATION_UPDATE_NOTE,
} from '../fragments/operations'
import { formatCart } from './formatCart'
import { createShopifyClient } from '../createShopifyClient'
import {
  AddLinesInput,
  BuyerIdentity,
  Cart,
  ServiceResponse,
  UpdateLinesInput,
} from '@magal/models'
import {
  ShopifyCartMutationResData,
  ShopifyCartMutationRootKey,
  ShopifyCartFetchRes,
  ShopifyCartRes,
} from './models'
import { GraphQLError, Response } from 'graphql-request/dist/types'

export type CartClientResponse = ServiceResponse<Cart>
export type CartClientReturn = Promise<CartClientResponse>

export type CartClient = (props: {
  cartId: string | null
  locale?: string
}) => {
  create: (email?: string) => CartClientReturn
  fetch: (cartId?: string) => CartClientReturn
  updateBuyer: (buyerIdentity: BuyerIdentity) => CartClientReturn
  addLines: (lines: AddLinesInput) => CartClientReturn
  updateLines: (lines: UpdateLinesInput) => CartClientReturn
  removeLines: (lineIds: string[]) => CartClientReturn
  updateNote: (note: string) => CartClientReturn
}

const METHOD_FINGERPRINT = '[cartClient]'
const __handleErrors: (props: {
  resErrors?: GraphQLError[]
  userErrors?: Array<any>
  operationKey?: ShopifyCartMutationRootKey | 'fetch'
}) => void = ({ resErrors, userErrors, operationKey }) => {
  if (resErrors) {
    resErrors.forEach((e) => {
      const exception = new Error(JSON.stringify(e))
      errorDebugMessage(
        `${METHOD_FINGERPRINT} Error in ${operationKey} response. `,
        exception,
      )
    })
  }
  if (userErrors) {
    userErrors.forEach((e) => errorDebugMessage(new Error(JSON.stringify(e))))
  }
}

const handleCartMutationRes = (
  res: Response<ShopifyCartMutationResData>,
  mutationKey: ShopifyCartMutationRootKey,
): CartClientResponse => {
  __handleErrors({
    resErrors: res.errors,
    userErrors: res.data[mutationKey].userErrors,
  })
  const incomingCart = res.data[mutationKey].cart
  return { status: 'OK', data: incomingCart ? formatCart(incomingCart) : null }
}

const handleCartFetchRes = (
  res: Response<{ cart: ShopifyCartRes | null }>,
): CartClientResponse => {
  __handleErrors({ resErrors: res.errors, operationKey: 'fetch' })
  const incomingCart = res.data.cart
  return { status: 'OK', data: incomingCart ? formatCart(incomingCart) : null }
}

const handleCartCatchError = (
  error: Error,
  operationName: string,
): CartClientResponse => {
  errorDebugMessage(`${METHOD_FINGERPRINT} Error in ${operationName}. `, error)
  return { status: 'ERROR', errors: [error] }
}

export const createCartClient: CartClient = ({ locale, cartId }) => {
  const [countryCode, language] = getLocaleRegionIdFromPath(locale)
  const client = createShopifyClient(language)

  return {
    create: (email?: string) => {
      const cartInput = {
        buyerIdentity: {
          countryCode,
          email,
        },
      }
      return client
        .rawRequest<ShopifyCartMutationResData>(MUTATION_CREATE_CART, {
          cartInput,
        })
        .then((res) => handleCartMutationRes(res, 'cartCreate'))
        .catch((e) => handleCartCatchError(e, 'cartCreate'))
    },
    updateNote: (note: string) => {
      return client
        .rawRequest<ShopifyCartMutationResData>(MUTATION_UPDATE_NOTE, {
          cartId,
          note,
          countryCode,
        })
        .then((res) => handleCartMutationRes(res, 'cartNoteUpdate'))
        .catch((e) => handleCartCatchError(e, 'cartNoteUpdate'))
    },
    updateBuyer: (buyerIdentity: BuyerIdentity) => {
      return client
        .rawRequest<ShopifyCartMutationResData>(MUTATION_UPDATE_BUYER, {
          cartId,
          buyerIdentity,
          countryCode,
        })
        .then((res) => handleCartMutationRes(res, 'cartBuyerIdentityUpdate'))
        .catch((e) => handleCartCatchError(e, 'cartBuyerIdentityUpdate'))
    },
    addLines: (lines: AddLinesInput) => {
      return client
        .rawRequest<ShopifyCartMutationResData>(MUTATION_ADD_CART_LINES, {
          cartId,
          lines,
          countryCode,
        })
        .then((res) => handleCartMutationRes(res, 'cartLinesAdd'))
        .catch((e) => handleCartCatchError(e, 'cartLinesAdd'))
    },
    updateLines: (lines: UpdateLinesInput) => {
      return client
        .rawRequest<ShopifyCartMutationResData>(MUTATION_UPDATE_CART_LINES, {
          cartId,
          lines,
          countryCode,
        })
        .then((res) => handleCartMutationRes(res, 'cartLinesUpdate'))
        .catch((e) => handleCartCatchError(e, 'cartLinesUpdate'))
    },
    removeLines: (lineIds: Array<string>) => {
      return client
        .rawRequest<ShopifyCartMutationResData>(MUTATION_REMOVE_CART_LINES, {
          cartId,
          lineIds,
          countryCode,
        })
        .then((res) => handleCartMutationRes(res, 'cartLinesRemove'))
        .catch((e) => handleCartCatchError(e, 'cartLinesRemove'))
    },
    fetch: async (specificCartId?: string) => {
      const variables = {
        cartId: specificCartId ?? cartId,
        countryCode,
      }
      return client
        .rawRequest<ShopifyCartFetchRes>(QUERY_FETCH_CART, variables)
        .then((res) => handleCartFetchRes(res))
        .catch((e) => handleCartCatchError(e, 'fetch'))
    },
  }
}
