import { SectionShopifyId } from '@magal/models'
import { captureException } from '@sentry/nextjs'

export const validateShopifyIds = (
  shopifyIds?: SectionShopifyId[],
): SectionShopifyId[] => {
  if (!Array.isArray(shopifyIds)) {
    captureException(
      new Error(
        `[validateShopifyIds] received shopifyIds is not an array. Receives ${typeof shopifyIds}`,
      ),
    )
    return []
  }

  return shopifyIds.reduce((acc: SectionShopifyId[], val) => {
    const SUPPORTED_TYPES = [
      'productBasic',
      'productExtended',
      'variantBasic',
      'collectionSliderExcerpt',
    ]

    if (!val) {
      captureException(
        new Error('[validateShopifyIds] requested shopifyId was undefined'),
      )
      return acc
    }

    if (typeof val.id !== 'string') {
      captureException(
        new Error(
          `[validateShopifyIds] unsupported shopifyId "id" type "${val.id}"`,
        ),
      )
      return acc
    }

    if (!SUPPORTED_TYPES.includes(val.type)) {
      captureException(
        new Error(
          `[validateShopifyIds] unsupported shopifyId type "${val.type}"`,
        ),
      )
      return acc
    }

    return [...acc, val]
  }, [])
}
