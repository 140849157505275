import { Cart, CartLineType } from '@magal/models'
import { formatVariant } from '../helpers/formatProduct'
import { ShopifyCartLineRes, ShopifyCartRes } from './models'
import { BUNDLE_ID_KEY, BUNDLE_ID_PREFIX_ADDITIONAL } from '../bundles/utils'

const calculateLinesQuantity = (lines: CartLineType[]): number => {
  return (
    lines?.reduce((acc, line) => {
      if (
        line.attributes
          ?.find((a) => a.key === BUNDLE_ID_KEY)
          ?.value.startsWith(BUNDLE_ID_PREFIX_ADDITIONAL)
      ) {
        return acc
      }
      return acc + line.quantity
    }, 0) ?? 0
  )
}

export const formatCart = (cartRes: ShopifyCartRes): Cart => {
  const lines =
    cartRes.lines.nodes.length > 0
      ? cartRes.lines.nodes.map((line: ShopifyCartLineRes) => {
          const { merchandise } = line
          return {
            ...line,
            merchandise: formatVariant(merchandise),
          }
        })
      : []

  const totalQuantity = calculateLinesQuantity(lines)

  return {
    ...cartRes,
    totalQuantity,
    lines,
  }
}
